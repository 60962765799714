.b-contacts, .b-contacts * {
  box-sizing: border-box;
}

.b-block.b-contacts {
  background: #000;
  color: #ffffff;
  //padding: 2em 0;
  position: relative;

  .b-block__title {
    padding-bottom: 30px;
    margin: auto;
    color: #ffffff;
    width: 100%;
  }

  .b-contacts-feedback {
    .b-contacts-common {
      float: left;
      width: 400px;

      .b-contacts-phone {
        font-size: 16pt;
        line-height: 1.2em;

        .b-contacts-phone__icon {
          display: block;
          width: 20px;
          height: 32px;
          float: left;
          margin-top: 0.3em;
          margin-right: 1em;

          svg g g {
            fill: white;
          }
        }

        .b-contacts-phone__address {
          font-weight: 100;
          font-size: 14pt;
        }

        .b-contacts-phone__number {
          a {
            text-decoration: none;
            cursor: pointer;
            color: white;
          }
        }

        .b-contacts-phone__end {
          clear: both;
        }

        &+.b-contacts-phone {
          margin-top: 1.5em;
        }

        &.b-contacts-phone--no-address {
          line-height: 32px;

          .b-contacts-phone__icon {
            margin-top: 0;
          }
        }
      }
    }

    .b-contacts__btn {
      display: none;
      width: 220px;
      height: 50px;
      margin: 2em auto;
      background-color: #0032E9;

      a {
        display: block;
        padding: 10px;
        border: 2px solid #ffffff;
        color: #ffffff;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        font-family: "DaxLinePro", sans-serif;

        //&:hover {
        //  color: #fff;
        //  background: transparent;
        //}
      }

    }

    .b-contacts-form {
      margin-left: 0;

      .form {
        margin: 0 0.5em;

        textarea[name="message"] {
          height: auto;
        }

        .button {
          height: 60px;

          &.button-send {
            width: 240px;
          }

          &.button-upload {
            .svg-icon {
              margin-left: 0.5em;
              display: inline-block;
              vertical-align: middle;
              width: 24px;

              svg g g {
                fill: $buttonFontColorDefault;
              }
            }

            &:disabled {
              .svg-icon {
                svg g g {
                  fill: darken($buttonFontColorDefault, 40%);
                }
              }
            }
          }
        }

        .button+.button {
          margin-left: 1em;
        }

        .upload-limit {
          margin-right: 1em;
          color: $buttonFontColorDefault;

          &.error {
            color: #F74343;
          }
        }

        .attached-files {
          .attached-file {
            line-height: 1.5em;

            .attached-file-delete {
              cursor: pointer;
              display: inline-block;
              vertical-align: middle;
              width: 16px;
              margin-right: 1.5em;

              .svg-animate {
                fill: white;
              }
            }

            .attached-file__size {
              margin-left: 1em;
            }

            &:hover {
              color: #9b9b9b;

              .attached-file-delete {
                .svg-animate {
                  fill: #9b9b9b;
                }
              }
            }

          }
        }
      }
    }

    .b-footer-addresses {
      text-align: center;
      width: 700px;
      margin: 2em auto auto;
      color: #888888;

      .b-footer-addresses__item {
        display: inline-block;
        min-width: 200px;
        max-width: 350px;
        padding: 0 1em;
      }

      .b-footer-addresses__link {
        color: #fff;
      }
    }

    .b-footer-year {
      color: #888888;
      margin-top: 1em;
      text-align: center;
    }

    .b-contacts-feedback__end {
      clear: both;
    }
  }

}

@include media-screen-990 {

  .b-block.b-contacts {
    .b-contacts-feedback {

      .b-contacts-common {
        float: none;
        width: 100%;
      }

      .b-contacts__btn {
        display: block;
      }

      .b-contacts-form {
        display: none;
      }

    }
  }
}

@include media-screen-700 {

  .b-block.b-contacts {

    .b-block__title {
      font-size: 24px;
      line-height: 29px;
    }

    .b-contacts-feedback {

      .b-contacts__btn {
        display: block;
        width: 176px;
        height: 50px;
        bottom: 40px;
        margin-bottom: 8px;
        background-color: #0032E9;

        &:hover {
          background-color: transparent;
        }
      }

      .b-footer-addresses {
        margin: 2em auto;
        width: auto;
        min-width: 280px;

        .b-footer-addresses__item + .b-footer-addresses__item {
          margin-top: 1em;
          padding: 0;
        }
      }

    }
  }

}