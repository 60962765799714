@mixin media-screen-1200 {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin media-screen-990 {
  @media screen and (max-width: 990px) {
    @content;
  }
}

@mixin media-screen-700 {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin media-screen-550 {
  @media screen and (max-width: 550px) {
    @content;
  }
}