.form, .form * {
  box-sizing: border-box;
}

.form {
  padding: 0;
  margin: 0;
  font-size: $formFontSize;
}

.form+.form {
  margin-top: 1em;
}

.form-field {
  width: 100%;

  &.form-field--one-half {
    width: 50%;
  }

  &.form-field--one-third {
    width: 33.3333%;
  }

  &.form-field--two-thirds {
    width: 66.6666%;
  }

  &.form-field--one-sixth {
    width: 16.6666%;
  }

  &.form-field--five-sixths {
    width: 83.3333%;
  }

  &.form-field--align-left {
    text-align: left;
  }

  &.form-field--align-center {
    text-align: center;
  }

  &.form-field--align-right {
    text-align: right;
  }

  // Элементы ввода

  .form-input {
    width: 100%;
    display: block;
    outline: none;
    margin: 0;
    font-family: $formFontFamily;
    color: $formInputColor;
    font-size: $formFontSize;

    &.form-input-text input,
    &.form-input-text-area textarea {
      font-family: $formFontFamily;
      color: $formInputColor;
      font-size: $formInputFontSize;
      width: 100%;
      height: $formInputHeight;
      line-height: $formInputLineHeight;
      background: $formInputBackgroundColor;
      padding: 0 0.8em;
      resize: vertical;

      &:-moz-placeholder {
        color: $formInputColor
      }

      &::-webkit-input-placeholder {
        color: $formInputColor
      }

    }

    &.form-input-text-area textarea {
      padding: 0.8em;
      resize: vertical;
      height: 4em;
    }

    &.form-input-text input, &.form-input-text-area textarea {
      font-size: $formInputFontSize;
      border: none;
    }

    // Validator styles

    &.form-input-text input, &.form-input-text-area textarea {
      &.error {
        background-color: #fcc;
      }
    }

  }
}

.form-field+.form-field, .form-field+.form-row, .form-row+.form-row, .form-row+.form-field {
  margin-top: 1em;
}

.form-row {
  margin-left: -0.5em;
  margin-right: -0.5em;
  font-size: $formFontSize;
  height: $formInputHeight;

  .form-field {
    margin: 0;
    padding: 0 0.5em;
    float: left;
  }

  .form-row__end {
    clear: left;
  }
}

.form-submit-input {
    width: 240px;
    height: 60px;
	border-color: #fff;
	color: #fff;
	background: #0032E9;

	&:hover {
		color: #fff;
	}
	
	&:disabled {
		background: #888;
		border-color: #888;
		color: #fff;
	}
}
