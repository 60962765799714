@charset "UTF-8";
.g-strong {
  font-weight: bold; }

/*CSS Reset and PreStyles*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption, header, nav, section, aside, article, time, footer {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0; }

html, body {
  width: 100%;
  height: 100%; }

form, header, nav, section, aside, article, time, footer {
  display: block; }

a img {
  border: none; }

.br {
  font-size: 0;
  height: 0;
  clear: both;
  overflow: hidden; }

ul, ol {
  display: block;
  margin: 0 0 11px 0;
  padding: 0;
  list-style: none; }

ul li {
  margin: 0 0 5px 24px;
  padding: 0;
  position: relative; }

ul li:before {
  display: block;
  content: "\2014";
  float: left;
  margin-left: -24px; }

ol {
  counter-reset: item; }

ol li {
  margin: 0 0 5px 20px;
  padding: 0; }

ol li:before {
  display: block;
  float: left;
  margin-left: -20px;
  counter-increment: item;
  content: counters(item, ".") "."; }

ul.unstyled li {
  margin: 0;
  padding: 0;
  background: none;
  list-style: none; }

table {
  margin: 30px 0;
  border: none;
  border-top: 2px solid #cfcfcf;
  border-collapse: collapse; }

th {
  padding: 13px 20px;
  border-bottom: 1px solid #cfcfcf;
  color: #999999;
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  text-align: left; }

td {
  padding: 13px 20px;
  border-bottom: 1px solid #cfcfcf;
  font-size: 14px;
  line-height: 18px; }

th.ta-r, td.ta-r {
  text-align: right; }

th:first-child, td:first-child {
  padding-left: 0; }

th:last-child, td:last-child {
  padding-right: 0; }

.d-n {
  display: none; }

.d-b {
  display: block; }

.fl-l {
  float: left; }

.fl-r {
  float: right !important; }

.ov-h {
  overflow: hidden; }

.fw-b {
  font-weight: bold; }

.ta-c {
  text-align: center; }

.tt-u {
  text-transform: uppercase; }

.whs-nw {
  white-space: nowrap; }

.animate, a, .link {
  -moz-transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, -moz-opacity 300ms linear, -moz-box-shadow 300ms linear, -moz-transform 300ms linear;
  -webkit-transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, -webkit-opacity 300ms linear, -webkit-box-shadow 300ms linear, -webkit-transform 300ms linear;
  -o-transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, -o-opacity 300ms linear, -o-box-shadow 300ms linear, -o-transform 300ms linear;
  transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, opacity 300ms linear, box-shadow 300ms linear, transform 300ms linear; }

.svg-animate {
  -moz-transition: fill 300ms linear, stroke 300ms linear;
  -webkit-transition: fill 300ms linear, stroke 300ms linear;
  -o-transition: fill 300ms linear, stroke 300ms linear;
  transition: fill 300ms linear, stroke 300ms linear; }

.b-image {
  display: block;
  margin: 0;
  padding: 0; }

.m-0 {
  margin: 0; }

.m-a {
  margin: auto; }

.pos-r {
  position: relative; }

/* blocks */
.b-image {
  display: block;
  margin: 0;
  padding: 0;
  border: 0 solid #cccccc;
  border-bottom-width: 1px; }

a:hover .b-image.tohover, .hover .b-image.tohover {
  border-color: #ae1d00; }

/* colors */
.red {
  color: #ae1d00 !important; }

/* other */
sup {
  line-height: 1.5em;
  vertical-align: top; }

.button {
  font-family: DaxLinePro, sans-serif;
  font-size: 13pt;
  font-weight: 400;
  border: 2px solid #979797;
  background: #1f1f1f;
  color: #9b9b9b;
  line-height: 1.2em;
  height: 3em;
  margin: 0;
  padding: 0 1em;
  outline: none;
  vertical-align: middle;
  cursor: pointer; }
  .button:hover {
    background: none;
    color: #9b9b9b; }
  .button:disabled {
    border: 2px solid #313131;
    background: #1f1f1f;
    color: #353535; }
  .button.button--primary {
    border-color: #fff;
    background: #0032E9;
    color: #fff; }
    .button.button--primary:hover {
      background: none;
      color: #fff; }

.form, .form * {
  box-sizing: border-box; }

.form {
  padding: 0;
  margin: 0;
  font-size: 14pt; }

.form + .form {
  margin-top: 1em; }

.form-field {
  width: 100%; }
  .form-field.form-field--one-half {
    width: 50%; }
  .form-field.form-field--one-third {
    width: 33.3333%; }
  .form-field.form-field--two-thirds {
    width: 66.6666%; }
  .form-field.form-field--one-sixth {
    width: 16.6666%; }
  .form-field.form-field--five-sixths {
    width: 83.3333%; }
  .form-field.form-field--align-left {
    text-align: left; }
  .form-field.form-field--align-center {
    text-align: center; }
  .form-field.form-field--align-right {
    text-align: right; }
  .form-field .form-input {
    width: 100%;
    display: block;
    outline: none;
    margin: 0;
    font-family: OpenSans, Arial, Helvetica;
    color: #262626;
    font-size: 14pt; }
    .form-field .form-input.form-input-text input,
    .form-field .form-input.form-input-text-area textarea {
      font-family: OpenSans, Arial, Helvetica;
      color: #262626;
      font-size: 13pt;
      width: 100%;
      height: 3em;
      line-height: 1.2em;
      background: #e0deda;
      padding: 0 0.8em;
      resize: vertical; }
      .form-field .form-input.form-input-text input:-moz-placeholder,
      .form-field .form-input.form-input-text-area textarea:-moz-placeholder {
        color: #262626; }
      .form-field .form-input.form-input-text input::-webkit-input-placeholder,
      .form-field .form-input.form-input-text-area textarea::-webkit-input-placeholder {
        color: #262626; }
    .form-field .form-input.form-input-text-area textarea {
      padding: 0.8em;
      resize: vertical;
      height: 4em; }
    .form-field .form-input.form-input-text input, .form-field .form-input.form-input-text-area textarea {
      font-size: 13pt;
      border: none; }
    .form-field .form-input.form-input-text input.error, .form-field .form-input.form-input-text-area textarea.error {
      background-color: #fcc; }

.form-field + .form-field, .form-field + .form-row, .form-row + .form-row, .form-row + .form-field {
  margin-top: 1em; }

.form-row {
  margin-left: -0.5em;
  margin-right: -0.5em;
  font-size: 14pt;
  height: 3em; }
  .form-row .form-field {
    margin: 0;
    padding: 0 0.5em;
    float: left; }
  .form-row .form-row__end {
    clear: left; }

.form-submit-input {
  width: 240px;
  height: 60px;
  border-color: #fff;
  color: #fff;
  background: #0032E9; }
  .form-submit-input:hover {
    color: #fff; }
  .form-submit-input:disabled {
    background: #888;
    border-color: #888;
    color: #fff; }

/* popups */
#fader {
  display: none;
  background: rgba(12, 12, 12, 0.94);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 405; }

.b-popup {
  display: none;
  width: 530px;
  height: 260px;
  margin: -190px 0 0 -320px;
  padding: 60px 55px 0px;
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 406; }

.b-popup_small {
  width: 370px !important;
  height: auto;
  margin: 0 0 0 -245px !important;
  padding: 30px 60px !important;
  top: 100px;
  left: 50% !important; }

.b-popup.fixed {
  position: fixed;
  margin-top: 0; }

.b-popup__close {
  width: 24px;
  height: 26px;
  position: absolute;
  top: -13px;
  right: -64px;
  cursor: pointer; }

.b-popup__title {
  margin: 0 10px;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-family: "DaxLinePro", sans-serif;
  color: #303030; }

.b-popup-error .b-popup__title {
  color: #F74343; }

.b-popup_small .b-popup__title {
  font-size: 24px;
  line-height: 61px; }

.b-popup__descr {
  margin-bottom: 30px;
  padding-left: 0 30px; }

.b-popup__thanks {
  margin: 8px 25px 23px;
  font-size: 18px; }

.b-popup__knowmore {
  color: #c5c5c5; }

.b-popup__link--fb {
  display: block;
  width: 198px;
  height: 50px;
  margin: 18px auto 0;
  background: #3b5998;
  font-size: 18px;
  line-height: 50px;
  color: #ffffff;
  font-family: "DaxLinePro", sans-serif; }

.b-feedback-popup {
  padding-left: 30px;
  padding-right: 30px; }

.b-popup-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0; }

.b-feedback-popup div {
  text-align: center; }

.b-popup__failed {
  width: 100px;
  margin: auto;
  text-align: center; }

.b-popup-error-message {
  margin: 8px 16px;
  text-align: center; }

.b-popup_review {
  width: 640px;
  height: auto;
  padding: 45px 70px;
  margin: 0 0 0 -390px;
  top: 100px; }

.b-popup__image {
  margin-bottom: 10px;
  text-align: center; }

.b-popup__image img {
  display: block;
  margin: auto; }

.b-popup__name {
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  font-family: "DaxLinePro", sans-serif; }

.b-popup__job {
  margin-top: -6px;
  font-size: 14px;
  line-height: 22px;
  text-align: center; }

.b-popup__quote {
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
  text-align: center; }

.b-popup__text {
  margin-top: 25px; }

.b-popup_review .b-popup__text {
  max-height: 500px;
  overflow-y: auto; }

.b-popup__form__field {
  margin-bottom: 35px; }

#feedbackForm1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.b-popup__form__field input,
.b-popup__form__field textarea {
  display: block;
  width: 328px;
  height: 24px;
  padding: 18px 20px;
  border: 1px solid #ababab;
  background: #ffffff;
  color: #1c1c1c;
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  transition: background-color 300ms linear, border-color 300ms linear; }

.b-popup__form__field textarea {
  height: 122px;
  resize: vertical; }

.b-popup__form__field .error {
  background-color: #FFF3F3;
  border-color: #FFB1B1;
  color: #F74343; }

.b-popup__form__submit {
  padding-top: 10px; }

.b-popup__form__submit input,
.b-popup__form__submit button,
.b-popup__form__submit a {
  display: block;
  width: 220px;
  height: 50px;
  margin: auto;
  padding: 0;
  border: none;
  background: #0032E9;
  color: #ffffff;
  font-size: 18px;
  line-height: 50px;
  font-family: "DaxLinePro", sans-serif;
  cursor: pointer; }

.b-popup__form__submit input:disabled {
  background: #888; }

@media screen and (max-width: 700px) {
  .b-popup_small {
    padding: 0 !important; } }

@media screen and (max-width: 480px) {
  .b-feedback-popup {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0; } }

.b-contacts, .b-contacts * {
  box-sizing: border-box; }

.b-block.b-contacts {
  background: #000;
  color: #ffffff;
  position: relative; }
  .b-block.b-contacts .b-block__title {
    padding-bottom: 30px;
    margin: auto;
    color: #ffffff;
    width: 100%; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts-common {
    float: left;
    width: 400px; }
    .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone {
      font-size: 16pt;
      line-height: 1.2em; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone .b-contacts-phone__icon {
        display: block;
        width: 20px;
        height: 32px;
        float: left;
        margin-top: 0.3em;
        margin-right: 1em; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone .b-contacts-phone__icon svg g g {
          fill: white; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone .b-contacts-phone__address {
        font-weight: 100;
        font-size: 14pt; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone .b-contacts-phone__number a {
        text-decoration: none;
        cursor: pointer;
        color: white; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone .b-contacts-phone__end {
        clear: both; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone + .b-contacts-phone {
        margin-top: 1.5em; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone.b-contacts-phone--no-address {
        line-height: 32px; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-common .b-contacts-phone.b-contacts-phone--no-address .b-contacts-phone__icon {
          margin-top: 0; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts__btn {
    display: none;
    width: 220px;
    height: 50px;
    margin: 2em auto;
    background-color: #0032E9; }
    .b-block.b-contacts .b-contacts-feedback .b-contacts__btn a {
      display: block;
      padding: 10px;
      border: 2px solid #ffffff;
      color: #ffffff;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      font-family: "DaxLinePro", sans-serif; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts-form {
    margin-left: 0; }
    .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form {
      margin: 0 0.5em; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form textarea[name="message"] {
        height: auto; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .button {
        height: 60px; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .button.button-send {
          width: 240px; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .button.button-upload .svg-icon {
          margin-left: 0.5em;
          display: inline-block;
          vertical-align: middle;
          width: 24px; }
          .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .button.button-upload .svg-icon svg g g {
            fill: #9b9b9b; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .button.button-upload:disabled .svg-icon svg g g {
          fill: #353535; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .button + .button {
        margin-left: 1em; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .upload-limit {
        margin-right: 1em;
        color: #9b9b9b; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .upload-limit.error {
          color: #F74343; }
      .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .attached-files .attached-file {
        line-height: 1.5em; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .attached-files .attached-file .attached-file-delete {
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          width: 16px;
          margin-right: 1.5em; }
          .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .attached-files .attached-file .attached-file-delete .svg-animate {
            fill: white; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .attached-files .attached-file .attached-file__size {
          margin-left: 1em; }
        .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .attached-files .attached-file:hover {
          color: #9b9b9b; }
          .b-block.b-contacts .b-contacts-feedback .b-contacts-form .form .attached-files .attached-file:hover .attached-file-delete .svg-animate {
            fill: #9b9b9b; }
  .b-block.b-contacts .b-contacts-feedback .b-footer-addresses {
    text-align: center;
    width: 700px;
    margin: 2em auto auto;
    color: #888888; }
    .b-block.b-contacts .b-contacts-feedback .b-footer-addresses .b-footer-addresses__item {
      display: inline-block;
      min-width: 200px;
      max-width: 350px;
      padding: 0 1em; }
    .b-block.b-contacts .b-contacts-feedback .b-footer-addresses .b-footer-addresses__link {
      color: #fff; }
  .b-block.b-contacts .b-contacts-feedback .b-footer-year {
    color: #888888;
    margin-top: 1em;
    text-align: center; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts-feedback__end {
    clear: both; }

@media screen and (max-width: 990px) {
  .b-block.b-contacts .b-contacts-feedback .b-contacts-common {
    float: none;
    width: 100%; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts__btn {
    display: block; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts-form {
    display: none; } }

@media screen and (max-width: 700px) {
  .b-block.b-contacts .b-block__title {
    font-size: 24px;
    line-height: 29px; }
  .b-block.b-contacts .b-contacts-feedback .b-contacts__btn {
    display: block;
    width: 176px;
    height: 50px;
    bottom: 40px;
    margin-bottom: 8px;
    background-color: #0032E9; }
    .b-block.b-contacts .b-contacts-feedback .b-contacts__btn:hover {
      background-color: transparent; }
  .b-block.b-contacts .b-contacts-feedback .b-footer-addresses {
    margin: 2em auto;
    width: auto;
    min-width: 280px; }
    .b-block.b-contacts .b-contacts-feedback .b-footer-addresses .b-footer-addresses__item + .b-footer-addresses__item {
      margin-top: 1em;
      padding: 0; } }

.b-footer, .b-footer * {
  box-sizing: border-box; }

.b-footer .b-popup, .b-footer .b-popup * {
  box-sizing: content-box; }

.b-footer {
  background: #000;
  color: #ffffff;
  padding: 2em 0;
  position: relative; }
  .b-footer .b-footer-title {
    font-size: 26pt;
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 1em; }
  .b-footer .b-footer-button a {
    display: block;
    width: 196px;
    margin: auto;
    padding: 10px;
    border: 2px solid #ffffff;
    background-color: #0032E9;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif; }
    .b-footer .b-footer-button a:hover {
      color: #ffffff;
      background: none; }
  .b-footer .b-footer-addresses {
    text-align: center;
    width: 700px;
    margin: 0 auto;
    color: #888888; }
    .b-footer .b-footer-addresses .b-footer-addresses__item {
      display: inline-block;
      min-width: 200px;
      max-width: 350px;
      padding: 0 1em; }
  .b-footer .b-footer-year {
    color: #888888;
    margin-top: 1em;
    text-align: center; }

@media screen and (max-width: 700px) {
  .b-footer .b-footer-addresses {
    margin: 2em auto;
    width: auto;
    min-width: 280px; }
    .b-footer .b-footer-addresses .b-footer-addresses__item + .b-footer-addresses__item {
      margin-top: 1em;
      padding: 0; } }

* {
  -webkit-text-size-adjust: none;
  /*фиксим баг в IOS cо шкалированием в landscape*/
  outline: none;
  /*убираем рамку вокруг ссылок и кнопок*/
  -webkit-touch-callout: none;
  /*если надо, то запрещаем выделение текста*/
  -webkit-tap-highlight-color: transparent;
  /*убираем фон подсветки ссылок и кнопок*/
  -webkit-appearance: none;
  /*убираем iOS стили форм*/
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-regular-wf.eot");
  src: url("../css/daxlinepro-regular-wf.eot?#iefix") format("embedded-opentype"), url("../css/daxlinepro-regular-wf.woff") format("woff"), url("../css/daxlinepro-regular-wf.ttf") format("truetype"), url("../css/daxlinepro-regular-wf.svg#daxlinepro-regularregular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-light-wf.eot");
  src: url("../css/daxlinepro-light-wf.eot?#iefix") format("embedded-opentype"), url("../css/daxlinepro-light-wf.woff") format("woff"), url("../css/daxlinepro-light-wf.ttf") format("truetype"), url("../css/daxlinepro-light-wf.svg#daxlineprolight") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-medium-wf.eot");
  src: url("../css/daxlinepro-medium-wf.eot?#iefix") format("embedded-opentype"), url("../css/daxlinepro-medium-wf.woff") format("woff"), url("../css/daxlinepro-medium-wf.ttf") format("truetype"), url("../css/daxlinepro-medium-wf.svg#daxlinepromedium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-thin-wf.eot");
  src: url("../css/daxlinepro-thin-wf.eot?#iefix") format("embedded-opentype"), url("../css/daxlinepro-thin-wf.woff") format("woff"), url("../css/daxlinepro-thin-wf.ttf") format("truetype"), url("../css/daxlinepro-thin-wf.svg#daxlineprothin") format("svg");
  font-weight: 200;
  font-style: normal; }

html, body {
  min-width: 320px;
  min-height: 100%; }

body {
  background: #ffffff;
  color: #303030;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", Arial, Tahoma, sans-serif; }

a, .link {
  color: #3262C4;
  text-decoration: none;
  cursor: pointer; }

a:hover, .link:hover {
  color: #303030;
  text-decoration: none; }

.dotted:hover {
  text-decoration: none; }

p {
  margin: 0 0 10px 0;
  padding: 0; }

.b-page {
  min-height: 100%;
  position: relative; }

.l-wrap {
  max-width: 1400px;
  margin: auto; }

.l-menu__wrap {
  /* max-width: 1530px; */
  width: 100%;
  /* min-width: 1200px; */
  padding: 0 25px;
  margin-right: 30px;
  position: relative; }

.b-head {
  padding: 29px 0;
  border-bottom: 1px solid transparent;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 400;
  -moz-transition: height 300ms linear;
  -webkit-transition: height 300ms linear;
  -o-transition: height 300ms linear;
  transition: height 300ms linear; }

.b-head__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  -o-opacity: 0;
  opacity: 0; }

.b-head .l-wrap {
  padding: 0 25px;
  position: relative;
  width: auto;
  max-width: none; }

.b-head a {
  color: #ffffff; }

.b-head a:hover {
  color: #f0f0f0; }

.b-head__lang {
  float: right;
  width: 88px;
  margin: -5px 0 0 35px; }

.b-head__lang a, .b-head__lang span {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border: 1px solid #ffffff;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  text-decoration: none; }

.b-head__lang a:hover, .b-head__lang .active {
  color: #083088;
  background: #ffffff; }

.b-head__logo {
  display: none;
  width: 101px;
  margin-top: -29px;
  margin-left: -25px;
  position: absolute; }

.inner .b-head__logo {
  display: block; }

.b-head__menutrigger {
  display: none;
  width: 46px;
  height: 43px;
  position: absolute;
  top: -7px;
  cursor: pointer;
  -moz-transition: background 300ms linear;
  -webkit-transition: background 300ms linear;
  -o-transition: background 300ms linear;
  transition: background 300ms linear; }

.b-head__menutrigger i {
  display: block;
  width: 26px;
  height: 3px;
  background: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  -moz-transition: background 300ms linear;
  -webkit-transition: background 300ms linear;
  -o-transition: background 300ms linear;
  transition: background 300ms linear; }

.b-head__menutrigger i i {
  left: 0; }

.scrolled .b-head__menutrigger i {
  background: #000000; }

.scrolled .b-head__menutrigger {
  left: 100px; }

.b-head__menu {
  overflow: hidden;
  margin-left: 0px; }

.b-head__nav {
  float: left;
  font-size: 18px;
  line-height: 26px;
  font-family: "DaxLinePro", sans-serif; }

.b-head__nav a {
  margin-right: 20px; }

.b-head__nav__end {
  clear: both; }

.b-head-menu-socials {
  display: none; }

.b-head__menu__close {
  display: none; }

.b-head__socials {
  float: right;
  margin-left: 35px;
  padding-top: 3px; }

.b-head__socials a {
  margin-left: 30px; }

.b-head__socials a img {
  vertical-align: top; }

.b-head__phone {
  float: right;
  font-size: 18px;
  line-height: 26px; }

.scrolled .b-head {
  color: #000; }
  .scrolled .b-head a {
    color: #000; }

.scrolled .b-head__bg {
  -moz-opacity: 1;
  -webkit-opacity: 1;
  -o-opacity: 1;
  opacity: 1; }

.scrolled .b-head__nav {
  margin-left: 100px; }

.scrolled .b-head a:hover, .scrolled .b-head a.active {
  color: #a4a4a4; }

.scrolled .b-head__socials a img {
  display: none; }

.scrolled .b-head__socials a img.d-n {
  display: inline-block; }

.scrolled .b-head__lang a, .scrolled .b-head__lang span {
  border-color: #cccccc;
  background-color: #ffffff;
  color: #cccccc; }

.scrolled .b-head__lang a:hover, .scrolled .b-head__lang .active {
  color: #ffffff;
  background: #cccccc; }

.b-projects__one {
  display: block;
  float: left;
  width: 25%;
  position: relative; }

.b-projects_alternate {
  position: relative; }

.b-projects_alternate:after {
  display: block;
  content: " ";
  height: 1px;
  background: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0; }

.b-projects_alternate .b-projects__one {
  box-sizing: border-box; }
  .b-projects_alternate .b-projects__one * {
    box-sizing: border-box; }
  .b-projects_alternate .b-projects__one.b-projects--action .b-professionals__image {
    background-image: url("../img/projects/action/thumb.png"); }
  .b-projects_alternate .b-projects__one.b-projects--dostavista .b-professionals__image {
    background-image: url("../img/projects/dostavista/thumb.png"); }
  .b-projects_alternate .b-projects__one.b-projects--nissan .b-professionals__image {
    background-image: url("../img/projects/nissan/thumb.png"); }
  .b-projects_alternate .b-projects__one.b-projects--graviwar .b-professionals__image {
    background-image: url("../img/projects/graviwar/thumb.png"); }
  .b-projects_alternate .b-projects__one.b-projects--vedomosti .b-professionals__image {
    background-image: url("../img/projects/vedomosti/thumb.png"); }
  .b-projects_alternate .b-projects__one.b-projects--width-one-third {
    width: 33.333%; }
    .b-projects_alternate .b-projects__one.b-projects--width-one-third .b-professionals__image {
      padding-bottom: 70.3125%; }
  .b-projects_alternate .b-projects__one.b-projects--width-55 {
    width: 55%; }
    .b-projects_alternate .b-projects__one.b-projects--width-55 .b-professionals__image {
      padding-bottom: 42.6136%; }
  .b-projects_alternate .b-projects__one.b-projects--width-45 {
    width: 45%; }
    .b-projects_alternate .b-projects__one.b-projects--width-45 .b-professionals__image {
      padding-bottom: 52.0833%; }

.b-projects__one .b-professionals__image {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-size: cover; }

.b-projects__one .b-professionals__image img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

.b-projects__one .b-professionals__text {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.b-projects__one .b-professionals__name {
  bottom: 60%; }

.b-projects__one .b-professionals__job {
  top: 50%; }

.b-projects__one .b-professionals__job a {
  display: block;
  width: 147px;
  height: 46px;
  margin: auto;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  line-height: 46px;
  font-weight: 400;
  font-family: "DaxLinePro", sans-serif; }

.b-projects__one .b-professionals__job a:hover {
  background: #ffffff;
  color: #303003; }

.b-block {
  padding: 80px 0; }

.b-block__title {
  padding-bottom: 60px;
  color: #000000;
  font-size: 36px;
  line-height: 50px;
  font-weight: 100;
  text-align: center;
  font-family: "DaxLinePro", sans-serif; }

.b-whatwedo__one {
  float: left;
  width: 25%;
  margin-top: 30px;
  text-align: center; }

.b-whatwedo__title {
  padding: 25px 0 10px 0;
  color: #191919;
  font-size: 24px;
  line-height: 40px;
  font-family: "DaxLinePro", sans-serif; }

.b-whatwedo__text {
  padding: 0 20px; }

.b-theytrust {
  background: #f8f6f3; }

.b-theytrust__names {
  margin-top: 30px;
  text-align: center; }

.b-theytrust__names a, .b-theytrust__names span {
  margin: 0 10px;
  color: #303030; }

.b-theytrust__list {
  margin-top: 43px;
  overflow: hidden; }

.b-theytrust__one {
  display: block;
  float: left;
  width: 25%;
  height: 124px;
  margin: -1px 0 0 -1px;
  padding: 7px 0;
  border-top: 1px solid #e0deda;
  border-left: 1px solid #e0deda;
  text-align: center;
  position: relative; }

.b-theytrust__one img {
  display: block;
  margin: auto;
  padding: 0;
  position: relative;
  z-index: 2;
  -moz-transition: -moz-opacity 300ms linear;
  -webkit-transition: -webkit-opacity 300ms linear;
  -o-transition: -o-opacity 300ms linear;
  transition: opacity 300ms linear;
  width: 210px;
  height: 130px; }

.b-morethandev .l-wrap {
  max-width: 1310px;
  padding: 0 45px; }

.b-morethandev__image {
  float: left;
  margin: 30px 46px 0 0; }

.b-morethandev__text {
  margin-top: 30px;
  overflow: hidden; }

.b-morethandev__name {
  color: #000000;
  font-size: 24px;
  line-height: 40px;
  font-family: "DaxLinePro", sans-serif; }

.b-morethandev__job {
  margin-bottom: 5px;
  color: #000000;
  font-size: 14px;
  line-height: 22px; }

.b-morethandev__slogan {
  margin-bottom: 30px;
  color: #191919; }

.b-morethandev__descr {
  padding-left: 33px; }

.b-morethandev__descr p {
  display: block;
  float: left;
  width: 50%; }

.b-morethandev__descr p:first-child {
  margin-right: 33px;
  margin-left: -33px; }

.b-platforms {
  padding: 115px 0;
  background: #1A2754 url("../img/base/pbg.png") repeat 50% 0;
  color: #ffffff;
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  text-align: center; }

.b-platforms__icos {
  margin-bottom: 31px; }

.b-platforms__icos img {
  vertical-align: middle;
  margin: 0 40px; }

.b-awards .l-wrap {
  text-align: center;
  max-width: 1200px; }

.b-awards-group {
  display: inline-block; }

.b-awards-group + .b-awards-group {
  margin-top: 20px; }

.b-awards-item {
  display: inline-block;
  width: 260px;
  vertical-align: middle; }

.b-awards__header {
  display: table;
  height: 130px;
  margin: 0 auto; }

.b-awards__image {
  display: table-cell;
  width: 168px;
  height: 130px;
  vertical-align: middle;
  text-align: center;
  overflow: hidden; }

.b-awards-item.no-title .b-awards__image {
  height: 195px; }

.b-awards__title {
  width: 200px;
  height: 65px;
  color: #000000;
  font-size: 40px;
  line-height: 65px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto; }

.b-awards__brief {
  margin: 0 auto;
  height: 60px; }

.b-reviews {
  background: #f8f6f3; }

.b-reviews .l-wrap {
  max-width: 1210px; }

.b-reviews__carousel {
  padding-top: 30px;
  position: relative; }
  .b-reviews__carousel li {
    max-width: 450px; }

.b-carousel {
  margin: 0 30px;
  position: relative;
  overflow: hidden; }

.b-carousel__prev, .b-carousel__next {
  margin-top: -28px;
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer; }

.b-carousel__next {
  right: 0;
  left: auto; }

.b-carousel__prev.disabled, .b-carousel__next.disabled {
  display: none; }

.b-reviews ul, .b-reviews li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none; }

.b-reviews ul {
  width: 10000%;
  position: relative; }

.b-reviews li {
  float: left;
  margin: 0 85px 0 70px; }

.b-reviews li:before {
  display: none; }

.b-reviews__image {
  float: left;
  margin-right: 20px; }

.b-reviews__image img {
  display: block;
  margin: 0;
  padding: 0; }

.b-reviews__ov {
  overflow: hidden; }

.b-reviews__name {
  font-size: 24px;
  line-height: 37px;
  font-family: "DaxLinePro", sans-serif; }

.b-reviews__job {
  width: 190px;
  font-size: 14px;
  line-height: 22px; }

.b-reviews__quote {
  padding: 10px 0 5px 0;
  font-size: 16px;
  line-height: 22px;
  font-style: italic; }

.b-reviews__text {
  width: 435px;
  padding-top: 10px; }

.b-reviews__more {
  padding-top: 10px;
  font-size: 18px;
  line-height: 27px; }

.b-professionals .l-wrap {
  max-width: 1170px; }

.b-professionals__list {
  padding: 1% 0 0 0.8%; }

.b-professionals__list ul, .b-professionals__list li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none; }

.b-professionals__list li {
  float: left;
  width: 25%;
  position: relative; }

.b-professionals__list li:before {
  display: none; }

.b-professionals__image {
  padding: 0 3% 3% 0; }

.b-professionals__image img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

.b-professionals__text {
  background: rgba(12, 12, 12, 0.9);
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 0;
  right: 3%;
  bottom: 3%;
  left: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  -moz-transition: -moz-opacity 200ms linear;
  -webkit-transition: -webkit-opacity 200ms linear;
  -o-transition: -o-opacity 200ms linear;
  transition: opacity 200ms linear; }

.b-professionals li:hover .b-professionals__text, .b-projects__one:hover .b-professionals__text {
  -moz-opacity: 1;
  -webkit-opacity: 1;
  -o-opacity: 1;
  opacity: 1; }

.b-professionals__name {
  font-size: 24px;
  line-height: 40px;
  font-family: "DaxLinePro", sans-serif;
  position: absolute;
  right: 20px;
  bottom: 45%;
  left: 20px; }

.b-professionals__job {
  font-size: 18px;
  line-height: 34px;
  position: absolute;
  top: 55%;
  right: 20px;
  left: 20px; }

.b-workway {
  background: #1A2754 url("../img/base/pbg.png") repeat 50% 0;
  color: #ffffff;
  text-align: center; }

.b-workway .b-block__title {
  color: #ffffff; }

.b-workway__one {
  display: inline-block;
  width: 20%;
  margin: 70px 2% 30px 2%;
  vertical-align: top; }

.b-workway__image {
  height: 50px;
  margin-bottom: 20px; }

.b-workway__text {
  font-size: 20px;
  line-height: 31px;
  font-family: "DaxLinePro", sans-serif;
  overflow: hidden; }

.b-announces .l-wrap {
  padding: 0 45px; }

.b-announces__section {
  float: left;
  width: 47%;
  padding-top: 30px; }

.b-announces__section_right {
  float: right; }

.b-announces__title {
  margin-bottom: 37px;
  color: #383838;
  font-size: 24px;
  line-height: 33px; }

.b-announces__one {
  margin-bottom: 30px; }

.b-announces__image {
  float: left;
  margin-right: 30px; }

.b-announces__text {
  overflow: hidden; }

.b-announces__date {
  margin-bottom: 2px;
  color: #b7b7b7;
  font-size: 16px;
  line-height: 22px; }

.b-announces__name {
  font-size: 24px;
  line-height: 33px; }

.b-announces__descr {
  margin-top: 10px;
  font-size: 18px;
  line-height: 24px; }

.b-announces__more {
  padding-top: 10px;
  font-size: 18px;
  line-height: 27px;
  text-align: right; }

@keyframes field-shake {
  0%, 100% {
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateX(10px); } }

@-webkit-keyframes field-shake {
  0%, 100% {
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateX(10px); } }

.b-field__shake {
  animation: field-shake 0.7s both;
  -webkit-animation: field-shake 0.7s both; }

.b-field__hint {
  position: absolute;
  margin-top: -15px;
  font-family: "DaxLinePro", sans-serif;
  font-size: 18px;
  color: #F74343;
  opacity: 0.0;
  transition: opacity 100ms linear, margin-top 100ms ease-out; }

.b-field__hint__visible {
  margin-top: -25px;
  opacity: 1.0; }

/* inner page */
.b-textpage {
  margin-bottom: 55px;
  padding-top: 84px; }

.b-textpage_nohead {
  padding-top: 124px; }

.b-textpage .l-wrap {
  max-width: 1080px;
  padding: 0 45px; }

.b-textpage__head {
  padding: 120px 0 40px 0;
  margin-bottom: 70px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  color: #ffffff;
  position: relative; }

.b-textpage__date {
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 19px; }

.b-textpage__title {
  font-size: 48px;
  line-height: 65px; }

.b-textpage__title2 {
  font-size: 24px;
  line-height: 32px; }

.b-textpage__quote {
  font-style: italic; }

.b-textpage p {
  margin-bottom: 24px; }

.b-textpage__image {
  margin-bottom: 24px;
  padding: 24px 0;
  text-align: center; }

.b-textpage__image img {
  max-width: 100%;
  height: auto; }

.b-textpage__video {
  text-align: center; }

.b-textpage__video iframe {
  margin: 0 auto; }

.b-textpage__socials {
  padding-top: 75px;
  text-align: center; }

.b-textpage__comments {
  padding-top: 20px;
  text-align: center; }

/* case page */
.b-casehead {
  height: 700px;
  min-height: 300px;
  padding-top: 84px;
  background-repeat: no-repeat;
  background-position: 50% 84px; }

.b-casehead__video {
  height: 100%; }

.b-casehead__video iframe {
  display: block;
  width: 100%;
  height: 100%; }

.b-casehead .l-wrap {
  margin-top: 80px;
  padding: 0 45px; }

.b-casehead__logo {
  margin-bottom: 4px;
  max-width: 100%; }

.b-casehead__descr {
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 33px;
  max-width: 100%; }

.b-caseblock {
  padding-top: 80px;
  position: relative; }

.b-caseblock_withsides {
  overflow: hidden; }

.b-caseblock__left {
  position: absolute;
  left: 0;
  top: 50%; }

.b-caseblock__right {
  position: absolute;
  right: 0;
  top: 50%; }

.b-caseblock_tripled {
  margin-bottom: 175px;
  padding-bottom: 1px; }

.b-caseblock_screens {
  margin-bottom: 50px; }

.b-caseblock_parallax {
  background-repeat: repeat;
  background-position: 50% 50%; }

.b-caseblock_parallax_withscreen {
  margin-bottom: 400px;
  padding-bottom: 1px; }

.b-caseblock_parallax .b-caseblock__screen {
  padding: 0;
  margin-top: -300px;
  margin-bottom: -389px; }

.b-caseblock__title {
  max-width: 900px;
  margin: 0 auto 20px auto;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  position: relative;
  z-index: 1; }

.b-caseblock__subtitle {
  max-width: 660px;
  margin: auto;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  position: relative;
  z-index: 1; }

.b-caseblock__text {
  max-width: 660px;
  margin: auto;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  position: relative;
  z-index: 1; }

.b-caseblock_parallax__things {
  height: 1275px;
  position: relative;
  overflow: hidden; }

.b-caseblock_parallax__things img {
  display: block;
  position: absolute;
  top: 0;
  left: 50% !important; }

.b-caseblock__screen00 {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  position: relative;
  overflow: hidden; }

.b-caseblock__triple {
  width: 972px;
  margin: 0 auto -175px auto; }

.b-caseblock__triple__one {
  float: left;
  width: 274px;
  margin: 80px 25px 0 25px; }

.b-caseblock__triple__descr {
  font-size: 18px;
  line-height: 24px;
  font-style: italic;
  text-align: center; }

.b-caseblock__triple__image {
  width: 374px;
  margin: -10px -50px 0 -50px;
  text-align: center; }

.b-caseblock__screen {
  padding: 50px 0 70px 0;
  position: relative;
  z-index: 1; }

.b-caseblock__screen--nopadding {
  padding: 0; }

.b-caseblock__screen_flex {
  margin: auto; }

.b-caseblock__screen_flex img {
  display: block;
  width: 100%;
  height: auto; }

.b-caseblock__screen_bg {
  margin-top: 50px;
  padding-top: 80px; }

.b-caseblock__screen__descr {
  max-width: 410px;
  margin: 0 auto 40px auto;
  font-size: 20px;
  line-height: 27px;
  font-style: italic;
  text-align: center; }

.b-caseblock__screen__descr_arrow {
  max-width: 320px;
  padding: 70px 20px 0 50px;
  background: url("../img/base/case-arrow.png") no-repeat 0 0;
  text-align: left;
  position: absolute;
  left: 50%;
  margin-left: 160px; }

.b-caseblock__screen__image {
  margin: auto;
  text-align: center; }

.b-caseblock-carousel {
  margin: 40px 0 90px 0;
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.b-caseblock-carousel_flex {
  margin: 0;
  overflow: hidden; }

.b-caseblock-carousel__wrap {
  padding: 160px 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative; }

.b-caseblock-carousel__viewport {
  margin: auto;
  position: relative;
  overflow: hidden; }

.b-caseblock-carousel_flex .b-caseblock-carousel__viewport {
  overflow: visible; }

.b-caseblock-carousel_flex:before, .b-caseblock-carousel_flex:after {
  display: block;
  content: " ";
  width: 200px;
  background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, white), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1; }

.b-caseblock-carousel_flex:after {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, white));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* IE10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
  /* IE6-9 */
  right: 0;
  left: auto; }

.b-caseblock-carousel ul, .b-caseblock-carousel li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none; }

.b-caseblock-carousel ul {
  width: 10000%;
  position: relative; }

.b-caseblock-carousel li {
  float: left; }

.b-caseblock-carousel_flex li {
  margin: 0 100px; }

.b-caseblock-carousel_flex li.noM {
  margin: 0; }

.b-caseblock-carousel li:before {
  display: none; }

.b-caseblock-carousel img {
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  height: auto; }

.b-caseblock-carousel_flex li img, .b-caseblock-carousel_respond li img {
  max-width: 100%;
  height: auto; }

.b-caseblock-carousel__prev, .b-caseblock-carousel__next {
  width: 119px;
  height: 119px;
  margin-top: -60px;
  background: url("../img/base/arrowleft_bg.png") no-repeat 50% 50%;
  position: absolute;
  top: 50%;
  left: 5%;
  z-index: 2;
  cursor: pointer; }

.b-caseblock-carousel__next {
  background-image: url("../img/base/arrowright_bg.png");
  right: 5%;
  left: auto; }

.b-caseblock-selector {
  margin: 0 auto;
  text-align: center; }

.b-caseblock-viewport {
  width: 100%; }
  .b-caseblock-viewport .b-caseblock-viewport-image {
    position: absolute;
    left: 0;
    top: 0; }

.b-case-review {
  padding: 120px 0 100px 0;
  background: #f8f6f3; }

.b-case-team__one {
  padding-bottom: 100px; }

.b-case-team .l-wrap, .b-case-review .l-wrap {
  max-width: 685px; }

.b-case-photos {
  position: relative;
  overflow: hidden; }

.b-case-photos ul, .b-case-photos li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none; }

.b-case-photos ul {
  width: 10000%;
  position: relative; }

.b-case-photos li {
  float: left; }

.b-case-photos li:before {
  display: none; }

.b-case-photos li img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

.b-case-photos__prev, .b-case-photos__next {
  display: block;
  width: 81px;
  height: 81px;
  margin-top: -40px;
  background: url("../img/base/arrowleft_bg.png") no-repeat 0 0;
  position: absolute;
  top: 50%;
  left: 50px;
  cursor: pointer; }

.b-case-photos__next {
  background-image: url("../img/base/arrowright_bg.png");
  right: 50px;
  left: auto; }

.b-case-review__author {
  margin-bottom: 20px; }

.b-case-review__photo {
  float: left;
  margin-right: 20px; }

.b-case-review__person {
  overflow: hidden; }

.b-case-review__name {
  font-size: 24px;
  line-height: 33px; }

.b-case-review__job {
  font-size: 20px;
  line-height: 27px; }

.b-case-review__text {
  font-size: 18px;
  line-height: 24px; }
  .b-case-review__text p + p {
    margin-top: 24px; }

.b-case-foot {
  padding: 80px 0 40px 0;
  background: #083088 url("../img/base/pbg.png") repeat 50% 0;
  color: #ffffff; }

.b-case-foot a {
  color: #ffffff; }

.b-case-foot__btn {
  margin-bottom: 45px;
  padding-top: 60px; }

.b-case-foot__btn a {
  display: block;
  width: 196px;
  margin: auto;
  padding: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  color: #083088;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-family: "DaxLinePro", sans-serif; }

.b-case-foot__btn a:hover {
  color: #ffffff;
  background: none; }

.b-case-foot__nav {
  max-width: 570px;
  margin: auto;
  padding: 60px 0 20px 0;
  font-size: 20px;
  line-height: 27px; }

.b-case-foot__nav__prev {
  float: left;
  padding-left: 25px;
  background: url("../img/base/arrowleft.png") no-repeat 0 50%; }

.b-case-foot__nav__next {
  float: right;
  padding-right: 25px;
  background: url("../img/base/arrowright.png") no-repeat 100% 50%; }

.b-anchor {
  display: block;
  margin: -84px 0 84px 0; }

@media screen and (max-width: 1280px) {
  .hide-lowres {
    display: none !important; }
  .l-wrap {
    padding: 0 45px; }
  .b-head .l-wrap {
    width: auto;
    min-width: 50%; }
  .b-head__nav a {
    margin-right: 10px; }
  .b-head__logo {
    right: auto; }
  .b-workway__list {
    margin-left: -5%; }
  .b-caseblock-carousel_flex:before, .b-caseblock-carousel_flex:after {
    display: none; } }

@media screen and (max-width: 1024px) {
  .scrolled .b-head__nav {
    margin-left: 0px; }
  .l-wrap {
    min-width: 890px; }
  .b-theytrust__one img {
    width: 152px;
    height: 94px;
    margin-top: 12px; }
  .b-head__menutrigger {
    display: block; }
  .b-head__menu {
    display: none; }
  .scrolled .b-head__menu {
    background: #ffffff; }
  .b-head__logo {
    right: auto; }
  .b-head__nav a {
    display: block;
    margin-right: 0;
    padding: 5px 30px; }
  .scrolled .b-head__nav a.active {
    color: #000; }
  .b-block {
    padding: 70px 0; }
  .b-projects__one {
    width: 50%; }
  .b-whatwedo {
    padding-bottom: 30px; }
  .b-whatwedo .br.d-n {
    display: block; }
  .b-whatwedo__one {
    width: 34%;
    margin: 30px 8% 90px 8%; }
  .b-theytrust .l-wrap {
    padding: 0;
    min-width: 1000px; }
  .b-theytrust__names a, .b-theytrust__names span {
    margin: 0 5px; }
  .b-morethandev__descr {
    padding-left: 0; }
  .b-morethandev__descr p {
    float: none;
    width: auto; }
  .b-morethandev__descr p:first-child {
    margin-right: 0;
    margin-left: 0; }
  .b-awards .l-wrap {
    width: auto; }
  .b-awards__one {
    width: 400px; }
  .b-awards__one_5, .b-awards__one_6 {
    width: auto; }
  .b-carousel__prev {
    left: -20px; }
  .b-carousel__next {
    right: -20px; }
  .b-professionals .l-wrap {
    min-width: 910px; }
  .b-workway__one {
    width: 20%;
    margin: 30px 2% 15px 2%; }
  .b-workway__image {
    float: none;
    height: 64px; }
  .b-announces__section {
    float: none;
    width: auto; }
  .b-caseblock-carousel__prev {
    left: 0; }
  .b-caseblock-carousel__next {
    right: 0; }
  .b-caseblock__triple {
    width: auto;
    margin: auto; }
  .b-caseblock__triple__one {
    float: none;
    margin: auto; } }

@media only screen and (max-width: 990px) and (min-width: 481px) {
  .b-popup {
    width: auto;
    margin-left: 0;
    right: 30px;
    left: 30px; } }

@media screen and (max-width: 990px) {
  .l-wrap {
    min-width: 590px; }
  .b-caseblock-carousel__prev, .b-caseblock-carousel__next {
    display: none !important; }
  .b-projects__one .b-professionals__job a {
    width: 110px;
    height: 29px;
    border: 1px solid #ffffff;
    font-size: 14px;
    line-height: 29px; }
  .b-theytrust .l-wrap {
    padding: 0;
    min-width: 700px; }
  .b-awards .l-wrap {
    width: auto; }
  .b-awards__one {
    width: 35%;
    margin-left: 10%; }
  .b-awards__one_5, .b-awards__one_6 {
    width: auto;
    margin-left: 0; }
  .b-awards__one_6 {
    padding-top: 100px; }
  .b-awards__one_4 .b-awards__image {
    height: 151px; }
  .b-awards__image, .b-awards__title {
    float: none !important; }
  .b-reviews .l-wrap {
    padding: 0; }
  .b-reviews .b-carousel {
    margin: 0; }
  .b-carousel__prev, .b-carousel__next {
    display: none; }
  .b-professionals .l-wrap {
    padding: 0;
    min-width: 700px; }
  .b-professionals__name {
    font-size: 18px;
    line-height: 30px;
    font-family: "DaxLinePro", sans-serif;
    position: absolute;
    right: 10px;
    bottom: 45%;
    left: 10px; }
  .b-professionals__job {
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    top: 55%;
    right: 20px;
    left: 20px; }
  #fader {
    background: rgba(12, 12, 12, 0.84); }
  .b-popup__close {
    top: 20px;
    right: 20px; }
  .b-popup__close img {
    display: none; }
  .b-popup__close img.d-n {
    display: block !important; }
  .b-caseblock-carousel__wrap {
    padding: 0;
    background: none !important; }
  .b-caseblock__screen__descr_arrow {
    max-width: 410px;
    margin: 0 auto 40px auto;
    padding: 0;
    background: none;
    text-align: center;
    position: static;
    left: auto; } }

@media screen and (max-width: 1024px) {
  .hide-mobile, .b-carousel__prev, .b-carousel__next, .b-reviews__text, .b-reviews__more, .b-workway, .b-announces, .b-caseblock-carousel__prev, .b-caseblock-carousel__next, .b-case-photos__prev, .b-case-photos__next {
    display: none !important; }
  .b-head {
    margin-bottom: -1px;
    padding: 0;
    border-color: transparent;
    position: static;
    z-index: 1; }
  .b-head .l-wrap {
    position: static; }
  .b-head__lang {
    position: fixed;
    top: 15px;
    right: 10px;
    z-index: 1; }
  .b-head__menutrigger {
    width: 44px;
    height: 44px;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 404; }
  .scrolled .b-head__menutrigger {
    background: rgba(255, 255, 255, 0.9);
    left: 15px; }
  .b-head__menutrigger i {
    width: 25px;
    height: 2px;
    top: 11px;
    left: 10px; }
  .b-head__menutrigger i i {
    top: 10px; }
  .b-head__menu {
    padding: 90px 0 10px 0;
    background: #ffffff;
    color: #000000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 410; }
  .b-head__phone {
    position: fixed;
    left: 70px;
    right: 110px;
    top: 20px;
    z-index: 405;
    text-align: right;
    font-size: 15px; }
  .scrolled .b-head__phone a {
    opacity: 0;
    color: #fff;
    pointer-events: none; }
  .b-head__nav {
    position: absolute;
    top: 90px;
    right: 0;
    left: 0;
    overflow-y: auto; }
  .b-head__nav a {
    padding: 8px 70px;
    color: #000000;
    font-size: 27px;
    line-height: 37px; }
  .b-head__nav__end {
    display: none; }
  .b-head-menu-socials {
    display: block;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    padding: 8px 70px; }
    .b-head-menu-socials a img {
      width: 20px;
      height: auto; }
  .b-head__nav a:hover {
    color: #a4a4a4; }
  .b-head__menu__close {
    display: block;
    width: 44px;
    height: 33px;
    padding-top: 12px;
    text-align: center;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer; }
  .b-head__socials {
    opacity: 0;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1; }
  .b-head__logo {
    margin: 0;
    padding: 0;
    position: fixed;
    top: 25px;
    left: 70px;
    right: auto;
    z-index: 5;
    opacity: 0 !important; }
  .b-head.active .b-head__mobile-logo {
    display: none;
    -moz-transition: -moz-opacity 300ms linear;
    -webkit-transition: -webkit-opacity 300ms linear;
    -o-transition: -o-opacity 300ms linear;
    transition: opacity 300ms linear; }
  .b-head__logo img.d-n {
    display: none !important; }
  .b-head__lang {
    width: 98px;
    padding: 8px 0 0 0;
    margin-right: 0px;
    -moz-transition: -moz-opacity 300ms linear;
    -webkit-transition: -webkit-opacity 300ms linear;
    -o-transition: -o-opacity 300ms linear;
    transition: opacity 300ms linear; }
  .scrolled .b-head__lang {
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    opacity: 0; }
  .b-head__lang a, .b-head__lang span, .scrolled .b-head__lang a, .scrolled .b-head__lang span {
    width: 28px;
    height: 28px;
    margin-left: 15px;
    border: 1px solid #ffffff;
    background: none;
    font-size: 12px;
    line-height: 28px; }
  .scrolled .b-head__lang a:hover, .scrolled .b-head__lang .active {
    color: #083088;
    background: #ffffff; }
  .b-head__lang a:hover, .b-head__lang .active {
    color: #083088;
    background: #ffffff; }
  .l-wrap {
    min-width: 290px;
    padding: 0 15px; }
  .b-block {
    padding: 60px 0; }
  .b-block__title {
    padding-bottom: 30px;
    font-size: 24px;
    line-height: 29px; }
  .b-projects_alternate .b-projects__one {
    float: none;
    width: auto !important;
    overflow: hidden;
    text-align: center; }
    .b-projects_alternate .b-projects__one.b-projects--action .b-professionals__image {
      background-image: url("../img/projects/action/thumb_720.jpg"); }
    .b-projects_alternate .b-projects__one.b-projects--dostavista .b-professionals__image {
      background-image: url("../img/projects/dostavista/thumb_720.jpg"); }
    .b-projects_alternate .b-projects__one.b-projects--nissan .b-professionals__image {
      background-image: url("../img/projects/nissan/thumb_720.jpg"); }
    .b-projects_alternate .b-projects__one.b-projects--graviwar .b-professionals__image {
      background-image: url("../img/projects/graviwar/thumb_720.jpg"); }
    .b-projects_alternate .b-projects__one.b-projects--vedomosti .b-professionals__image {
      background-image: url("../img/projects/vedomosti/thumb_720.jpg"); }
    .b-projects_alternate .b-projects__one .b-professionals__image {
      padding-bottom: 41.6666% !important; }
  .b-whatwedo__one {
    float: none;
    width: auto;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: left; }
  .b-whatwedo__image {
    float: left;
    width: 44px;
    height: 37px;
    margin-right: 15px;
    padding-top: 7px;
    text-align: center; }
  .b-whatwedo__image img {
    width: auto;
    height: 30px; }
  .b-whatwedo__title {
    padding: 13px 0 2px 59px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500; }
  .b-whatwedo__text {
    padding: 0 0 0 59px;
    font-size: 16px;
    line-height: 22px; }
  .b-theytrust .l-wrap {
    width: auto;
    min-width: 320px;
    padding: 0; }
  .b-theytrust__names {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 20px; }
  .b-theytrust__one {
    width: 50%; }
  .b-morethandev {
    padding-bottom: 14px; }
  .b-morethandev .l-wrap {
    padding: 0 15px; }
  .b-morethandev__image {
    margin: 0 15px 10px 0; }
  .b-morethandev__image img {
    width: 90px;
    height: 90px; }
  .b-morethandev__text {
    margin: 0;
    overflow: visible; }
  .b-morethandev__name {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500; }
  .b-morethandev__job {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 18px; }
  .b-morethandev__slogan {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px; }
  .b-morethandev__text p {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 22px; }
  .b-platforms {
    padding: 35px 15px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600; }
  .b-platforms__icos img {
    width: 30px;
    height: auto;
    margin: 0 20px; }
  .b-awards__image img {
    width: 120px; }
  .b-awards-item.no-title .b-awards__image {
    height: 165px; }
  .b-awards__title {
    height: 35px;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600; }
  .b-awards__one img {
    width: 84px;
    height: auto; }
  .b-reviews .l-wrap {
    width: auto;
    min-width: 320px;
    margin: 0;
    padding: 0; }
  .b-reviews__carousel {
    padding-top: 0; }
  .b-carousel {
    margin: 0; }
  .b-reviews__carousel li {
    width: 250px;
    margin: 0 13px 0 15px; }
  .b-reviews__image img {
    width: 60px;
    height: 60px; }
  .b-reviews__name {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500; }
  .b-reviews__job {
    width: auto;
    font-size: 13px;
    line-height: 18px; }
  .b-reviews__quote {
    font-size: 12px;
    line-height: 22px; }
  .b-professionals {
    overflow: hidden; }
  .b-professionals .l-wrap {
    min-width: 320px; }
  .b-professionals .b-block__title {
    padding: 0 45px 30px 45px; }
  .b-professionals__list {
    height: 170px;
    padding: 0;
    position: relative;
    overflow: hidden; }
  .b-professionals__list ul {
    width: 1000%;
    position: relative; }
  .b-professionals__list li {
    display: block;
    float: left;
    width: 170px;
    height: 170px;
    margin: 0 5px;
    padding: 0; }
  .b-professionals__name {
    font-size: 15px;
    line-height: 20px;
    right: 10px;
    left: 10px; }
  .b-professionals__job {
    font-size: 9px;
    line-height: 17px;
    right: 10px;
    left: 10px; }
  .b-projects__one .b-professionals__job a {
    width: 150px;
    height: 50px;
    border: 1px solid #ffffff;
    font-size: 15px;
    line-height: 50px; }
  .b-professionals__image {
    padding: 0; }
  .b-professionals__text {
    right: 0;
    bottom: 0; }
  /* inner page */
  .inner.scrolled .b-head {
    background: none;
    border: none; }
  .b-textpage {
    margin-bottom: 20px;
    padding: 0; }
  .b-textpage_nohead {
    padding-top: 80px; }
  .b-textpage .l-wrap {
    min-width: 260px;
    padding: 0 15px; }
  .b-textpage__head {
    margin-bottom: 30px;
    padding: 80px 0 30px 0; }
  .b-textpage__title {
    font-size: 36px;
    line-height: 48px; }
  .b-textpage__title2 {
    font-size: 22px;
    line-height: 28px; }
  .b-textpage__socials {
    padding-top: 20px; }
  .b-textpage__comments {
    padding-top: 20px; }
  .b-anchor {
    display: block;
    margin: 0; }
  #popupFeedback {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: auto !important;
    padding: 15px 30px;
    overflow-y: auto; }
  .b-popup_small .b-popup__title {
    font-size: 28px;
    line-height: 20px; }
  #popupFeedback .b-popup__form__field {
    margin-bottom: 30px;
    text-align: center; }
  #popupFeedback .b-popup__form__field input,
  #popupFeedback .b-popup__form__field textarea {
    padding: 8px 12px;
    width: 90%;
    margin: 0 auto; } }

@media screen and (max-width: 700px) and (orientation: landscape) {
  .b-theytrust__one img {
    width: 105px;
    height: 65px;
    margin: auto; } }

@media screen and (max-width: 550px) {
  /* Вертикальное расположение Awards на главной */
  .b-awards__header, .b-awards__image,
  .b-awards-item.no-title .b-awards__header, .b-awards-item.no-title .b-awards__image {
    height: auto; }
  .b-head__bg {
    display: none; }
  .b-awards-group + .b-awards-group,
  .b-awards-item + .b-awards-item {
    margin-top: 20px; } }

@media screen and (max-width: 360px) {
  #popupFeedback .b-popup__title {
    font-size: 20px;
    line-height: 20px; }
  .b-popup__form__field input, .b-popup__form__field textarea {
    font-size: 14px; }
  .b-popup__close {
    width: 16px; }
  .b-popup__close img.d-n {
    width: 100%; }
  #popupFeedback .b-popup__form__field {
    margin-bottom: 20px; }
  .b-popup__form__submit input {
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
  .b-popup__form__field textarea {
    height: 52px; }
  #popupFeedback .b-popup__form__field input,
  #popupFeedback .b-popup__form__field textarea {
    padding: 4px 12px; }
  .b-popup__form__submit {
    padding-top: 0; }
  .b-field__hint {
    font-size: 12px; }
  .b-projects_alternate .b-projects__one.b-projects--action .b-professionals__image {
    background-image: url("../img/projects/action/thumb_360.jpg"); }
  .b-projects_alternate .b-projects__one.b-projects--dostavista .b-professionals__image {
    background-image: url("../img/projects/dostavista/thumb_360.jpg"); }
  .b-projects_alternate .b-projects__one.b-projects--nissan .b-professionals__image {
    background-image: url("../img/projects/nissan/thumb_360.jpg"); }
  .b-projects_alternate .b-projects__one.b-projects--graviwar .b-professionals__image {
    background-image: url("../img/projects/graviwar/thumb_360.jpg"); }
  .b-projects_alternate .b-projects__one.b-projects--vedomosti .b-professionals__image {
    background-image: url("../img/projects/vedomosti/thumb_360.jpg"); }
  .b-projects_alternate .b-projects__one .b-professionals__image {
    padding-bottom: 72.2222% !important; } }

/* animations */
@-webkit-keyframes buzz-out {
  5% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  10% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  15% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  25% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  30% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  35% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  40% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  45% {
    -webkit-transform: translateX(1px) rotate(0deg);
    transform: translateX(1px) rotate(0deg); }
  50% {
    -webkit-transform: translateX(-1px) rotate(0deg);
    transform: translateX(-1px) rotate(0deg); }
  55% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg); }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg); } }

@keyframes buzz-out {
  5% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  10% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  15% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  25% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  30% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  35% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  40% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  45% {
    -webkit-transform: translateX(1px) rotate(0deg);
    transform: translateX(1px) rotate(0deg); }
  50% {
    -webkit-transform: translateX(-1px) rotate(0deg);
    transform: translateX(-1px) rotate(0deg); }
  55% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg); }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg); } }

.buzz-out {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation-name: buzz-out;
  animation-name: buzz-out;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes bob-float {
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bob-float {
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.bob-float {
  -webkit-animation-name: bob-float;
  animation-name: icon-bob-float;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate; }

.js-fade {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0; }

.unselectable {
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

.b-spacer {
  height: 84px; }

.sebbia-event img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.fixed-scroll {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.b-titleblock {
  height: 100%;
  background: #083088 url("../img/base/cover/cover1.jpg") no-repeat 50% 100%;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative; }
  .b-titleblock .b-titleblock__logo {
    width: 256px;
    height: 256px;
    margin: auto;
    position: absolute;
    top: -100px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0032E9; }
  .b-titleblock .b-titleblock__descr {
    height: 30px;
    margin: auto;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif;
    position: absolute;
    top: -50px;
    right: 0;
    bottom: 0;
    left: 0; }
  .b-titleblock .b-titleblock__showreal {
    width: 220px;
    height: 50px;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 150px;
    left: 0; }
  .b-titleblock .b-titleblock__showreal a {
    display: block;
    padding: 10px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif; }
  .b-titleblock .b-titleblock__showreal a:hover {
    color: #fff;
    background: #0032E9; }
  .b-titleblock .b-titleblock__showreal a:hover .a {
    display: none; }
  .b-titleblock .b-titleblock__showreal a .b {
    display: none; }
  .b-titleblock .b-titleblock__showreal a:hover .b {
    display: inline; }
  .b-titleblock .b-titleblock__btn {
    width: 220px;
    height: 50px;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 80px;
    left: 0; }
  .b-titleblock .b-titleblock__btn a {
    display: block;
    padding: 10px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif; }
  .b-titleblock .b-titleblock__btn a:hover {
    color: #083088;
    background: #ffffff; }

#popupShowReal {
  width: 720px;
  height: 405px;
  padding: 0;
  background: transparent; }
  #popupShowReal .showreal-video {
    width: 720px;
    height: 405px; }

@media screen and (max-width: 990px) {
  #popupShowReal {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: auto !important;
    padding: 0;
    z-index: 560;
    overflow-y: auto;
    background: transparent;
    text-align: center; }
    #popupShowReal .b-popup__close img {
      display: block; }
      #popupShowReal .b-popup__close img.d-n {
        display: none; }
    #popupShowReal .showreal-video {
      margin-top: 60px;
      width: 100%;
      height: 70%; } }

@media screen and (max-width: 700px) {
  .b-titleblock {
    min-height: 320px; }
    .b-titleblock .b-titleblock__logo, .b-titleblock .b-titleblock__logo img {
      width: 200px;
      height: 200px; }
    .b-titleblock .b-titleblock__showreal {
      width: 176px;
      bottom: 120px; }
    .b-titleblock .b-titleblock__descr {
      height: 15px;
      font-size: 15px;
      line-height: 23px;
      font-weight: 300;
      top: -110px; }
    .b-titleblock .b-titleblock-showreal {
      height: 70px;
      width: 70px;
      top: 20px; }
    .b-titleblock .b-titleblock__btn {
      width: 176px;
      height: 50px;
      bottom: 40px; }
    .b-titleblock .b-titleblock__btn a {
      padding: 14px 0;
      font-size: 15px;
      line-height: 23px; }
    .b-titleblock .b-titleblock__showreal a {
      font-size: 15px; }
  #popupShowReal {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: auto !important;
    padding: 0;
    z-index: 560;
    overflow-y: auto;
    background: transparent;
    text-align: center; }
    #popupShowReal .b-popup__close img {
      display: block; }
      #popupShowReal .b-popup__close img.d-n {
        display: none; }
    #popupShowReal .showreal-video {
      margin-top: 60px;
      width: 100%;
      height: 70%; } }

.b-page.b-platform-page .b-platform-head {
  position: relative;
  min-height: 300px;
  padding-top: 84px;
  background: no-repeat 50% 50%; }
  .b-page.b-platform-page .b-platform-head .title {
    font-size: 36pt;
    font-weight: 400;
    line-height: 1.2em; }
  .b-page.b-platform-page .b-platform-head .description {
    font-size: 24pt;
    font-weight: 100;
    margin-top: 2em;
    line-height: 1.2em; }

.b-page.b-platform-page .b-platform-block, .b-page.b-platform-page .b-platform-block * {
  box-sizing: border-box; }

.b-page.b-platform-page .b-platform-block {
  position: relative;
  font-size: 12pt;
  line-height: 1.5em;
  background: no-repeat 50% 50%;
  background-size: 100% auto; }
  .b-page.b-platform-page .b-platform-block a, .b-page.b-platform-page .b-platform-block .link {
    text-decoration: underline; }
  .b-page.b-platform-page .b-platform-block p {
    text-indent: 0;
    margin: 0; }
  .b-page.b-platform-page .b-platform-block ol {
    margin: 0;
    list-style: decimal inside;
    text-indent: 0.5em; }
  .b-page.b-platform-page .b-platform-block ul {
    margin: 0;
    list-style: disc outside;
    padding-left: 2em; }
  .b-page.b-platform-page .b-platform-block ol li, .b-page.b-platform-page .b-platform-block ul li {
    margin: 0; }
    .b-page.b-platform-page .b-platform-block ol li:before, .b-page.b-platform-page .b-platform-block ul li:before {
      content: none; }
  .b-page.b-platform-page .b-platform-block p + p, .b-page.b-platform-page .b-platform-block ul + p, .b-page.b-platform-page .b-platform-block ol + p {
    margin-top: 0.5em; }
  .b-page.b-platform-page .b-platform-block .title {
    font-family: DaxLinePro, sans-serif;
    font-size: 26pt;
    line-height: 1em;
    font-weight: 100; }
  .b-page.b-platform-page .b-platform-block .sub-title {
    font-family: "Open Sans", Arial, Tahoma, sans-serif;
    font-size: 14pt;
    line-height: 1.2em;
    font-weight: 600; }
  .b-page.b-platform-page .b-platform-block h3 {
    font-family: "Open Sans", Arial, Tahoma, sans-serif;
    font-size: 14pt;
    font-weight: 600; }
  .b-page.b-platform-page .b-platform-block .block-row {
    text-align: left;
    margin-left: -1em;
    margin-right: -1em; }
    .b-page.b-platform-page .b-platform-block .block-row .block-col {
      float: left;
      width: 100%;
      padding: 0 1em; }
      .b-page.b-platform-page .b-platform-block .block-row .block-col.block-col-6 {
        width: 50%; }
    .b-page.b-platform-page .b-platform-block .block-row .block-row__end {
      clear: both; }
  .b-page.b-platform-page .b-platform-block .illustration {
    background: no-repeat 50% 50%;
    background-size: cover; }

@media screen and (max-width: 1200px) {
  .b-page.b-platform-page .b-head__logo {
    width: 101px;
    margin: auto;
    position: absolute;
    right: 0;
    left: 0; } }

@media screen and (max-width: 700px) {
  .b-page.b-platform-page .l-wrap {
    min-width: 450px;
    padding-left: 30px;
    padding-right: 30px; }
  .b-page.b-platform-page .b-head .b-head__logo {
    width: 160px;
    opacity: 1 !important; }
    .b-page.b-platform-page .b-head .b-head__logo img {
      display: none !important; }
    .b-page.b-platform-page .b-head .b-head__logo img.d-n {
      display: block !important;
      width: 160px;
      height: auto; }
  .b-page.b-platform-page .b-platform-head .title {
    font-size: 32pt;
    line-height: 1.4em; }
  .b-page.b-platform-page .b-platform-head .description {
    font-size: 20pt;
    line-height: 1.5em; }
  .b-page.b-platform-page .b-platform-block {
    background-size: auto; }
    .b-page.b-platform-page .b-platform-block .title {
      line-height: 1.2em; } }

@media screen and (max-width: 550px) {
  .b-page.b-platform-page .l-wrap {
    min-width: 280px;
    padding-left: 15px;
    padding-right: 15px; } }

.b-page.b-platform-page.b-platform-android {
  overflow-x: hidden; }
  .b-page.b-platform-page.b-platform-android .b-platform-head {
    display: table;
    width: 100%;
    height: 440px;
    background-image: url("/img/platforms/android/bg_android_1920.png");
    color: white; }
    .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap {
      display: table-cell;
      text-align: center;
      vertical-align: middle; }
  .b-page.b-platform-page.b-platform-android .b-platform-block .l-wrap {
    max-width: 960px; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages {
    padding: 3em 0 5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap {
      text-align: center; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .title {
        margin-right: 445px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .sub-title {
        margin-right: 445px;
        margin-top: 2em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row {
        margin: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .description {
          margin-right: 380px;
          padding-right: 2em; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration {
          display: table;
          margin-top: -5em;
          float: right;
          width: 380px; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration .image {
            display: table-cell;
            text-align: left;
            vertical-align: middle; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration .image img {
              width: 380px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row + .block-row {
          margin-top: 0.5em; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-services {
    padding: 5em 0 5em;
    background-image: url("../img/platforms/android/bg_2_1920.png");
    color: white; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row {
      margin: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .illustration {
        float: left;
        margin-top: -2em;
        width: 400px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .illustration img {
          width: 400px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .sub-title {
        margin-bottom: 1em;
        margin-left: 400px;
        padding-left: 50px;
        font-size: 18pt;
        font-weight: 400; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .description {
        margin-left: 400px;
        padding-left: 50px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .description li + li {
          margin-top: 0.8em; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts {
    padding: 5em 0 5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row {
      margin: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .illustration {
        float: right;
        width: 460px;
        margin-top: -4em; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .illustration img {
          width: 460px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .sub-title {
        margin-bottom: 1em;
        margin-right: 460px;
        padding-right: 50px;
        font-size: 18pt;
        font-weight: 400; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .description {
        margin-right: 460px;
        padding-right: 50px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .description ul + p {
          margin-top: 1.5em; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-team {
    padding: 5em 0 5em;
    background-color: #d5efff; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row.block-row-head {
      margin: 0; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row .sub-title {
      font-size: 18pt;
      font-weight: 400;
      margin-top: 0.5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row h3 {
      margin-bottom: 1em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row .illustration {
      width: 100%;
      text-align: center;
      margin-bottom: 2em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row .illustration img {
        width: 390px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row .description {
      max-width: 550px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row .description li + li {
        margin-top: 1em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .block-row + .block-row {
      margin-top: 3em; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-process {
    padding: 3em 0 5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row {
      margin: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration {
        float: right;
        margin-top: -3em;
        width: 420px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration img {
          width: 420px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .description {
        margin-right: 420px;
        padding-right: 2em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row + .block-row {
        margin-top: 1em; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-price {
    padding: 3em 0 5em;
    background-image: url("../img/platforms/android/bg_3_1920.png");
    color: white; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row {
      margin: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration {
        float: left;
        margin-top: -3em;
        width: 550px;
        text-align: center; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder {
          display: none;
          padding: 0;
          margin: 0 auto;
          height: 350px;
          width: 450px; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder > li {
            height: 320px;
            width: 240px;
            box-shadow: 0 0 10px #777777;
            cursor: pointer;
            overflow: hidden;
            background: white;
            opacity: 1 !important; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card {
            text-align: left;
            color: #262626; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-content {
              padding: 2em 1em 0; }
              .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-content h1 {
                color: #262626;
                font-size: 150%;
                font-family: "Open Sans", Arial, Tahoma, sans-serif;
                line-height: 80%;
                padding: 0 0.5em; }
              .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-content .price-card-description {
                font-size: 80%;
                margin-top: 1em;
                line-height: 1.3em;
                color: #4a4a4a; }
                .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-content .price-card-description ul {
                  list-style: none inside;
                  margin: 0;
                  padding: 0; }
                  .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-content .price-card-description ul > li {
                    margin: 0;
                    line-height: 1.3em; }
                    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-content .price-card-description ul > li:before {
                      content: "-";
                      margin-left: 0;
                      margin-right: 0.5em; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-footer {
              position: absolute;
              display: table;
              left: 0;
              right: 0;
              width: 100%;
              bottom: 0;
              height: 20%;
              font-size: 160%;
              line-height: 1.5em; }
              .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card .price-card-footer .content {
                display: table-cell;
                padding: 0 0.5em;
                text-align: center;
                vertical-align: middle; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card.price-card--business .price-card-footer {
              background-color: #00ccf1;
              color: #262626; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card.price-card--all .price-card-description {
              padding: 0 1em; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card.price-card--all .price-card-footer {
              background-color: #d5d5ff;
              color: rgba(50, 51, 50, 0.8); }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder .price-card.price-card--startup .price-card-footer {
              background-color: #d5d5ff;
              color: rgba(50, 51, 50, 0.8); }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .sub-title {
        margin-bottom: 1em;
        margin-left: 550px;
        padding-left: 50px;
        font-size: 18pt;
        font-weight: 400; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .description {
        max-width: 550px;
        margin-left: 550px;
        padding-left: 50px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .description li + li {
          margin-top: 0.8em; }
  .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies {
    padding: 3em 0 5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row {
      margin: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .illustration {
        float: right;
        margin-top: -3em;
        width: 230px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .illustration img {
          width: 230px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .description {
        margin-right: 230px;
        padding-right: 2em; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .description li {
          margin-top: 0.5em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row + .block-row {
        margin-top: 1em; }
  @media screen and (max-width: 1200px) {
    .b-page.b-platform-page.b-platform-android .b-platform-head {
      height: 400px; }
      .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap {
        min-width: 800px; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .title {
          font-size: 32pt;
          line-height: 1.2em; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .description {
          font-size: 20pt;
          margin-top: 1.5em;
          line-height: 1.2em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .illustration {
      width: 250px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .illustration img {
        width: 250px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .sub-title {
      margin-left: 250px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .description {
      margin-left: 250px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .illustration {
      width: 350px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .illustration img {
        width: 350px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .sub-title {
      margin-right: 350px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .description {
      margin-right: 350px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration {
      width: 300px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration img {
        width: 300px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .description {
      margin-right: 300px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration {
      width: 400px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder {
        height: 350px;
        width: 300px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .sub-title {
      margin-left: 400px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .description {
      margin-left: 400px; } }
  @media screen and (max-width: 990px) {
    .b-page.b-platform-page.b-platform-android .b-platform-head {
      height: 400px; }
      .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap {
        min-width: 600px; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .title {
          font-size: 32pt;
          line-height: 1.2em; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .description {
          font-size: 20pt;
          margin-top: 1.5em;
          line-height: 1.2em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages {
      padding-bottom: 3em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .title {
        text-align: center;
        margin-right: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .sub-title {
        margin-right: 0;
        margin-top: 1em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .description {
        margin-right: 0;
        padding-right: 0;
        margin-top: 1em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        float: none;
        width: 300px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration .image {
          text-align: center;
          vertical-align: middle; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration .image img {
            width: 300px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row + .block-row {
        margin-top: 1.5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-services {
      padding-top: 3em;
      padding-bottom: 3em;
      background-image: url("../img/platforms/android/bg_2_800.png"); }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row {
        margin: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .sub-title {
          text-align: center;
          margin-bottom: 2em;
          margin-left: 0;
          padding-left: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .description {
          margin-top: 1em;
          margin-left: 0;
          padding-left: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .illustration {
          margin-left: auto;
          margin-right: auto;
          float: none;
          margin-top: 0;
          width: 250px; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .illustration img {
            width: 250px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts {
      padding: 3em 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .illustration {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        float: none;
        width: 300px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .illustration img {
          width: 300px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .sub-title {
        text-align: center;
        margin-bottom: 1em;
        margin-right: 0;
        padding-right: 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-experts .block-row .description {
        margin-top: 1em;
        margin-right: 0;
        padding-right: 0; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team {
      padding: 3em 0; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .title {
        text-align: center; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .sub-title {
        margin-top: 1em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .block-col.block-col-6 {
        float: none;
        width: 100%; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .block-col + .block-col-6 {
        margin-top: 2em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .description {
        max-width: none; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .description li + li {
          margin-top: 0; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-process {
      padding-bottom: 3em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row {
        margin: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .title {
          text-align: center; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration {
          margin-left: auto;
          margin-right: auto;
          float: none;
          margin-top: 0;
          width: 350px; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration img {
            width: 350px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .description {
          margin-top: 2em;
          margin-right: 0;
          padding-right: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row + .block-row {
          margin-top: 2em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price {
      padding: 3em 0;
      background-image: url("../img/platforms/android/bg_3_800.png");
      color: white; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row {
        margin: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration {
          margin-right: auto;
          margin-left: auto;
          float: none;
          margin-top: 0;
          width: 550px; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder {
            padding: 0;
            margin: 0 auto;
            height: 300px;
            width: 450px; }
            .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder > li {
              height: 320px;
              width: 240px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .sub-title {
          text-align: center;
          margin-left: 0;
          padding-left: 0;
          margin-bottom: 1.5em; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .description {
          margin-top: 2em;
          margin-left: 0;
          padding-left: 0; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies {
      padding-bottom: 3em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row {
        margin: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .title {
          text-align: center; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .illustration {
          margin-right: auto;
          margin-left: auto;
          float: none;
          margin-top: 0;
          width: 230px; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .illustration img {
            width: 230px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .description {
          margin-top: 2em;
          margin-right: 0;
          padding-right: 0; }
          .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row .description li {
            margin-top: 0; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-technologies .block-row + .block-row {
          margin-top: 2em; } }
  @media screen and (max-width: 700px) {
    .b-page.b-platform-page.b-platform-android .b-platform-head {
      height: 600px;
      background-size: 100% auto;
      background-image: url("/img/platforms/android/bg_android_800.png"); }
      .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap {
        min-width: 450px; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .title {
          font-size: 32pt; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .description {
          font-size: 20pt;
          margin-top: 1.5em; } }
  @media screen and (max-width: 550px) {
    .b-page.b-platform-page.b-platform-android .b-platform-head {
      height: 360px; }
      .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap {
        min-width: 280px; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .title {
          font-size: 28pt;
          font-weight: 400; }
        .b-page.b-platform-page.b-platform-android .b-platform-head .l-wrap .description {
          font-size: 16pt;
          line-height: 1.5em;
          margin-top: 1.5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration {
      width: 280px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .illustration .image img {
        width: 280px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row .description {
      margin-top: 2.5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-advantages .l-wrap .block-row + .block-row {
      margin-top: 2.5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .description {
      margin-top: 2em; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-services .block-row .description li + li {
        margin-top: 0.5em; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-team .l-wrap .block-row .illustration img {
      width: 280px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration {
      width: 280px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-process .block-row .illustration img {
        width: 280px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration {
      width: 290px; }
      .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder {
        padding: 0;
        margin: 0 auto;
        height: 300px;
        width: 290px; }
        .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .illustration .price-slider-holder > li {
          height: 280px;
          width: 230px; }
    .b-page.b-platform-page.b-platform-android .b-platform-block.block-price .block-row .description {
      margin-left: 0;
      padding-left: 0; } }

.b-project .b-footer .b-project-nav {
  max-width: 570px;
  margin: 2.5em auto;
  font-size: 14pt;
  line-height: 1.5em; }
  .b-project .b-footer .b-project-nav .b-project-nav__next, .b-project .b-footer .b-project-nav .b-project-nav__prev {
    color: white; }
    .b-project .b-footer .b-project-nav .b-project-nav__next:hover, .b-project .b-footer .b-project-nav .b-project-nav__prev:hover {
      color: #666666; }
  .b-project .b-footer .b-project-nav .b-project-nav__prev {
    float: left;
    padding-left: 25px;
    background: url("../img/base/arrowleft.png") no-repeat 0 50%; }
  .b-project .b-footer .b-project-nav .b-project-nav__next {
    float: right;
    padding-right: 25px;
    background: url("../img/base/arrowright.png") no-repeat 100% 50%; }

.b-casehead-vedomosti {
  height: 1100px;
  position: relative;
  background: url("../img/projects/vedomosti/cover.png") 100% 84px;
  color: #332f2e; }
  .b-casehead-vedomosti .l-wrap {
    height: 100%;
    margin: auto; }
    .b-casehead-vedomosti .l-wrap .b-casehead__logo {
      position: absolute;
      top: -300px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 600px;
      height: 112px; }
      .b-casehead-vedomosti .l-wrap .b-casehead__logo img {
        width: 600px; }
    .b-casehead-vedomosti .l-wrap .b-casehead__descr {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 1000px;
      height: 150px;
      margin: auto;
      text-align: center;
      color: white; }
    .b-casehead-vedomosti .l-wrap .b-casehead__btns {
      position: absolute;
      bottom: 80px;
      left: 0;
      right: 0;
      height: 56px;
      margin: auto;
      text-align: center; }
      .b-casehead-vedomosti .l-wrap .b-casehead__btns a {
        margin: 0;
        padding: 0; }
      .b-casehead-vedomosti .l-wrap .b-casehead__btns a + a {
        margin-left: 1em; }
      .b-casehead-vedomosti .l-wrap .b-casehead__btns img {
        width: 194px;
        height: 56px; }

.b-caseblock.vedomosti {
  padding-top: 50px; }
  .b-caseblock.vedomosti.b-caseblock--bright {
    background-color: #fff6f1; }
  .b-caseblock.vedomosti.b-caseblock--dark {
    background-color: #332f2e; }
    .b-caseblock.vedomosti.b-caseblock--dark .b-caseblock__title {
      color: white; }
    .b-caseblock.vedomosti.b-caseblock--dark .b-caseblock__subtitle {
      color: #857871; }
  .b-caseblock.vedomosti .b-caseblock__text {
    max-width: 1100px;
    margin-bottom: 1em; }
  .b-caseblock.vedomosti .b-caseblock__title {
    max-width: 1100px; }
  .b-caseblock.vedomosti .b-caseblock__subtitle {
    max-width: 1200px;
    margin-bottom: 2em; }
  .b-caseblock.vedomosti.b-caseblock-viewport {
    padding-bottom: 50px; }
    .b-caseblock.vedomosti.b-caseblock-viewport .b-image-viewport {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 1000px;
      overflow: hidden; }
      .b-caseblock.vedomosti.b-caseblock-viewport .b-image-viewport .b-caseblock-viewport-image {
        width: 2600px;
        height: 2220px;
        background-image: url("../img/projects/vedomosti/scheme.png");
        background-size: cover; }
  .b-caseblock.vedomosti.b-caseblock-vedomosti-formats {
    padding-bottom: 14em; }
    .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage {
      margin: 50px auto 0;
      background: none;
      width: 100%;
      height: 498px; }
      .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap {
        padding: 0;
        background: none;
        overflow: hidden;
        width: 100%;
        height: 498px; }
        .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__prev, .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__next {
          color: white; }
        .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap:before, .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap:after {
          display: block;
          content: "";
          width: 200px;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1; }
        .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap:before {
          left: 0;
          background: -moz-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
          /* FF3.6+ */
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, #332f2e), color-stop(100%, rgba(255, 255, 255, 0)));
          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
          /* Opera 11.10+ */
          background: -ms-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
          /* IE10+ */
          background: linear-gradient(to right, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
          /* IE6-9 */ }
        .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__wrap:after {
          right: 0;
          background: -moz-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
          /* FF3.6+ */
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(51, 47, 46, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
          /* Opera 11.10+ */
          background: -ms-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
          /* IE10+ */
          background: linear-gradient(to right, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
          /* IE6-9 */ }
      .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__viewport {
        overflow: visible;
        width: 658px;
        height: 498px;
        margin: 0 auto; }
        .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_formats-item {
          height: 498px;
          width: 658px;
          cursor: pointer;
          text-align: center; }
          .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_formats-item .b-caseblock-carousel_formats-item__image {
            margin: 0 auto;
            width: 558px;
            height: 498px;
            background-position: 0 0;
            background-size: cover; }
            .b-caseblock.vedomosti.b-caseblock-vedomosti-formats .b-formats-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_formats-item .b-caseblock-carousel_formats-item__image img {
              width: 558px;
              height: 498px; }
  .b-caseblock.vedomosti .b-inlines-navigation {
    margin: 1em auto 1em;
    background: none;
    width: 1000px;
    height: 80px; }
    .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel__wrap {
      padding: 0;
      background: none;
      width: 1000px; }
    .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel__viewport {
      overflow: hidden;
      width: 600px;
      height: 80px;
      margin: 0 auto; }
    .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item {
      height: 80px;
      width: 120px;
      text-align: center; }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item .b-caseblock-carousel_inline-item__image {
        margin: 0 auto 0.5em;
        width: 48px;
        height: 48px;
        background-position: 0 0;
        background-size: cover; }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item .b-caseblock-carousel_inline-item__descr {
        color: #857871; }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.active .b-caseblock-carousel_inline-item__image {
        background-position: -48px 0; }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.active .b-caseblock-carousel_inline-item__descr {
        color: #fff6f1; }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--quote .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/quote.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--text .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/text.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--picture .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/picture.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--timeline .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/timeline.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--gallery .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/gallery.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--doc .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/doc.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--info .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/info.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--link .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/link.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--video .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/video.png"); }
      .b-caseblock.vedomosti .b-inlines-navigation .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--youtube .b-caseblock-carousel_inline-item__image {
        background-image: url("../img/projects/vedomosti/carousel-inlines/youtube.png"); }
  .b-caseblock.vedomosti .b-inlines-stage {
    margin: 0 auto;
    background: none;
    width: 100%;
    height: 651px; }
    .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap {
      position: relative;
      padding: 0;
      background: none;
      overflow: hidden;
      width: 100%;
      height: 651px; }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap:before, .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap:after {
        display: block;
        content: "";
        width: 200px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1; }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap:before {
        left: 0;
        background: -moz-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #332f2e), color-stop(100%, rgba(255, 255, 255, 0)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
        /* IE10+ */
        background: linear-gradient(to right, #332f2e 0%, rgba(51, 47, 46, 0) 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
        /* IE6-9 */ }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap:after {
        right: 0;
        background: -moz-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(51, 47, 46, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
        /* IE10+ */
        background: linear-gradient(to right, rgba(51, 47, 46, 0) 0%, #332f2e 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
        /* IE6-9 */ }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__prev, .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__next {
        color: white; }
    .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport {
      overflow: visible;
      height: 651px;
      margin: 0 auto; }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_inline-item {
        height: 651px;
        cursor: pointer;
        text-align: center; }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_inline-item .b-caseblock-carousel_inline-item__image {
          margin: 0 auto;
          height: 651px;
          background-position: 0 0;
          background-size: cover; }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios {
        width: 1109px; }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item {
          width: 1109px; }
          .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item .b-caseblock-carousel_inline-item__image {
            width: 959px; }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--quote .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_quote.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--text .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_text.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--picture .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_img.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--timeline .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_chrono.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--gallery .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_gallery.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--doc .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_doc.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--info .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_info.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--link .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_link1.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--video .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_video.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--ios .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--youtube .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen2_youtube.png"); }
      .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android {
        width: 1139px; }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item {
          width: 1139px; }
          .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item .b-caseblock-carousel_inline-item__image {
            width: 989px; }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--quote .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_quote.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--text .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_text.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--picture .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_img.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--timeline .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_chrono.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--gallery .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_gallery.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--doc .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_doc.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--info .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_info.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--link .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_link1.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--video .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_video.png"); }
        .b-caseblock.vedomosti .b-inlines-stage .b-caseblock-carousel__viewport.b-inlines--android .b-caseblock-carousel_inline-item.b-caseblock-carousel_inline-item--youtube .b-caseblock-carousel_inline-item__image {
          background-image: url("../img/projects/vedomosti/carousel-inlines/screen1_youtube.png"); }
  .b-caseblock.vedomosti .b-caseblock-selector {
    font-size: 16pt;
    padding: 3em 0; }
    .b-caseblock.vedomosti .b-caseblock-selector .button {
      border: 2px solid #857871;
      border-radius: 4px;
      background-color: #332f2e;
      color: #857871;
      padding: 0.4em 1em;
      width: 160px;
      font-size: 16pt;
      cursor: pointer; }
      .b-caseblock.vedomosti .b-caseblock-selector .button.button--selected {
        background-color: #857871;
        color: #fff6f1; }
      .b-caseblock.vedomosti .b-caseblock-selector .button.button--first {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .b-caseblock.vedomosti .b-caseblock-selector .button.button--last {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
  .b-caseblock.vedomosti.b-caseblock-vedomosti-design .b-caseblock__screen_flex img {
    max-width: 1141px;
    margin-left: auto;
    margin-right: auto; }
  .b-caseblock.vedomosti.b-caseblock-vedomosti-newspaper {
    padding-bottom: 2em; }
    .b-caseblock.vedomosti.b-caseblock-vedomosti-newspaper .b-caseblock__screen_flex img {
      max-width: 1202px;
      margin-left: auto;
      margin-right: auto; }
  .b-caseblock.vedomosti.b-caseblock-vedomosti-hypercomments .b-caseblock__screen_flex img {
    max-width: 1142px;
    margin-left: auto;
    margin-right: auto; }
  .b-caseblock.vedomosti.b-caseblock-vedomosti-creatives .b-caseblock__screen_flex img {
    max-width: 1125px;
    margin-left: auto;
    margin-right: auto; }
  .b-caseblock.vedomosti.b-caseblock-vedomosti-sitemobile {
    padding-bottom: 50px; }
    .b-caseblock.vedomosti.b-caseblock-vedomosti-sitemobile .b-caseblock__screen_flex img {
      max-width: 1112px;
      margin-left: auto;
      margin-right: auto; }

.b-case-team {
  padding: 50px 0; }
  .b-case-team .b-case-team__one {
    padding: 0; }
  .b-case-team .b-case-team__one + .b-case-team__one {
    margin-top: 50px; }

.b-case-team--vedomosti {
  padding: 50px 0;
  background-color: #fff6f1; }
  .b-case-team--vedomosti .b-case-team__one {
    padding-bottom: 0; }

.b-casehead-action {
  height: 900px;
  position: relative;
  background: url("../img/projects/action/cover.jpg") 100% 84px;
  color: #332f2e; }
  .b-casehead-action .l-wrap {
    height: 100%;
    margin: auto; }
    .b-casehead-action .l-wrap .b-casehead__logo {
      position: absolute;
      top: -150px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 350px;
      height: 112px; }
      .b-casehead-action .l-wrap .b-casehead__logo img {
        width: 350px; }
    .b-casehead-action .l-wrap .b-casehead__descr {
      position: absolute;
      top: 150px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 70%;
      min-width: 800px;
      height: 150px;
      margin: auto;
      text-align: center;
      color: white; }

.b-caseblock.b-caseblock-action {
  padding: 5em 0 7em; }
  .b-caseblock.b-caseblock-action.b-caseblock--dark {
    background: #393b3f;
    color: #fff6f1; }
    .b-caseblock.b-caseblock-action.b-caseblock--dark .b-caseblock__title {
      color: white; }
    .b-caseblock.b-caseblock-action.b-caseblock--dark .b-caseblock__subtitle {
      color: #999999; }
  .b-caseblock.b-caseblock-action.b-caseblock--bright {
    background: #ffffff;
    color: #999999; }
    .b-caseblock.b-caseblock-action.b-caseblock--bright .b-caseblock__title {
      color: black; }
  .b-caseblock.b-caseblock-action .b-caseblock__text {
    max-width: 1100px;
    margin-bottom: 1em; }
  .b-caseblock.b-caseblock-action .b-caseblock__title {
    max-width: 1100px;
    line-height: 1.2em;
    margin-bottom: 0.5em; }
  .b-caseblock.b-caseblock-action .b-caseblock__subtitle {
    max-width: 1000px;
    margin-bottom: 2em;
    line-height: 1.5em;
    font-size: 20px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action--magazine {
    padding: 10em 0 12em;
    overflow: hidden; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine {
      position: relative;
      padding: 0;
      color: white;
      box-sizing: border-box; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine-description {
        position: relative;
        margin: auto;
        padding: 0 2em; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine-description .magazine-description__logo {
          text-align: left; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine-description .magazine-description__text {
          margin-top: 1em;
          line-height: 1.5em;
          font-size: 14pt; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine-description .magazine-description-stores {
          text-align: left;
          margin-top: 1.5em; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine-description .magazine-description-stores a {
            display: inline-block; }
            .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine-description .magazine-description-stores a img {
              vertical-align: middle; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine .magazine .magazine__end {
        clear: both; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action--ekiosk {
      background: #eeeeee; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action--ekiosk .magazine {
        max-width: 1200px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action--ekiosk .magazine .magazine-description {
          color: black;
          float: left;
          width: 500px; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action--ekiosk .magazine .magazine-description .magazine-description__logo {
            text-align: left; }
            .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action--ekiosk .magazine .magazine-description .magazine-description__logo img {
              width: 331px;
              height: 64px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action--ekiosk .magazine .magazine-screen {
          position: absolute;
          left: 550px;
          top: -50px;
          width: 629px;
          height: 499px;
          background: url("../img/projects/action/ekiosk/screen.png") top left no-repeat; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-glavbuh {
      background: #1d1d1b; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-glavbuh .magazine {
        background-size: auto 100%;
        height: 461px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-glavbuh .magazine .magazine-screen {
          position: absolute;
          right: 540px;
          top: -50px;
          width: 870px;
          height: 461px;
          background: url("../img/projects/action/glavbuh/screen.png") top left no-repeat; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-glavbuh .magazine .magazine-description {
          float: right;
          text-align: left;
          width: 460px;
          height: 380px; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-glavbuh .magazine .magazine-description .magazine-description__logo img {
            width: 200px; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-gendir {
      background: #ae9a63;
      padding: 14em 0 16em; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-gendir .magazine {
        max-width: 1000px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-gendir .magazine .magazine-description {
          float: left;
          width: 480px;
          height: 320px; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-gendir .magazine .magazine-description .magazine-description__logo img {
            width: 227px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-gendir .magazine .magazine-screen {
          position: absolute;
          left: 500px;
          top: -100px;
          width: 491px;
          height: 514px;
          background: url("../img/projects/action/gendir/screen.png") top right no-repeat; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-comdir {
      background: #3299bc;
      padding: 14em 0 14em; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-comdir .magazine {
        max-width: 1250px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-comdir .magazine .magazine-screen {
          position: absolute;
          right: 550px;
          top: -70px;
          width: 660px;
          height: 469px;
          background: url("../img/projects/action/comdir/screen.png") top right no-repeat; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-comdir .magazine .magazine-description {
          float: right;
          width: 480px; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-comdir .magazine .magazine-description .magazine-description__logo img {
            width: 261px; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-findir {
      background: #f98b33;
      padding: 12em 0 12em; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-findir .magazine .magazine-description {
        float: left;
        width: 480px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-findir .magazine .magazine-description .magazine-description__logo img {
          width: 260px; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-findir .magazine .magazine-screen {
        position: absolute;
        left: 520px;
        top: -50px;
        width: 860px;
        height: 448px;
        background: url("../img/projects/action/findir/screen.png") top right no-repeat; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-upr {
      background: #94bd4e;
      padding: 14em 0 16em; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-upr .magazine {
        max-width: 1000px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-upr .magazine .magazine-screen {
          position: absolute;
          right: 520px;
          top: -120px;
          width: 501px;
          height: 524px;
          background: url("../img/projects/action/upr/screen.png") top left no-repeat; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-upr .magazine .magazine-description {
          float: right;
          width: 450px; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-upr .magazine .magazine-description .magazine-description__logo img {
            width: 281px; }
    .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-lawyer {
      background: #08344d;
      padding: 14em 0 16em; }
      .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-lawyer .magazine {
        max-width: 1200px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-lawyer .magazine .magazine-description {
          float: left;
          width: 480px; }
          .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-lawyer .magazine .magazine-description .magazine-description__logo img {
            width: 240px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action--magazine.b-caseblock-action-lawyer .magazine .magazine-screen {
          position: absolute;
          left: 500px;
          top: -100px;
          width: 726px;
          height: 550px;
          background: url("../img/projects/action/lawyer/screen.png") top right no-repeat; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-ubu {
    background: #177a60; }
    .b-caseblock.b-caseblock-action.b-caseblock-action-ubu .magazine {
      max-width: 1000px; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-ubu .magazine .magazine-screen {
        position: absolute;
        right: 550px;
        top: -50px;
        width: 530px;
        height: 510px;
        background: url("../img/projects/action/ubu/screen.png") top left no-repeat; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-ubu .magazine .magazine-description {
        float: right;
        width: 480px; }
        .b-caseblock.b-caseblock-action.b-caseblock-action-ubu .magazine .magazine-description .magazine-description__logo img {
          width: 208px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-services .b-caseblock__subtitle {
    color: #fff6f1; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-services .b-caseblock__screen_flex .b-caseblock__screen__image img {
    margin: 0 auto;
    max-width: 847px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage {
    margin: 50px auto 0;
    background: none;
    width: 100%;
    height: 502px; }
    .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap {
      padding: 0;
      background: none;
      overflow: hidden;
      width: 100%;
      height: 502px; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__prev, .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__next {
        color: white; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap:before, .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap:after {
        display: block;
        content: "";
        width: 200px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap:before {
        left: 0;
        background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, white), color-stop(100%, rgba(255, 255, 255, 0)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* IE10+ */
        background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
        /* IE6-9 */ }
      .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__wrap:after {
        right: 0;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* IE10+ */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
        /* IE6-9 */ }
    .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__viewport {
      overflow: visible;
      width: 1042px;
      height: 502px;
      margin: 0 auto; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_easy-item {
        height: 502px;
        width: 1042px;
        cursor: pointer;
        text-align: center; }
        .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_easy-item .b-caseblock-carousel_easy-item__image {
          margin: 0 auto;
          width: 942px;
          height: 502px;
          background-position: 0 0;
          background-size: cover; }
          .b-caseblock.b-caseblock-action.b-caseblock-action-easy .b-easy-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_easy-item .b-caseblock-carousel_easy-item__image img {
            width: 942px;
            height: 502px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-catalog .b-caseblock__screen_flex .b-caseblock__screen__image img {
    margin: 0 auto;
    max-width: 1100px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage {
    margin: 50px auto 0;
    background: none;
    width: 100%;
    height: 502px; }
    .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap {
      padding: 0;
      background: none;
      overflow: hidden;
      width: 100%;
      height: 502px; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__prev, .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap .b-caseblock-carousel__next {
        color: white; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap:before, .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap:after {
        display: block;
        content: "";
        width: 200px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap:before {
        left: 0;
        background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, white), color-stop(100%, rgba(255, 255, 255, 0)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
        /* IE10+ */
        background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
        /* IE6-9 */ }
      .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__wrap:after {
        right: 0;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
        /* IE10+ */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1);
        /* IE6-9 */ }
    .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__viewport {
      overflow: visible;
      width: 1042px;
      height: 502px;
      margin: 0 auto; }
      .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_issues-item {
        height: 502px;
        width: 1042px;
        cursor: pointer;
        text-align: center; }
        .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_issues-item .b-caseblock-carousel_issues-item__image {
          margin: 0 auto;
          width: 942px;
          height: 502px;
          background-position: 0 0;
          background-size: cover; }
          .b-caseblock.b-caseblock-action.b-caseblock-action-issues .b-issues-stage .b-caseblock-carousel__viewport .b-caseblock-carousel_issues-item .b-caseblock-carousel_issues-item__image img {
            width: 942px;
            height: 502px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-fullscreen .b-caseblock__screen_flex .b-caseblock__screen__image img {
    margin: 0 auto;
    max-width: 1122px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-purchase .b-caseblock__title {
    margin-bottom: 1.5em; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-purchase .b-caseblock__screen_flex .b-caseblock__screen__image img {
    margin: 0 auto;
    max-width: 1000px; }
  .b-caseblock.b-caseblock-action.b-caseblock-action-control {
    padding-bottom: 0; }
    .b-caseblock.b-caseblock-action.b-caseblock-action-control .b-caseblock__screen_flex .b-caseblock__screen__image img {
      margin: 0 auto;
      max-width: 998px; }

.b-case-team {
  padding: 50px 0; }
  .b-case-team .b-case-team__one {
    padding: 0; }
  .b-case-team .b-case-team__one + .b-case-team__one {
    margin-top: 50px; }

.b-case-team--vedomosti {
  padding: 50px 0;
  background-color: #fff6f1; }
  .b-case-team--vedomosti .b-case-team__one {
    padding-bottom: 0; }

.scroll-to-top {
  position: fixed;
  right: 80px;
  bottom: 80px;
  width: 64px;
  height: 64px;
  z-index: 10;
  border-radius: 32px;
  background: #fff;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  border: 1px solid #e9e9e9;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  line-height: 78px;
  text-align: center;
  transition-duration: .275s; }
  .scroll-to-top:before {
    content: url("../img/base/top-arrow.svg"); }
  .scroll-to-top:hover, .scroll-to-top:active {
    background: #f9f9f9;
    border: 1px solid #e9e9e9;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(10px); }
  .scroll-to-top:active {
    transform: translateY(10px) scale(0.96); }
  .scroll-to-top--visible {
    animation: appearing cubic-bezier(0, 0, 0.2, 1) 0.275s normal forwards;
    pointer-events: auto; }
  .scroll-to-top--hide {
    animation: hiding cubic-bezier(0.4, 0, 1, 1) 0.225s normal forwards; }

@keyframes appearing {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes hiding {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@media screen and (max-width: 700px) {
  .scroll-to-top {
    right: 40px;
    bottom: 40px;
    width: 58.1818181818px;
    height: 58.1818181818px;
    line-height: 74px; } }

@media screen and (max-width: 480px) {
  .scroll-to-top {
    right: 20px;
    bottom: 20px; } }
