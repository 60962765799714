/*CSS Reset and PreStyles*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption, header, nav, section, aside, article, time, footer {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
}

html, body {
    width: 100%;
    height: 100%;
}

form, header, nav, section, aside, article, time, footer {
    display: block;
}