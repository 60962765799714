.b-page.b-platform-page {

  .b-platform-head {
    position: relative;
    min-height: 300px;
    padding-top: 84px;
    background: no-repeat 50% 50%;

    .title {
      font-size: 36pt;
      font-weight: 400;
      line-height: 1.2em;
    }

    .description {
      font-size: 24pt;
      font-weight: 100;
      margin-top: 2em;
      line-height: 1.2em;
    }
  }

  .b-platform-block, .b-platform-block * {
    box-sizing: border-box;
  }

  .b-platform-block {
    position: relative;
    font-size: 12pt;
    line-height: 1.5em;
    background: no-repeat 50% 50%;
    background-size: 100% auto;

    a, .link {
      text-decoration: underline;
    }

    p {
      text-indent: 0;
      margin: 0;
    }

    ol {
      margin: 0;
      list-style: decimal inside;
      text-indent: 0.5em;
    }

    ul {
      margin: 0;
      list-style: disc outside;
      padding-left: 2em;
    }

    ol, ul {
      li {
        margin: 0;
        &:before {
          content: none;
        }
      }
    }

    p+p, ul+p, ol+p {
      margin-top: 0.5em;
    }

    .title {
      font-family: $fontFamilyDaxLinePro;
      font-size: 26pt;
      line-height: 1em;
      font-weight: 100;
    }

    .sub-title {
      font-family: $fontFamilyDefault;
      font-size: 14pt;
      line-height: 1.2em;
      font-weight: 600;
    }

    h3 {
      font-family: $fontFamilyDefault;
      font-size: 14pt;
      font-weight: 600;
    }

    .block-row {
      text-align: left;
      margin-left: -1em;
      margin-right: -1em;

      .block-col {
        float: left;
        width: 100%;
        padding: 0 1em;

        &.block-col-6 {
          width: 50%;
        }
      }

      .block-row__end {
        clear: both;
      }
    }

    .illustration {
      background: no-repeat 50% 50%;
      background-size: cover;
    }
  }

  @include media-screen-1200 {

    .b-head__logo {
      width: 101px;
      margin: auto;
      position: absolute;
      right: 0;
      left: 0;
    }

  }

  @include media-screen-700 {

    .l-wrap {
      min-width: 450px;
      padding-left: 30px;
      padding-right: 30px;
    }

    .b-head {
      .b-head__logo {
        width: 160px;
        opacity: 1 !important;

        img {
          display: none !important;
        }

        img.d-n {
          display: block !important;
          width: 160px;
          height: auto;
        }
      }
    }

    .b-platform-head {

      .title {
        font-size: 32pt;
        line-height: 1.4em;
      }

      .description {
        font-size: 20pt;
        line-height: 1.5em;
      }

    }

    .b-platform-block {
      background-size: auto;

      .title {
        line-height: 1.2em;
      }

      .sub-title {

      }
    }

  }

  @include media-screen-550 {

    .l-wrap {
      min-width: 280px;
      padding-left: 15px;
      padding-right: 15px;
    }

  }

}


@import "android";