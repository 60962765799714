$side: 64px;
$appearing-animation: appearing  cubic-bezier(0.0, 0.0, 0.2, 1) .275s normal forwards;
$hiding-animation: hiding cubic-bezier(0.4, 0.0, 1, 1) .225s normal forwards;
$ligt-grey: #e9e9e9;
$margin: 80px;

.scroll-to-top {
    position: fixed;
    right: $margin;
    bottom: $margin;
    width: $side;
    height: $side;
    z-index: 10;
    border-radius: $side / 2;
	background: #fff;
	opacity: 0;
    cursor: pointer;
	pointer-events: none;
	border: 1px solid $ligt-grey;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08);
	line-height: 78px;
	text-align: center;

	transition-duration: .275s;

	&:before {
		content: url("../img/base/top-arrow.svg");
	}

	&:hover, &:active {
		background: #f9f9f9;
		border: 1px solid $ligt-grey;
		box-shadow: 0 1px 6px 0 rgba(0,0,0,0.08);
		transform: translateY(10px);
	}

	&:active {
		transform: translateY(10px) scale(.96);
	}

    &--visible {
		animation: $appearing-animation;
        pointer-events: auto;
	}
	
	&--hide {
		animation: $hiding-animation;
	}
}

@keyframes appearing {
	from {opacity: 0;}
	to { opacity: 1;}
}

@keyframes hiding {
	from {opacity: 1;}
	to { opacity: 0;}
}

@media screen and (max-width: 700px) {

	.scroll-to-top {
		right: 40px;
		bottom: 40px;
		width: $side / 1.1;
		height: $side / 1.1;
		line-height: $side + 10px;
	}
}

@media screen and (max-width: 480px) {
	.scroll-to-top {
		right: 20px;
		bottom: 20px;
	}
}
