$buttonFontFamily: (DaxLinePro, sans-serif);

$buttonFontColorDefault: #9b9b9b;
$buttonBackgroundDefault: #1f1f1f;
$buttonBorderColorDefault: #979797;

$buttonFontColorPrimary: #141414;
$buttonBackgroundPrimary: #00ccf1;

.button {
  font-family: $buttonFontFamily;
  font-size: $formInputFontSize;
  font-weight: 400;
  border: 2px solid $buttonBorderColorDefault;
  background: $buttonBackgroundDefault;
  color: $buttonFontColorDefault;
  line-height: 1.2em;
  height: $formInputHeight;
  margin: 0;
  padding: 0 1em;
  outline: none;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    background: none;
    color: $buttonFontColorDefault;
  }

  &:disabled {
    border: 2px solid darken($buttonBorderColorDefault, 40%);
    background: $buttonBackgroundDefault;
    color: darken($buttonFontColorDefault, 40%);
  }

  &.button--primary {
    border-color: #fff;
    background: #0032E9;
    color: #fff;

    &:hover {
      background: none;
      color: #fff;
    }
  }
}