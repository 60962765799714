a img {
  border: none;
}

.br {
  font-size: 0;
  height: 0;
  clear: both;
  overflow: hidden;
}

ul, ol {
  display: block;
  margin: 0 0 11px 0;
  padding: 0;
  list-style: none;
}

ul li {
  margin: 0 0 5px 24px;
  padding: 0;
  position: relative;
}

ul li:before {
  display: block;
  content: "\2014";
  float: left;
  margin-left: -24px;
}

ol {
  counter-reset: item;
}

ol li {
  margin: 0 0 5px 20px;
  padding: 0;
}

ol li:before {
  display: block;
  float: left;
  margin-left: -20px;
  counter-increment: item;
  content: counters(item, ".") ".";
}

ul.unstyled li {
  margin: 0;
  padding: 0;
  background: none;
  list-style: none;
}

table {
  margin: 30px 0;
  border: none;
  border-top: 2px solid #cfcfcf;
  border-collapse: collapse;
}

th {
  padding: 13px 20px;
  border-bottom: 1px solid #cfcfcf;
  color: #999999;
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  text-align: left;
}

td {
  padding: 13px 20px;
  border-bottom: 1px solid #cfcfcf;
  font-size: 14px;
  line-height: 18px;
}

th.ta-r, td.ta-r {
  text-align: right;
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

.d-n {
  display: none;
}

.d-b {
  display: block;
}

.fl-l {
  float: left;
}

.fl-r {
  float: right !important;
}

.ov-h {
  overflow: hidden;
}

.fw-b {
  font-weight: bold;
}

.ta-c {
  text-align: center;
}

.tt-u {
  text-transform: uppercase;
}

.whs-nw {
  white-space: nowrap;
}

.animate, a, .link {
  -moz-transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, -moz-opacity 300ms linear, -moz-box-shadow 300ms linear, -moz-transform 300ms linear;
  -webkit-transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, -webkit-opacity 300ms linear, -webkit-box-shadow 300ms linear, -webkit-transform 300ms linear;
  -o-transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, -o-opacity 300ms linear, -o-box-shadow 300ms linear, -o-transform 300ms linear;
  transition: border-color 300ms linear, background-color 300ms linear, color 300ms linear, opacity 300ms linear, box-shadow 300ms linear, transform 300ms linear;
}

.svg-animate {
  -moz-transition: fill 300ms linear, stroke 300ms linear;
  -webkit-transition: fill 300ms linear, stroke 300ms linear;
  -o-transition: fill 300ms linear, stroke 300ms linear;
  transition: fill 300ms linear, stroke 300ms linear;
}

.b-image {
  display: block;
  margin: 0;
  padding: 0;
}

.m-0 {
  margin: 0;
}

.m-a {
  margin: auto;
}

.pos-r {
  position: relative;
}

/* blocks */

.b-image {
  display: block;
  margin: 0;
  padding: 0;
  border: 0 solid #cccccc;
  border-bottom-width: 1px;
}

a:hover .b-image.tohover, .hover .b-image.tohover {
  border-color: #ae1d00;
}

/* colors */

.red {
  color: #ae1d00 !important;
}

/* other */

sup {
  line-height: 1.5em;
  vertical-align: top;
}