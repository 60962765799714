.b-page.b-platform-page.b-platform-android {
  overflow-x: hidden;

  .b-platform-head {
    display: table;
    width: 100%;
    height: 440px;
    background-image: url("/img/platforms/android/bg_android_1920.png");
    color: white;

    .l-wrap {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }

  .b-platform-block {

    .l-wrap {
      max-width: 960px;
    }

    &.block-advantages {
      padding: 3em 0 5em;

      .l-wrap {
        text-align: center;

        .title {
          margin-right: 445px;
        }

        .sub-title {
          margin-right: 445px;
          margin-top: 2em;
        }

        .block-row {
          margin: 0;

          .description {
            margin-right: 380px;
            padding-right: 2em;
          }

          .illustration {
            display: table;
            margin-top: -5em;
            float: right;
            width: 380px;

            .image {
              display: table-cell;
              text-align: left;
              vertical-align: middle;

              img {
                width: 380px;
              }
            }
          }

          &+.block-row {
            margin-top: 0.5em;
          }
        }
      }
    }

    &.block-services {
      padding: 5em 0 5em;
      background-image: url('../img/platforms/android/bg_2_1920.png');
      color: white;

      .block-row {
        margin: 0;

        .illustration {
          float: left;
          margin-top: -2em;
          width: 400px;

          img {
            width: 400px;
          }
        }

        .sub-title {
          margin-bottom: 1em;
          margin-left: 400px;
          padding-left: 50px;
          font-size: 18pt;
          font-weight: 400;
        }

        .description {
          margin-left: 400px;
          padding-left: 50px;

          li+li {
            margin-top: 0.8em;
          }
        }
      }
    }

    &.block-experts {
      padding: 5em 0 5em;

      .block-row {
        margin: 0;

        $illustrationWidth: 460px;

        .illustration {
          float: right;
          width: $illustrationWidth;
          margin-top: -4em;

          img {
            width: $illustrationWidth;
          }
        }

        .sub-title {
          margin-bottom: 1em;
          margin-right: $illustrationWidth;
          padding-right: 50px;
          font-size: 18pt;
          font-weight: 400;
        }

        .description {
          margin-right: $illustrationWidth;
          padding-right: 50px;

          ul+p {
            margin-top: 1.5em;
          }
        }
      }
    }

    &.block-team {
      padding: 5em 0 5em;
      background-color: #d5efff;

      .block-row {
        &.block-row-head {
          margin: 0;
        }

        .sub-title {
          font-size: 18pt;
          font-weight: 400;
          margin-top: 0.5em;
        }

        h3 {
          margin-bottom: 1em;
        }

        .illustration {
          width: 100%;
          text-align: center;
          margin-bottom: 2em;

          img {
            width: 390px;
          }
        }

        .description {
          max-width: 550px;

          li+li {
            margin-top: 1em;
          }
        }

        &+.block-row {
          margin-top: 3em;
        }
      }
    }

    &.block-process {
      padding: 3em 0 5em;

      .block-row {
        margin: 0;

        $illustrationWidth: 420px;

        .illustration {
          float: right;
          margin-top: -3em;
          width: $illustrationWidth;

          img {
            width: $illustrationWidth;
          }
        }

        .description {
          margin-right: $illustrationWidth;
          padding-right: 2em;
        }

        &+.block-row {
          margin-top: 1em;
        }
      }
    }

    &.block-price {
      padding: 3em 0 5em;
      background-image: url('../img/platforms/android/bg_3_1920.png');
      color: white;

      .block-row {
        margin: 0;

        .illustration {
          float: left;
          margin-top: -3em;
          width: 550px;
          text-align: center;

          .price-slider-holder {
            $priceCardWidth: 240px;

            display: none;
            padding: 0;
            margin: 0 auto;
            height: 350px;
            width: 450px;

            > li {
              height: 320px;
              width: $priceCardWidth;

              box-shadow: 0 0 10px #777777;
              cursor: pointer;
              overflow: hidden;
              background: white;
              opacity: 1!important;
            }

            .price-card {
              text-align: left;
              color: #262626;

              .price-card-content {
                padding: 2em 1em 0;

                h1 {
                  color: #262626;
                  font-size: 150%;
                  font-family: $fontFamilyDefault;
                  line-height: 80%;
                  padding: 0 0.5em;
                }

                .price-card-description {
                  font-size: 80%;
                  margin-top: 1em;
                  line-height: 1.3em;
                  color: #4a4a4a;

                  ul {
                    list-style: none inside;
                    margin: 0;
                    padding: 0;

                    > li {
                      margin: 0;
                      line-height: 1.3em;

                      &:before {
                        content: "-";
                        margin-left: 0;
                        margin-right: 0.5em;
                      }
                    }
                  }
                }
              }

              .price-card-footer {
                position: absolute;
                display: table;
                left: 0;
                right: 0;
                width: 100%;
                bottom: 0;
                height: 20%;
                font-size: 160%;
                line-height: 1.5em;

                .content {
                  display: table-cell;
                  padding: 0 0.5em;
                  text-align: center;
                  vertical-align: middle;
                }
              }

              &.price-card--business {

                .price-card-footer {
                  background-color: #00ccf1;
                  color: #262626;
                }

              }

              &.price-card--all {

                .price-card-description {
                  padding: 0 1em;
                }

                .price-card-footer {
                  background-color: #d5d5ff;
                  color: rgba(50, 51, 50, 0.8);
                }

              }

              &.price-card--startup {

                .price-card-footer {
                  background-color: #d5d5ff;
                  color: rgba(50, 51, 50, 0.8);
                }

              }
            }
          }
        }

        .sub-title {
          margin-bottom: 1em;
          margin-left: 550px;
          padding-left: 50px;
          font-size: 18pt;
          font-weight: 400;
        }

        .description {
          max-width: 550px;
          margin-left: 550px;
          padding-left: 50px;

          li+li {
            margin-top: 0.8em;
          }
        }
      }
    }

    &.block-technologies {
      padding: 3em 0 5em;

      .block-row {
        margin: 0;

        .illustration {
          float: right;
          margin-top: -3em;
          width: 230px;

          img {
            width: 230px;
          }
        }

        .description {
          margin-right: 230px;
          padding-right: 2em;

          li {
            margin-top: 0.5em;
          }
        }

        &+.block-row {
          margin-top: 1em;
        }
      }
    }

  }

  @include media-screen-1200 {

    .b-platform-head {
      height: 400px;

      .l-wrap {
        min-width: 800px;

        .title {
          font-size: 32pt;
          line-height: 1.2em;
        }

        .description {
          font-size: 20pt;
          margin-top: 1.5em;
          line-height: 1.2em;
        }
      }
    }

    .b-platform-block {

      &.block-services {

        $illustrationWidth: 250px;

        .block-row {

          .illustration {
            width: $illustrationWidth;
            img {
              width: $illustrationWidth;
            }
          }

          .sub-title {
            margin-left: $illustrationWidth;
          }

          .description {
            margin-left: $illustrationWidth;
          }
        }
      }

      &.block-experts {

        .block-row {

          $illustrationWidth: 350px;

          .illustration {
            width: $illustrationWidth;

            img {
              width: $illustrationWidth;
            }
          }

          .sub-title {
            margin-right: $illustrationWidth;
          }

          .description {
            margin-right: $illustrationWidth;
          }
        }
      }

      &.block-process {

        .block-row {

          $illustrationWidth: 300px;

          .illustration {
            width: $illustrationWidth;

            img {
              width: $illustrationWidth;
            }
          }

          .description {
            margin-right: $illustrationWidth;
          }
        }
      }

      &.block-price {

        .block-row {

          .illustration {
            width: 400px;

            .price-slider-holder {
              height: 350px;
              width: 300px;
            }
          }

          .sub-title {
            margin-left: 400px;
          }

          .description {
            margin-left: 400px;
          }
        }
      }

    }

  }

  @include media-screen-990 {

    .b-platform-head {
      height: 400px;

      .l-wrap {
        min-width: 600px;

        .title {
          font-size: 32pt;
          line-height: 1.2em;
        }

        .description {
          font-size: 20pt;
          margin-top: 1.5em;
          line-height: 1.2em;
        }
      }
    }

    .b-platform-block {

      &.block-advantages {
        padding-bottom: 3em;

        .l-wrap {

          .title {
            text-align: center;
            margin-right: 0;
          }

          .sub-title {
            margin-right: 0;
            margin-top: 1em;
          }

          .block-row {

            .description {
              margin-right: 0;
              padding-right: 0;
              margin-top: 1em;
            }

            .illustration {
              margin-left: auto;
              margin-right: auto;
              margin-top: 0;
              float: none;
              width: 300px;

              .image {
                text-align: center;
                vertical-align: middle;

                img {
                  width: 300px;
                }
              }
            }

            &+.block-row {
              margin-top: 1.5em;
            }
          }
        }
      }

      &.block-services {
        padding-top: 3em;
        padding-bottom: 3em;
        background-image: url('../img/platforms/android/bg_2_800.png');

        .block-row {
          margin: 0;

          .sub-title {
            text-align: center;
            margin-bottom: 2em;
            margin-left: 0;
            padding-left: 0;
          }

          .description {
            margin-top: 1em;
            margin-left: 0;
            padding-left: 0;
          }

          .illustration {
            margin-left: auto;
            margin-right: auto;
            float: none;
            margin-top: 0;
            width: 250px;

            img {
              width: 250px;
            }
          }
        }
      }

      &.block-experts {
        padding: 3em 0;

        .block-row {

          $illustrationWidth: 300px;

          .illustration {
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;
            float: none;
            width: $illustrationWidth;

            img {
              width: $illustrationWidth;
            }
          }

          .sub-title {
            text-align: center;
            margin-bottom: 1em;
            margin-right: 0;
            padding-right: 0;
          }

          .description {
            margin-top: 1em;
            margin-right: 0;
            padding-right: 0;
          }
        }
      }

      &.block-team {
        padding: 3em 0;

        .l-wrap {
          .block-row {

            .title {
              text-align: center;
            }

            .sub-title {
              margin-top: 1em;
            }

            .block-col.block-col-6 {
              float: none;
              width: 100%;
            }

            .block-col + .block-col-6 {
              margin-top: 2em;
            }

            .description {
              max-width: none;


              li+li {
                margin-top: 0;
              }
            }
          }
        }
      }

      &.block-process {
        padding-bottom: 3em;

        .block-row {
          margin: 0;

          $illustrationWidth: 350px;

          .title {
            text-align: center;
          }

          .illustration {
            margin-left: auto;
            margin-right: auto;
            float: none;
            margin-top: 0;
            width: $illustrationWidth;

            img {
              width: $illustrationWidth;
            }
          }

          .description {
            margin-top: 2em;
            margin-right: 0;
            padding-right: 0;
          }

          &+.block-row {
            margin-top: 2em;
          }
        }
      }

      &.block-price {
        padding: 3em 0;
        background-image: url('../img/platforms/android/bg_3_800.png');
        color: white;

        .block-row {
          margin: 0;

          .illustration {
            margin-right: auto;
            margin-left: auto;
            float: none;
            margin-top: 0;
            width: 550px;

            .price-slider-holder {
              padding: 0;
              margin: 0 auto;
              height: 300px;
              width: 450px;

              > li {
                height: 320px;
                width: 240px;
              }
            }
          }

          .sub-title {
            text-align: center;
            margin-left: 0;
            padding-left: 0;
            margin-bottom: 1.5em;
          }

          .description {
            margin-top: 2em;
            margin-left: 0;
            padding-left: 0;
          }
        }
      }

      &.block-technologies {
        padding-bottom: 3em;

        .block-row {
          margin: 0;

          .title {
            text-align: center;
          }

          .illustration {
            margin-right: auto;
            margin-left: auto;
            float: none;
            margin-top: 0;
            width: 230px;

            img {
              width: 230px;
            }
          }

          .description {
            margin-top: 2em;
            margin-right: 0;
            padding-right: 0;

            li {
              margin-top: 0;
            }
          }

          &+.block-row {
            margin-top: 2em;
          }
        }
      }

    }

  }

  @include media-screen-700 {

    .b-platform-head {
      height: 600px;
      background-size: 100% auto;
      background-image: url('/img/platforms/android/bg_android_800.png');

      .l-wrap {
        min-width: 450px;

        .title {
          font-size: 32pt;
        }

        .description {
          font-size: 20pt;
          margin-top: 1.5em;
        }
      }
    }

  }

  @include media-screen-550 {

    .b-platform-head {
      height: 360px;

      .l-wrap {
        min-width: 280px;

        .title {
          font-size: 28pt;
          font-weight: 400;
        }

        .description {
          font-size: 16pt;
          line-height: 1.5em;
          margin-top: 1.5em;
        }
      }
    }

    .b-platform-block {

      &.block-advantages {

        .l-wrap {

          .block-row {

            .illustration {
              width: 280px;

              .image {
                img {
                  width: 280px;
                }
              }
            }

            .description {
              margin-top: 2.5em;
            }

            &+.block-row {
              margin-top: 2.5em;
            }
          }
        }
      }

      &.block-services {

        .block-row {

          .description {
            margin-top: 2em;

            li+li {
              margin-top: 0.5em;
            }
          }
        }
      }

      &.block-team {

        .l-wrap {
          .block-row {

            .illustration {

              img {
                width: 280px;
              }
            }
          }
        }
      }

      &.block-process {

        .block-row {
          $illustrationWidth: 280px;

          .illustration {
            width: $illustrationWidth;

            img {
              width: $illustrationWidth;
            }
          }
        }
      }

      &.block-price {

        .block-row {

          .illustration {
            width: 290px;

            .price-slider-holder {
              padding: 0;
              margin: 0 auto;
              height: 300px;
              width: 290px;

              > li {
                height: 280px;
                width: 230px;
              }
            }
          }

          .description {
            margin-left: 0;
            padding-left: 0;
          }
        }
      }

    }

  }

}