$color_case_vedomosti_block_bright: #fff6f1;
$color_case_vedomosti_block_dark: #332f2e;
$color_case_vedomosti_text_bright: #fff6f1; // Светлый текст на тёмном фоне
$color_case_vedomosti_text_dark: #857871; // Тёмный текст на тёмном фоне

@mixin sliderGradient($leftColorHex, $leftColorAlpha, $rightColorHex, $rightColorAlpha) {
  $leftColor: rgba($leftColorHex, $leftColorAlpha);
  $ieLeftColor: ie_hex_str($leftColor);
  $rightColor: rgba($rightColorHex, $rightColorAlpha);
  $ieRightColor: ie_hex_str($rightColor);
  background: -moz-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, $leftColor), color-stop(100%, rgba(255, 255, 255, 0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* IE10+ */
  background: linear-gradient(to right, $leftColor 0%, $rightColor 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1); /* IE6-9 */
}

.b-casehead-vedomosti {
  height: 1100px;
  position: relative;
  background: url('../img/projects/vedomosti/cover.png') 100% 84px;
  color: $color_case_vedomosti_block_dark;

  .l-wrap {
    height: 100%;
    margin: auto;

    .b-casehead__logo {
      position: absolute;
      top: -300px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 600px;
      height: 112px;

      img {
        width: 600px;
      }
    }

    .b-casehead__descr {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 1000px;
      height: 150px;
      margin: auto;
      text-align: center;
      color: white;
    }

    .b-casehead__btns {
      position: absolute;
      bottom: 80px;
      left: 0;
      right: 0;
      height: 56px;
      margin: auto;
      text-align: center;

      a {
        margin: 0;
        padding: 0;
      }

      a+a {
        margin-left: 1em;
      }

      img {
        width: 194px;
        height: 56px;
      }
    }

  }

}

.b-caseblock.vedomosti {
  padding-top: 50px;

  &.b-caseblock--bright {
    background-color: $color_case_vedomosti_block_bright;
  }

  &.b-caseblock--dark {
    background-color: $color_case_vedomosti_block_dark;

    .b-caseblock__title {
      color: white;
    }

    .b-caseblock__subtitle {
      color: $color_case_vedomosti_text_dark;
    }
  }

  .b-caseblock__text {
    max-width: 1100px;
    margin-bottom: 1em;
  }

  .b-caseblock__title {
    max-width: 1100px;
  }

  .b-caseblock__subtitle {
    max-width: 1200px;
    margin-bottom: 2em;
  }

  &.b-caseblock-viewport {
    padding-bottom: 50px;

    .b-image-viewport {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 1000px;
      overflow: hidden;

      .b-caseblock-viewport-image {
        width: 2600px;
        height: 2220px;
        background-image: url('../img/projects/vedomosti/scheme.png');
        background-size: cover;
      }

    }
  }

  $formats-stage-item-margin: 100px;
  $formats-stage-image-width: 558px;
  $formats-stage-image-height: 498px;

  &.b-caseblock-vedomosti-formats {
    padding-bottom: 14em;

    .b-formats-stage {
      margin: 50px auto 0;
      background: none;
      width: 100%;
      height: $formats-stage-image-height;

      .b-caseblock-carousel__wrap {
        padding: 0;
        background: none;
        overflow: hidden;
        width: 100%;
        height: $formats-stage-image-height;

        .b-caseblock-carousel__prev, .b-caseblock-carousel__next {
          color: white;
        }

        &:before, &:after {
          display: block;
          content: "";
          width: 200px;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1;
        }

        &:before {
          left: 0;
          @include sliderGradient($color_case_vedomosti_block_dark, 1.0, $color_case_vedomosti_block_dark, 0.0);
        }

        &:after {
          right: 0;
          @include sliderGradient($color_case_vedomosti_block_dark, 0.0, $color_case_vedomosti_block_dark, 1.0);
        }
      }

      .b-caseblock-carousel__viewport {
        overflow: visible;
        width: $formats-stage-image-width + $formats-stage-item-margin;
        height: $formats-stage-image-height;
        margin: 0 auto;

        .b-caseblock-carousel_formats-item {
          height: $formats-stage-image-height;
          width: $formats-stage-image-width + $formats-stage-item-margin;
          cursor: pointer;
          text-align: center;

          .b-caseblock-carousel_formats-item__image {
            margin: 0 auto;
            width: $formats-stage-image-width;
            height: $formats-stage-image-height;
            background-position: 0 0;
            background-size: cover;

            img {
              width: $formats-stage-image-width;
              height: $formats-stage-image-height;
            }
          }
        }
      }
    }
  }

  $inline-navigation-image-width: 48px;
  $inline-navigation-image-height: 48px;

  $inline-navigation-item-width: 120px;
  $inline-navigation-item-height: 80px;

  .b-inlines-navigation {
    margin: 1em auto 1em;
    background: none;
    width: 1000px;
    height: 80px;

    .b-caseblock-carousel__wrap {
      padding: 0;
      background: none;
      width: 1000px;
    }

    .b-caseblock-carousel__viewport {
      overflow: hidden;
      width: $inline-navigation-item-width * 5;
      height: $inline-navigation-item-height;
      margin: 0 auto;
    }

    .b-caseblock-carousel_inline-item {
      height: $inline-navigation-item-height;
      width: $inline-navigation-item-width;
      text-align: center;

      .b-caseblock-carousel_inline-item__image {
        margin: 0 auto 0.5em;
        width: $inline-navigation-image-width;
        height: $inline-navigation-image-height;
        background-position: 0 0;
        background-size: cover;
      }

      .b-caseblock-carousel_inline-item__descr {
        color: $color_case_vedomosti_text_dark;
      }

      &.active {
        .b-caseblock-carousel_inline-item__image {
          background-position: -48px 0;
        }

        .b-caseblock-carousel_inline-item__descr {
          color: $color_case_vedomosti_text_bright;
        }
      }

      &.b-caseblock-carousel_inline-item--quote {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/quote.png');
        }
      }

      &.b-caseblock-carousel_inline-item--text {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/text.png');
        }
      }

      &.b-caseblock-carousel_inline-item--picture {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/picture.png');
        }
      }

      &.b-caseblock-carousel_inline-item--timeline {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/timeline.png');
        }
      }

      &.b-caseblock-carousel_inline-item--gallery {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/gallery.png');
        }
      }

      &.b-caseblock-carousel_inline-item--doc {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/doc.png');
        }
      }

      &.b-caseblock-carousel_inline-item--info {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/info.png');
        }
      }

      &.b-caseblock-carousel_inline-item--link {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/link.png');
        }
      }

      &.b-caseblock-carousel_inline-item--video {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/video.png');
        }
      }

      &.b-caseblock-carousel_inline-item--youtube {
        .b-caseblock-carousel_inline-item__image {
          background-image: url('../img/projects/vedomosti/carousel-inlines/youtube.png');
        }
      }
    }
  }

  $inline-stage-item-margin: 150px;
  $inline-stage-image-height: 651px;

  .b-inlines-stage {
    margin: 0 auto;
    background: none;
    width: 100%;
    height: $inline-stage-image-height;

    .b-caseblock-carousel__wrap {
      position: relative;
      padding: 0;
      background: none;
      overflow: hidden;
      width: 100%;
      height: $inline-stage-image-height;

      &:before, &:after {
        display: block;
        content: "";
        width: 200px;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
      }

      &:before {
        left: 0;
        @include sliderGradient($color_case_vedomosti_block_dark, 1.0, $color_case_vedomosti_block_dark, 0.0);
      }

      &:after {
        right: 0;
        @include sliderGradient($color_case_vedomosti_block_dark, 0.0, $color_case_vedomosti_block_dark, 1.0);
      }

      .b-caseblock-carousel__prev, .b-caseblock-carousel__next {
        color: white;
      }
    }

    .b-caseblock-carousel__viewport {
      overflow: visible;
      height: $inline-stage-image-height;
      margin: 0 auto;

      .b-caseblock-carousel_inline-item {
        height: $inline-stage-image-height;
        cursor: pointer;
        text-align: center;

        .b-caseblock-carousel_inline-item__image {
          margin: 0 auto;
          height: $inline-stage-image-height;
          background-position: 0 0;
          background-size: cover;
        }
      }

      &.b-inlines--ios {
        $inline-stage-image-width: 959px;
        width: $inline-stage-image-width + $inline-stage-item-margin;

        .b-caseblock-carousel_inline-item {
          width: $inline-stage-image-width + $inline-stage-item-margin;

          .b-caseblock-carousel_inline-item__image {
            width: $inline-stage-image-width;
          }
        }

        .b-caseblock-carousel_inline-item {

          &.b-caseblock-carousel_inline-item--quote {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_quote.png');
            }
          }

          &.b-caseblock-carousel_inline-item--text {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_text.png');
            }
          }

          &.b-caseblock-carousel_inline-item--picture {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_img.png');
            }
          }

          &.b-caseblock-carousel_inline-item--timeline {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_chrono.png');
            }
          }

          &.b-caseblock-carousel_inline-item--gallery {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_gallery.png');
            }
          }

          &.b-caseblock-carousel_inline-item--doc {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_doc.png');
            }
          }

          &.b-caseblock-carousel_inline-item--info {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_info.png');
            }
          }

          &.b-caseblock-carousel_inline-item--link {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_link1.png');
            }
          }

          &.b-caseblock-carousel_inline-item--video {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_video.png');
            }
          }

          &.b-caseblock-carousel_inline-item--youtube {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen2_youtube.png');
            }
          }

        }
      }

      &.b-inlines--android {
        $inline-stage-image-width: 989px;
        width: $inline-stage-image-width + $inline-stage-item-margin;

        .b-caseblock-carousel_inline-item {
          width: $inline-stage-image-width + $inline-stage-item-margin;

          .b-caseblock-carousel_inline-item__image {
            width: $inline-stage-image-width;
          }
        }

        .b-caseblock-carousel_inline-item {

          &.b-caseblock-carousel_inline-item--quote {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_quote.png');
            }
          }

          &.b-caseblock-carousel_inline-item--text {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_text.png');
            }
          }

          &.b-caseblock-carousel_inline-item--picture {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_img.png');
            }
          }

          &.b-caseblock-carousel_inline-item--timeline {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_chrono.png');
            }
          }

          &.b-caseblock-carousel_inline-item--gallery {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_gallery.png');
            }
          }

          &.b-caseblock-carousel_inline-item--doc {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_doc.png');
            }
          }

          &.b-caseblock-carousel_inline-item--info {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_info.png');
            }
          }

          &.b-caseblock-carousel_inline-item--link {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_link1.png');
            }
          }

          &.b-caseblock-carousel_inline-item--video {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_video.png');
            }
          }

          &.b-caseblock-carousel_inline-item--youtube {
            .b-caseblock-carousel_inline-item__image {
              background-image: url('../img/projects/vedomosti/carousel-inlines/screen1_youtube.png');
            }
          }

        }
      }
    }
  }

  .b-caseblock-selector {
    font-size: 16pt;
    padding: 3em 0;

    .button {
      border: 2px solid $color_case_vedomosti_text_dark;
      border-radius: 4px;
      background-color: $color_case_vedomosti_block_dark;
      color: $color_case_vedomosti_text_dark;
      padding: 0.4em 1em;
      width: 160px;
      font-size: 16pt;
      cursor: pointer;

      &.button--selected {
        background-color: $color_case_vedomosti_text_dark;
        color: $color_case_vedomosti_text_bright;
      }

      &.button--first {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.button--last {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

  }

  &.b-caseblock-vedomosti-design {
    .b-caseblock__screen_flex img {
      max-width: 1141px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.b-caseblock-vedomosti-newspaper {
    padding-bottom: 2em;

    .b-caseblock__screen_flex img {
      max-width: 1202px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.b-caseblock-vedomosti-hypercomments {
    .b-caseblock__screen_flex img {
      max-width: 1142px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.b-caseblock-vedomosti-creatives {
    .b-caseblock__screen_flex img {
      max-width: 1125px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.b-caseblock-vedomosti-sitemobile {
    padding-bottom: 50px;

    .b-caseblock__screen_flex img {
      max-width: 1112px;
      margin-left: auto;
      margin-right: auto;
    }
  }

}

.b-case-team {
  padding: 50px 0;

  .b-case-team__one {
    padding: 0;
  }

  .b-case-team__one+.b-case-team__one {
    margin-top: 50px;
  }
}

.b-case-team--vedomosti {
  padding: 50px 0;
  background-color: $color_case_vedomosti_block_bright;

  .b-case-team__one {
    padding-bottom: 0;
  }
}