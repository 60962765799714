.b-project {

  .b-footer {

    .b-project-nav {
      max-width: 570px;
      margin: 2.5em auto;
      font-size: 14pt;
      line-height: 1.5em;

      .b-project-nav__next, .b-project-nav__prev {
        color: white;

        &:hover {
          color: #666666;
        }
      }

      .b-project-nav__prev {
        float: left;
        padding-left: 25px;
        background: url("../img/base/arrowleft.png") no-repeat 0 50%; }

      .b-project-nav__next {
        float: right;
        padding-right: 25px;
        background: url("../img/base/arrowright.png") no-repeat 100% 50%; }
    }

  }

}

@import "./projects/vedomosti";
@import "./projects/action";
@import "scroll-to-top";