.b-footer, .b-footer * {
  box-sizing: border-box;
}

.b-footer {
  .b-popup, .b-popup * {
    box-sizing: content-box;
  }
}

.b-footer {
  background: #000 ;
  color: #ffffff;
  padding: 2em 0;
  position: relative;

  .b-footer-title {
    font-size: 26pt;
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 1em;
  }

  .b-footer-button {
    a {
      display: block;
      width: 196px;
      margin: auto;
      padding: 10px;
      border: 2px solid #ffffff;
      background-color: #0032E9;
      color: #fff;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      font-family: "DaxLinePro", sans-serif;

      &:hover {
        color: #ffffff;
        background: none;
      }
    }
  }

  .b-footer-addresses {
    text-align: center;
    width: 700px;
    margin: 0 auto;
    color: #888888;

    .b-footer-addresses__item {
      display: inline-block;
      min-width: 200px;
      max-width: 350px;
      padding: 0 1em;
    }
  }

  .b-footer-year {
    color: #888888;
    margin-top: 1em;
    text-align: center;
  }
}

@include media-screen-700 {

  .b-footer {

    .b-footer-addresses {
      margin: 2em auto;
      width: auto;
      min-width: 280px;

      .b-footer-addresses__item + .b-footer-addresses__item {
        margin-top: 1em;
        padding: 0;
      }
    }
  }

}