@mixin prop-transition($property, $duration, $easing) {
  -moz-transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -o-transition: $property $duration $easing;
  transition: $property $duration $easing;
}

@mixin opacity-transition($duration, $easing) {
  -moz-transition: -moz-opacity $duration $easing;
  -webkit-transition: -webkit-opacity $duration $easing;
  -o-transition: -o-opacity $duration $easing;
  transition: opacity $duration $easing;
}

@mixin prop-opacity($value) {
  -moz-opacity: $value;
  -webkit-opacity: $value;
  -o-opacity: $value;
  opacity: $value;
}

* {
  -webkit-text-size-adjust: none; /*фиксим баг в IOS cо шкалированием в landscape*/
  outline: none; /*убираем рамку вокруг ссылок и кнопок*/
  -webkit-touch-callout: none; /*если надо, то запрещаем выделение текста*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /*убираем фон подсветки ссылок и кнопок*/
  -webkit-appearance: none; /*убираем iOS стили форм*/
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-regular-wf.eot");
  src: url("../css/daxlinepro-regular-wf.eot?#iefix") format("embedded-opentype"),
  url("../css/daxlinepro-regular-wf.woff") format("woff"),
  url("../css/daxlinepro-regular-wf.ttf") format("truetype"),
  url("../css/daxlinepro-regular-wf.svg#daxlinepro-regularregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-light-wf.eot");
  src: url("../css/daxlinepro-light-wf.eot?#iefix") format("embedded-opentype"),
  url("../css/daxlinepro-light-wf.woff") format("woff"),
  url("../css/daxlinepro-light-wf.ttf") format("truetype"),
  url("../css/daxlinepro-light-wf.svg#daxlineprolight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-medium-wf.eot");
  src: url("../css/daxlinepro-medium-wf.eot?#iefix") format("embedded-opentype"),
  url("../css/daxlinepro-medium-wf.woff") format("woff"),
  url("../css/daxlinepro-medium-wf.ttf") format("truetype"),
  url("../css/daxlinepro-medium-wf.svg#daxlinepromedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DaxLinePro";
  src: url("../css/daxlinepro-thin-wf.eot");
  src: url("../css/daxlinepro-thin-wf.eot?#iefix") format("embedded-opentype"),
  url("../css/daxlinepro-thin-wf.woff") format("woff"),
  url("../css/daxlinepro-thin-wf.ttf") format("truetype"),
  url("../css/daxlinepro-thin-wf.svg#daxlineprothin") format("svg");
  font-weight: 200;
  font-style: normal;
}

$fontFamilyDefault: ("Open Sans", Arial, Tahoma, sans-serif);
$fontFamilyDaxLinePro: (DaxLinePro, sans-serif);

$linkColor: #3262C4;

html, body {
  min-width: 320px;
  min-height: 100%;
}

body {
  // display: none;
  background: #ffffff;
  color: #303030;
  font-size: 16px;
  line-height: 24px;
  font-family: $fontFamilyDefault;
}

a, .link {
  color: #3262C4;
  text-decoration: none;
  cursor: pointer;
}

a:hover, .link:hover {
  color: #303030;
  text-decoration: none;
}

.dotted:hover {
  text-decoration: none;
}

p {
  margin: 0 0 10px 0;
  padding: 0;
}

.b-page {
  min-height: 100%;
  position: relative;
}

.l-wrap {
  max-width: 1400px;
  margin: auto;
}

.l-menu__wrap {
  /* max-width: 1530px; */
  width: 100%;
  /* min-width: 1200px; */
  padding: 0 25px;
  margin-right: 30px;
  position: relative;
}


.b-head {
  padding: 29px 0;
  border-bottom: 1px solid transparent;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 400;
  @include prop-transition(height, 300ms, linear);
}

.b-head__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  @include prop-opacity(0);
  //@include opacity-transition(300ms, linear);
}

.b-head .l-wrap {
  padding: 0 25px;
  position: relative;
  width: auto;
  max-width: none;
}

.b-head a {
  color: #ffffff;
}

.b-head a:hover {
  color: #f0f0f0;
}

.b-head__lang {
  float: right;
  width: 88px;
  margin: -5px 0 0 35px;
}

.b-head__lang a, .b-head__lang span {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border: 1px solid #ffffff;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
}

.b-head__lang a:hover, .b-head__lang .active {
  color: #083088;
  background: #ffffff;
}

.b-head__logo {
  display: none;
  width: 101px;
  margin-top: -29px;
  margin-left: -25px;
  position: absolute;
}

.inner .b-head__logo {
  display: block;
}

.b-head__menutrigger {
  display: none;
  width: 46px;
  height: 43px;
  position: absolute;
  top: -7px;
  cursor: pointer;
  @include prop-transition(background, 300ms, linear);
}

.b-head__menutrigger i {
  display: block;
  width: 26px;
  height: 3px;
  background: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  @include prop-transition(background, 300ms, linear);
}

.b-head__menutrigger i i {
  left: 0;
}

.scrolled .b-head__menutrigger i {
  background: #000000;
}

.scrolled .b-head__menutrigger {
  left: 100px;
}


.b-head__menu {
  overflow: hidden;
  margin-left: 0px;
}

.b-head__nav {
  float: left;
  font-size: 18px;
  line-height: 26px;
  font-family: "DaxLinePro", sans-serif;
}

.b-head__nav a {
  margin-right: 20px;
}

.b-head__nav__end {
  clear: both;
}

.b-head-menu-socials {
  display: none;
}

.b-head__menu__close {
  display: none;
}

.b-head__socials {
  float: right;
  margin-left: 35px;
  padding-top: 3px;
}

.b-head__socials a {
  margin-left: 30px;
}

.b-head__socials a img {
  vertical-align: top;
}

.b-head__phone {
  float: right;
  font-size: 18px;
  line-height: 26px;
}

.scrolled {
  .b-head {
    color: #000;
    a {
      color: #000;
    }
  }

  .b-head__bg {
    @include prop-opacity(1.0);
  }

}

.scrolled .b-head__nav {
    margin-left: 100px;
}


.scrolled .b-head a:hover, .scrolled .b-head a.active {
  color: #a4a4a4;
}

.scrolled .b-head__socials a img {
  display: none;
}

.scrolled .b-head__socials a img.d-n {
  display: inline-block;
}

.scrolled .b-head__lang a, .scrolled .b-head__lang span {
  border-color: #cccccc;
  background-color: #ffffff;
  color: #cccccc;
}

.scrolled .b-head__lang a:hover, .scrolled .b-head__lang .active {
  color: #ffffff;
  background: #cccccc;
}

.b-projects__one {
  display: block;
  float: left;
  width: 25%;
  position: relative;
}

.b-projects_alternate {
  position: relative;
}

.b-projects_alternate:after {
  display: block;
  content: " ";
  height: 1px;
  background: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.b-projects_alternate .b-projects__one {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &.b-projects--action .b-professionals__image {
    background-image: url('../img/projects/action/thumb.png');
  }

  &.b-projects--dostavista .b-professionals__image {
    background-image: url('../img/projects/dostavista/thumb.png');
  }

  &.b-projects--nissan .b-professionals__image {
    background-image: url('../img/projects/nissan/thumb.png');
  }

  &.b-projects--graviwar .b-professionals__image {
    background-image: url('../img/projects/graviwar/thumb.png');
  }

  &.b-projects--vedomosti .b-professionals__image {
    background-image: url('../img/projects/vedomosti/thumb.png');
  }

  &.b-projects--width-one-third {
    width: 33.333%;

    .b-professionals__image {
      padding-bottom: 70.3125%;
    }
  }

  &.b-projects--width-55 {
    width: 55%;

    .b-professionals__image {
      padding-bottom: 42.6136%;
    }
  }

  &.b-projects--width-45 {
    width: 45%;

    .b-professionals__image {
      padding-bottom: 52.0833%;
    }
  }

}

.b-projects__one .b-professionals__image {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.b-projects__one .b-professionals__image img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.b-projects__one .b-professionals__text {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.b-projects__one .b-professionals__name {
  bottom: 60%;
}

.b-projects__one .b-professionals__job {
  top: 50%;
}

.b-projects__one .b-professionals__job a {
  display: block;
  width: 147px;
  height: 46px;
  margin: auto;
  border: 2px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  line-height: 46px;
  font-weight: 400;
  font-family: "DaxLinePro", sans-serif;
}

.b-projects__one .b-professionals__job a:hover {
  background: #ffffff;
  color: #303003;
}

.b-block {
  padding: 80px 0;
}

.b-block__title {
  padding-bottom: 60px;
  color: #000000;
  font-size: 36px;
  line-height: 50px;
  font-weight: 100;
  text-align: center;
  font-family: "DaxLinePro", sans-serif;
}

.b-whatwedo__one {
  float: left;
  width: 25%;
  margin-top: 30px;
  text-align: center;
}

.b-whatwedo__title {
  padding: 25px 0 10px 0;
  color: #191919;
  font-size: 24px;
  line-height: 40px;
  font-family: "DaxLinePro", sans-serif;
}

.b-whatwedo__text {
  padding: 0 20px;
}

.b-theytrust {
  background: #f8f6f3;
}

.b-theytrust__names {
  margin-top: 30px;
  text-align: center;
}

.b-theytrust__names a, .b-theytrust__names span {
  margin: 0 10px;
  color: #303030;
}

.b-theytrust__list {
  margin-top: 43px;
  overflow: hidden;
}

.b-theytrust__one {
  display: block;
  float: left;
  width: 25%;
  height: 124px;
  margin: -1px 0 0 -1px;
  padding: 7px 0;
  border-top: 1px solid #e0deda;
  border-left: 1px solid #e0deda;
  text-align: center;
  position: relative;
}

.b-theytrust__one img {
  display: block;
  margin: auto;
  padding: 0;
  position: relative;
  z-index: 2;
  -moz-transition: -moz-opacity 300ms linear;
  -webkit-transition: -webkit-opacity 300ms linear;
  -o-transition: -o-opacity 300ms linear;
  transition: opacity 300ms linear;
  width: 210px;
  height: 130px;
}

.b-morethandev .l-wrap {
  max-width: 1310px;
  padding: 0 45px;
}

.b-morethandev__image {
  float: left;
  margin: 30px 46px 0 0;
}

.b-morethandev__text {
  margin-top: 30px;
  overflow: hidden;
}

.b-morethandev__name {
  color: #000000;
  font-size: 24px;
  line-height: 40px;
  font-family: "DaxLinePro", sans-serif;
}

.b-morethandev__job {
  margin-bottom: 5px;
  color: #000000;
  font-size: 14px;
  line-height: 22px;
}

.b-morethandev__slogan {
  margin-bottom: 30px;
  color: #191919;
}

.b-morethandev__descr {
  padding-left: 33px;
}

.b-morethandev__descr p {
  display: block;
  float: left;
  width: 50%;
}

.b-morethandev__descr p:first-child {
  margin-right: 33px;
  margin-left: -33px;
}

.b-platforms {
  padding: 115px 0;
  background: #1A2754 url("../img/base/pbg.png") repeat 50% 0;
  color: #ffffff;
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  text-align: center;
}

.b-platforms__icos {
  margin-bottom: 31px;
}

.b-platforms__icos img {
  vertical-align: middle;
  margin: 0 40px;
}

.b-awards .l-wrap {
  text-align: center;
  max-width: 1200px;
}

.b-awards-group {
  display: inline-block;
}

.b-awards-group + .b-awards-group {
  margin-top: 20px;
}

.b-awards-item {
  display: inline-block;
  width: 260px;
  vertical-align: middle;
}

.b-awards__header {
  display: table;
  height: 130px;
  margin: 0 auto;
}

.b-awards__image {
  display: table-cell;
  width: 168px;
  height: 130px;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
}

.b-awards-item.no-title .b-awards__image {
  height: 195px;
}

.b-awards__title {
  width: 200px;
  height: 65px;
  color: #000000;
  font-size: 40px;
  line-height: 65px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
}

.b-awards__brief {
  margin: 0 auto;
  height: 60px;
}

.b-reviews {
  background: #f8f6f3;
}

.b-reviews .l-wrap {
  max-width: 1210px;
}

.b-reviews__carousel {
  padding-top: 30px;
  position: relative;

  li {
    max-width: 450px;
  }
}

.b-carousel {
  margin: 0 30px;
  position: relative;
  overflow: hidden;
}

.b-carousel__prev, .b-carousel__next {
  margin-top: -28px;
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer;
}

.b-carousel__next {
  right: 0;
  left: auto;
}

.b-carousel__prev.disabled, .b-carousel__next.disabled {
  display: none;
}

.b-reviews ul, .b-reviews li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.b-reviews ul {
  width: 10000%;
  position: relative;
}

.b-reviews li {
  float: left;
  margin: 0 85px 0 70px;
}

.b-reviews li:before {
  display: none;
}

.b-reviews__image {
  float: left;
  margin-right: 20px;
}

.b-reviews__image img {
  display: block;
  margin: 0;
  padding: 0;
}

.b-reviews__ov {
  overflow: hidden;
}

.b-reviews__name {
  font-size: 24px;
  line-height: 37px;
  font-family: "DaxLinePro", sans-serif;
}

.b-reviews__job {
  width: 190px;
  font-size: 14px;
  line-height: 22px;
}

.b-reviews__quote {
  padding: 10px 0 5px 0;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
}

.b-reviews__text {
  width: 435px;
  padding-top: 10px;
}

.b-reviews__more {
  padding-top: 10px;
  font-size: 18px;
  line-height: 27px;
}

.b-professionals .l-wrap {
  max-width: 1170px;
}

.b-professionals__list {
  padding: 1% 0 0 0.8%;
}

.b-professionals__list ul, .b-professionals__list li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.b-professionals__list li {
  float: left;
  width: 25%;
  position: relative;
}

.b-professionals__list li:before {
  display: none;
}

.b-professionals__image {
  padding: 0 3% 3% 0;
}

.b-professionals__image img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.b-professionals__text {
  background: rgba(12, 12, 12, 0.90);
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 0;
  right: 3%;
  bottom: 3%;
  left: 0;

  @include prop-opacity(0);
  @include opacity-transition(200ms, linear);
}

.b-professionals li:hover .b-professionals__text, .b-projects__one:hover .b-professionals__text {
  @include prop-opacity(1.0)
}

.b-professionals__name {
  font-size: 24px;
  line-height: 40px;
  font-family: "DaxLinePro", sans-serif;
  position: absolute;
  right: 20px;
  bottom: 45%;
  left: 20px;
}

.b-professionals__job {
  font-size: 18px;
  line-height: 34px;
  position: absolute;
  top: 55%;
  right: 20px;
  left: 20px;
}

.b-workway {
  background: #1A2754 url("../img/base/pbg.png") repeat 50% 0;
  color: #ffffff;
  text-align: center;
}

.b-workway .b-block__title {
  color: #ffffff;
}

.b-workway__one {
  display: inline-block;
  width: 20%;
  margin: 70px 2% 30px 2%;
  vertical-align: top;
}

.b-workway__image {
  height: 50px;
  margin-bottom: 20px;
}

.b-workway__text {
  font-size: 20px;
  line-height: 31px;
  font-family: "DaxLinePro", sans-serif;
  overflow: hidden;
}

.b-announces .l-wrap {
  padding: 0 45px;
}

.b-announces__section {
  float: left;
  width: 47%;
  padding-top: 30px;
}

.b-announces__section_right {
  float: right;
}

.b-announces__title {
  margin-bottom: 37px;
  color: #383838;
  font-size: 24px;
  line-height: 33px;
}

.b-announces__one {
  margin-bottom: 30px;
}

.b-announces__image {
  float: left;
  margin-right: 30px;
}

.b-announces__text {
  overflow: hidden;
}

.b-announces__date {
  margin-bottom: 2px;
  color: #b7b7b7;
  font-size: 16px;
  line-height: 22px;
}

.b-announces__name {
  font-size: 24px;
  line-height: 33px;
}

.b-announces__descr {
  margin-top: 10px;
  font-size: 18px;
  line-height: 24px;
}

.b-announces__more {
  padding-top: 10px;
  font-size: 18px;
  line-height: 27px;
  text-align: right;
}

@keyframes field-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

@-webkit-keyframes field-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.b-field__shake {
  animation: field-shake 0.7s both;
  -webkit-animation: field-shake 0.7s both;
}

.b-field__hint {
  position: absolute;
  margin-top: -15px;
  font-family: "DaxLinePro", sans-serif;
  font-size: 18px;
  color: #F74343;
  opacity: 0.0;
  transition: opacity 100ms linear, margin-top 100ms ease-out;
}

.b-field__hint__visible {
  margin-top: -25px;
  opacity: 1.0;
}

/* inner page */

.b-textpage {
  margin-bottom: 55px;
  padding-top: 84px;
}

.b-textpage_nohead {
  padding-top: 124px;
}

.b-textpage .l-wrap {
  max-width: 1080px;
  padding: 0 45px;
}

.b-textpage__head {
  padding: 120px 0 40px 0;
  margin-bottom: 70px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  color: #ffffff;
  position: relative;
}

.b-textpage__date {
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 19px;
}

.b-textpage__title {
  font-size: 48px;
  line-height: 65px;
}

.b-textpage__title2 {
  font-size: 24px;
  line-height: 32px;
}

.b-textpage__quote {
  font-style: italic;
}

.b-textpage p {
  margin-bottom: 24px;
}

.b-textpage__image {
  margin-bottom: 24px;
  padding: 24px 0;
  text-align: center;
}

.b-textpage__image img {
  max-width: 100%;
  height: auto;
}

.b-textpage__video {
  text-align: center;
}

.b-textpage__video iframe {
  margin: 0 auto;
}

.b-textpage__socials {
  padding-top: 75px;
  text-align: center;
}

.b-textpage__comments {
  padding-top: 20px;
  text-align: center;
}

/* case page */

.b-casehead {
  height: 700px;
  min-height: 300px;
  padding-top: 84px;
  background-repeat: no-repeat;
  background-position: 50% 84px;
}

.b-casehead__video {
  height: 100%;
}

.b-casehead__video iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.b-casehead .l-wrap {
  margin-top: 80px;
  padding: 0 45px;
}

.b-casehead__logo {
  margin-bottom: 4px;
  max-width: 100%;
}

.b-casehead__descr {
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 33px;
  max-width: 100%;
}

.b-caseblock {
  padding-top: 80px;
  position: relative;
}

.b-caseblock_withsides {
  overflow: hidden;
}

.b-caseblock__left {
  position: absolute;
  left: 0;
  top: 50%;
}

.b-caseblock__right {
  position: absolute;
  right: 0;
  top: 50%;
}

.b-caseblock_tripled {
  margin-bottom: 175px;
  padding-bottom: 1px;
}

.b-caseblock_screens {
  margin-bottom: 50px;
}

.b-caseblock_parallax {
  background-repeat: repeat;
  background-position: 50% 50%;
}

.b-caseblock_parallax_withscreen {
  margin-bottom: 400px;
  padding-bottom: 1px;
}

.b-caseblock_parallax .b-caseblock__screen {
  padding: 0;
  margin-top: -300px;
  margin-bottom: -389px;
}

.b-caseblock__title {
  max-width: 900px;
  margin: 0 auto 20px auto;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.b-caseblock__subtitle {
  max-width: 660px;
  margin: auto;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.b-caseblock__text {
  max-width: 660px;
  margin: auto;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.b-caseblock_parallax__things {
  height: 1275px;
  position: relative;
  overflow: hidden;
}

.b-caseblock_parallax__things img {
  display: block;
  position: absolute;
  top: 0;
  left: 50% !important;
}

.b-caseblock__screen00 {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  position: relative;
  overflow: hidden;
}

.b-caseblock__triple {
  width: 972px;
  margin: 0 auto -175px auto;
}

.b-caseblock__triple__one {
  float: left;
  width: 274px;
  margin: 80px 25px 0 25px;
}

.b-caseblock__triple__descr {
  font-size: 18px;
  line-height: 24px;
  font-style: italic;
  text-align: center;
}

.b-caseblock__triple__image {
  width: 374px;
  margin: -10px -50px 0 -50px;
  text-align: center;
}

.b-caseblock__screen {
  padding: 50px 0 70px 0;
  position: relative;
  z-index: 1;
}

.b-caseblock__screen--nopadding {
  padding: 0;
}

.b-caseblock__screen_flex {
  margin: auto;
}

.b-caseblock__screen_flex img {
  display: block;
  width: 100%;
  height: auto;
}

.b-caseblock__screen_bg {
  margin-top: 50px;
  padding-top: 80px;
}

.b-caseblock__screen__descr {
  max-width: 410px;
  margin: 0 auto 40px auto;
  font-size: 20px;
  line-height: 27px;
  font-style: italic;
  text-align: center;
}

.b-caseblock__screen__descr_arrow {
  max-width: 320px;
  padding: 70px 20px 0 50px;
  background: url("../img/base/case-arrow.png") no-repeat 0 0;
  text-align: left;
  position: absolute;
  left: 50%;
  margin-left: 160px;
}

.b-caseblock__screen__image {
  margin: auto;
  text-align: center;
}

.b-caseblock-carousel {
  margin: 40px 0 90px 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.b-caseblock-carousel_flex {
  margin: 0;
  overflow: hidden;
}

.b-caseblock-carousel__wrap {
  padding: 160px 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
}

.b-caseblock-carousel__viewport {
  margin: auto;
  position: relative;
  overflow: hidden;
}

.b-caseblock-carousel_flex .b-caseblock-carousel__viewport {
  overflow: visible;
}

.b-caseblock-carousel_flex:before, .b-caseblock-carousel_flex:after {
  display: block;
  content: " ";
  width: 200px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* IE10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.b-caseblock-carousel_flex:after {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* IE10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
  right: 0;
  left: auto;
}

.b-caseblock-carousel ul, .b-caseblock-carousel li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.b-caseblock-carousel ul {
  width: 10000%;
  position: relative;
}

.b-caseblock-carousel li {
  float: left;
}

.b-caseblock-carousel_flex li {
  margin: 0 100px;
}

.b-caseblock-carousel_flex li.noM {
  margin: 0;
}

.b-caseblock-carousel li:before {
  display: none;
}

.b-caseblock-carousel img {
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  height: auto;
}

.b-caseblock-carousel_flex li img, .b-caseblock-carousel_respond li img {
  max-width: 100%;
  height: auto;
}

.b-caseblock-carousel__prev, .b-caseblock-carousel__next {
  width: 119px;
  height: 119px;
  margin-top: -60px;
  background: url("../img/base/arrowleft_bg.png") no-repeat 50% 50%;
  position: absolute;
  top: 50%;
  left: 5%;
  z-index: 2;
  cursor: pointer;
}

.b-caseblock-carousel__next {
  background-image: url("../img/base/arrowright_bg.png");
  right: 5%;
  left: auto;
}

.b-caseblock-selector {
  margin: 0 auto;
  text-align: center;
}

.b-caseblock-viewport {
  width: 100%;

  .b-caseblock-viewport-image {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.b-case-team {

}

.b-case-review {
  padding: 120px 0 100px 0;
  background: #f8f6f3;
}

.b-case-team__one {
  padding-bottom: 100px;
}

.b-case-team .l-wrap, .b-case-review .l-wrap {
  max-width: 685px;
}

.b-case-photos {
  position: relative;
  overflow: hidden;
}

.b-case-photos ul, .b-case-photos li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.b-case-photos ul {
  width: 10000%;
  position: relative;
}

.b-case-photos li {
  float: left;
}

.b-case-photos li:before {
  display: none;
}

.b-case-photos li img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.b-case-photos__prev, .b-case-photos__next {
  display: block;
  width: 81px;
  height: 81px;
  margin-top: -40px;
  background: url("../img/base/arrowleft_bg.png") no-repeat 0 0;
  position: absolute;
  top: 50%;
  left: 50px;
  cursor: pointer;
}

.b-case-photos__next {
  background-image: url("../img/base/arrowright_bg.png");
  right: 50px;
  left: auto;
}

.b-case-review__author {
  margin-bottom: 20px;
}

.b-case-review__photo {
  float: left;
  margin-right: 20px;
}

.b-case-review__person {
  overflow: hidden;
}

.b-case-review__name {
  font-size: 24px;
  line-height: 33px;
}

.b-case-review__job {
  font-size: 20px;
  line-height: 27px;
}

.b-case-review__text {
  font-size: 18px;
  line-height: 24px;

  p+p {
    margin-top: 24px;
  }

  ul li {
  }
}

.b-case-foot {
  padding: 80px 0 40px 0;
  background: #083088 url("../img/base/pbg.png") repeat 50% 0;
  color: #ffffff;
}

.b-case-foot a {
  color: #ffffff;
}

.b-case-foot__btn {
  margin-bottom: 45px;
  padding-top: 60px;
}

.b-case-foot__btn a {
  display: block;
  width: 196px;
  margin: auto;
  padding: 10px;
  border: 2px solid #ffffff;
  background: #ffffff;
  color: #083088;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-family: "DaxLinePro", sans-serif;
}

.b-case-foot__btn a:hover {
  color: #ffffff;
  background: none;
}

.b-case-foot__nav {
  max-width: 570px;
  margin: auto;
  padding: 60px 0 20px 0;
  font-size: 20px;
  line-height: 27px;
}

.b-case-foot__nav__prev {
  float: left;
  padding-left: 25px;
  background: url("../img/base/arrowleft.png") no-repeat 0 50%;
}

.b-case-foot__nav__next {
  float: right;
  padding-right: 25px;
  background: url("../img/base/arrowright.png") no-repeat 100% 50%;
}

.b-anchor {
  display: block;
  margin: -84px 0 84px 0;
}

@media screen and (max-width: 1280px) {
  .hide-lowres {
    display: none !important;
  }

  .l-wrap {
    padding: 0 45px;
  }

  .b-head .l-wrap {
    width: auto;
    min-width: 50%;
  }

  .b-head__nav a {
    margin-right: 10px;
  }

  .b-head__logo {
    right: auto;
  }

  .b-workway__list {
    margin-left: -5%;
  }

  .b-caseblock-carousel_flex:before, .b-caseblock-carousel_flex:after {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  
  .scrolled .b-head__nav {
    margin-left: 0px;
}

  .l-wrap {
    min-width: 890px;
  }

  .b-theytrust__one img {
    width: 152px;
    height: 94px;
    margin-top: 12px;
  }

  .b-head__menutrigger {
    display: block;
  }

  .b-head__menu {
    display: none;
    // padding: 0 0 10px 0;
    // position: absolute;
    // top: 56px;
    // left: 0;
    // -moz-transition: background 300ms linear;
    // -webkit-transition: background 300ms linear;
    // -o-transition: background 300ms linear;
    // transition: background 300ms linear;
  }

  .scrolled .b-head__menu {
    background: #ffffff;
  }

    .b-head__logo {
    right: auto;
  }

  .b-head__nav a {
	display: block;
    margin-right: 0;
    padding: 5px 30px;
  }

  .scrolled .b-head__nav a.active {
    color: #000;

  }

  .b-block {
    padding: 70px 0;
  }

  .b-projects__one {
    width: 50%;
  }

  .b-whatwedo {
    padding-bottom: 30px;
  }

  .b-whatwedo .br.d-n {
    display: block;
  }

  .b-whatwedo__one {
    width: 34%;
    margin: 30px 8% 90px 8%;
  }

  .b-theytrust .l-wrap {
    padding: 0;
    min-width: 1000px;
  }

  .b-theytrust__names a, .b-theytrust__names span {
    margin: 0 5px;
  }

  .b-morethandev__descr {
    padding-left: 0;
  }

  .b-morethandev__descr p {
    float: none;
    width: auto;
  }

  .b-morethandev__descr p:first-child {
    margin-right: 0;
    margin-left: 0;
  }

  .b-awards .l-wrap {
    width: auto;
  }

  .b-awards__one {
    width: 400px;
  }

  .b-awards__one_5, .b-awards__one_6 {
    width: auto;
  }

  .b-carousel__prev {
    left: -20px;
  }

  .b-carousel__next {
    right: -20px;
  }

  .b-professionals .l-wrap {
    min-width: 910px;
  }

  .b-workway__one {
    width: 20%;
    margin: 30px 2% 15px 2%;
  }

  .b-workway__image {
    float: none;
    height: 64px;
  }

  .b-announces__section {
    float: none;
    width: auto;
  }

  .b-caseblock-carousel__prev {
    left: 0;
  }

  .b-caseblock-carousel__next {
    right: 0;
  }

  .b-caseblock__triple {
    width: auto;
    margin: auto;
  }

  .b-caseblock__triple__one {
    float: none;
    margin: auto;
  }
}

@media only screen and (max-width: 990px) and (min-width: 481px) {
  .b-popup {
    width: auto;
    margin-left: 0;
    right: 30px;
    left: 30px;
  }
}

@media screen and (max-width: 990px) {

  .l-wrap {
    min-width: 590px;
  }

  .b-caseblock-carousel__prev, .b-caseblock-carousel__next {
    display: none !important;
  }

  .b-projects__one .b-professionals__job a {
    width: 110px;
    height: 29px;
    border: 1px solid #ffffff;
    font-size: 14px;
    line-height: 29px;
  }

  .b-theytrust .l-wrap {
    padding: 0;
    min-width: 700px;
  }

  .b-awards .l-wrap {
    width: auto;
  }

  .b-awards__one {
    width: 35%;
    margin-left: 10%;
  }

  .b-awards__one_5, .b-awards__one_6 {
    width: auto;
    margin-left: 0;
  }

  .b-awards__one_6 {
    padding-top: 100px;
  }

  .b-awards__one_4 .b-awards__image {
    height: 151px;
  }

  .b-awards__image, .b-awards__title {
    float: none !important;
  }

  .b-reviews .l-wrap {
    padding: 0;
  }

  .b-reviews .b-carousel {
    margin: 0;
  }

  .b-carousel__prev, .b-carousel__next {
    display: none;
  }

  .b-professionals .l-wrap {
    padding: 0;
    min-width: 700px;
  }

  .b-professionals__name {
    font-size: 18px;
    line-height: 30px;
    font-family: "DaxLinePro", sans-serif;
    position: absolute;
    right: 10px;
    bottom: 45%;
    left: 10px;
  }

  .b-professionals__job {
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    top: 55%;
    right: 20px;
    left: 20px;
  }

  #fader {
    background: rgba(12, 12, 12, 0.84);
  }

  .b-popup {
    // width: auto;
    // margin-left: 0;
    // right: 30px;
    // left: 30px;
  }

  // .b-popup-success {
	// // display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: space-around;
  // }

  .b-popup__close {
    top: 20px;
    right: 20px;
  }

  .b-popup__close img {
    display: none;
  }

  .b-popup__close img.d-n {
    display: block !important;
  }

  .b-caseblock-carousel__wrap {
    padding: 0;
    background: none !important;
  }

  .b-caseblock__screen__descr_arrow {
    max-width: 410px;
    margin: 0 auto 40px auto;
    padding: 0;
    background: none;
    text-align: center;
    position: static;
    left: auto;
  }
}

@media screen and (max-width: 1024px) {
  .hide-mobile, .b-carousel__prev, .b-carousel__next, .b-reviews__text, .b-reviews__more, .b-workway, .b-announces, .b-caseblock-carousel__prev, .b-caseblock-carousel__next, .b-case-photos__prev, .b-case-photos__next {
    display: none !important;
  }

  .b-head {
    margin-bottom: -1px;
    padding: 0;
    border-color: transparent;
    position: static;
    z-index: 1;
  }

  .b-head .l-wrap {
    position: static;
  }

  .b-head__lang {
    position: fixed;
    top: 15px;
    right: 10px;
    z-index: 1;
  }

  .b-head__menutrigger {
    width: 44px;
    height: 44px;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 404;

  }

  .scrolled .b-head__menutrigger {
    background: rgba(255, 255, 255, 0.90);
    left: 15px;
  }

  .b-head__menutrigger i {
    width: 25px;
    height: 2px;
    top: 11px;
    left: 10px;
  }

  .b-head__menutrigger i i {
    top: 10px;
  }

  .b-head__menu {
    padding: 90px 0 10px 0;
    background: #ffffff;
    color: #000000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 410;
  }

  .b-head__phone {
    position: fixed;
    left: 70px;
    right: 110px;
    top: 20px;
    // z-index: 410;
    z-index: 405;
    text-align: right;
    font-size: 15px;
  }

  .scrolled {
    .b-head__phone {
      a {
        opacity: 0;
        color: #fff;
        pointer-events: none;
      }
    }
  }

  .b-head__nav {
    position: absolute;
    top: 90px;
    right: 0;
    left: 0;
    overflow-y: auto;
  }

  .b-head__nav a {
    padding: 8px 70px;
    color: #000000;
    font-size: 27px;
    line-height: 37px;
  }

  .b-head__nav__end {
    display: none;
  }

  .b-head-menu-socials {
    display: block;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    padding: 8px 70px;

    a img {
      width: 20px;
      height: auto;
    }
  }

  .b-head__nav a:hover {
    color: #a4a4a4;
  }

  .b-head__menu__close {
    display: block;
    width: 44px;
    height: 33px;
    padding-top: 12px;
    text-align: center;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
  }

  .b-head__socials {
    opacity: 0;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
  }

  .b-head__logo {
    margin: 0;
    padding: 0;
    position: fixed;
    top: 25px;
    left: 70px;
    right: auto;
    z-index: 5;
    opacity: 0 !important;
  }

  .b-head.active .b-head__mobile-logo {
    // display: block !important;
	// opacity: 1 !important;
	display: none;
    @include opacity-transition(300ms, linear);
  }

//   .b-head__logo img {
//     display: block !important;
//     width: 182px;
//     height: 49px;
//   }

  .b-head__logo img.d-n {
    display: none !important;
  }

  .b-head__lang {
    width: 98px;
    padding: 8px 0 0 0;
    margin-right: 0px;
    -moz-transition: -moz-opacity 300ms linear;
    -webkit-transition: -webkit-opacity 300ms linear;
    -o-transition: -o-opacity 300ms linear;
    transition: opacity 300ms linear;
  }

  .scrolled .b-head__lang {
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }

  .b-head__lang a, .b-head__lang span, .scrolled .b-head__lang a, .scrolled .b-head__lang span {
    width: 28px;
    height: 28px;
    margin-left: 15px;
    border: 1px solid #ffffff;
    background: none;
    font-size: 12px;
    line-height: 28px;
  }

  .scrolled .b-head__lang a:hover, .scrolled .b-head__lang .active {
    color: #083088;
    background: #ffffff;
  }

  .b-head__lang a:hover, .b-head__lang .active {
    color: #083088;
    background: #ffffff;
  }

  .l-wrap {
    min-width: 290px;
    padding: 0 15px;
  }

  .b-block {
    padding: 60px 0;
  }

  .b-block__title {
    padding-bottom: 30px;
    font-size: 24px;
    line-height: 29px;
  }

  .b-projects_alternate .b-projects__one {
    float: none;
    width: auto !important;
    overflow: hidden;
    text-align: center;

    &.b-projects--action .b-professionals__image {
      background-image: url('../img/projects/action/thumb_720.jpg');
    }

    &.b-projects--dostavista .b-professionals__image {
      background-image: url('../img/projects/dostavista/thumb_720.jpg');
    }

    &.b-projects--nissan .b-professionals__image {
      background-image: url('../img/projects/nissan/thumb_720.jpg');
    }

    &.b-projects--graviwar .b-professionals__image {
      background-image: url('../img/projects/graviwar/thumb_720.jpg');
    }

    &.b-projects--vedomosti .b-professionals__image {
      background-image: url('../img/projects/vedomosti/thumb_720.jpg');
    }

    .b-professionals__image {
      padding-bottom: 41.6666% !important;
    }
  }

  .b-whatwedo__one {
    float: none;
    width: auto;
    margin: 0 0 20px 0;
    padding: 0;
    text-align: left;
  }

  .b-whatwedo__image {
    float: left;
    width: 44px;
    height: 37px;
    margin-right: 15px;
    padding-top: 7px;
    text-align: center;
  }

  .b-whatwedo__image img {
    width: auto;
    height: 30px;
  }

  .b-whatwedo__title {
    padding: 13px 0 2px 59px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  .b-whatwedo__text {
    padding: 0 0 0 59px;
    font-size: 16px;
    line-height: 22px;
  }

  .b-theytrust .l-wrap {
    width: auto;
    min-width: 320px;
    padding: 0;
  }

  .b-theytrust__names {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
  }

  .b-theytrust__one {
    width: 50%;
    //height: 62px;
  }


  .b-morethandev {
    padding-bottom: 14px;
  }

  .b-morethandev .l-wrap {
    padding: 0 15px;
  }

  .b-morethandev__image {
    margin: 0 15px 10px 0;
  }

  .b-morethandev__image img {
    width: 90px;
    height: 90px;
  }

  .b-morethandev__text {
    margin: 0;
    overflow: visible;
  }

  .b-morethandev__name {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
  }

  .b-morethandev__job {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 18px;
  }

  .b-morethandev__slogan {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 22px;
  }

  .b-morethandev__text p {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 22px;
  }

  .b-platforms {
    padding: 35px 15px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
  }

  .b-platforms__icos img {
    width: 30px;
    height: auto;
    margin: 0 20px;
  }

  .b-awards__image img {
    width: 120px;
  }

  .b-awards-item.no-title .b-awards__image {
    height: 165px;
  }

  .b-awards__title {
    height: 35px;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
  }

  .b-awards__one img {
    width: 84px;
    height: auto;
  }

  .b-reviews .l-wrap {
    width: auto;
    min-width: 320px;
    margin: 0;
    padding: 0;
  }

  .b-reviews__carousel {
    padding-top: 0;
  }

  .b-carousel {
    margin: 0;
  }

  .b-reviews__carousel li {
    width: 250px;
    margin: 0 13px 0 15px;
  }

  .b-reviews__image img {
    width: 60px;
    height: 60px;
  }

  .b-reviews__name {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
  }

  .b-reviews__job {
    width: auto;
    font-size: 13px;
    line-height: 18px;
  }

  .b-reviews__quote {
    font-size: 12px;
    line-height: 22px;
  }

  .b-professionals {
    overflow: hidden;
  }

  .b-professionals .l-wrap {
    min-width: 320px;
  }

  .b-professionals .b-block__title {
    padding: 0 45px 30px 45px;
  }

  .b-professionals__list {
    height: 170px;
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  .b-professionals__list ul {
    width: 1000%;
    position: relative;
  }

  .b-professionals__list li {
    display: block;
    float: left;
    width: 170px;
    height: 170px;
    margin: 0 5px;
    padding: 0;
  }

  .b-professionals__name {
    font-size: 15px;
    line-height: 20px;
    right: 10px;
    left: 10px;
  }

  .b-professionals__job {
    font-size: 9px;
    line-height: 17px;
    right: 10px;
    left: 10px;
  }

  .b-projects__one .b-professionals__job a {
    width: 150px;
    height: 50px;
    border: 1px solid #ffffff;
    font-size: 15px;
    line-height: 50px;
  }

  .b-professionals__image {
    padding: 0;
  }

  .b-professionals__text {
    right: 0;
    bottom: 0;
  }

  /* inner page */
  .inner.scrolled .b-head {
    background: none;
    border: none;
  }

  .b-textpage {
    margin-bottom: 20px;
    padding: 0;
  }

  .b-textpage_nohead {
    padding-top: 80px;
  }

  .b-textpage .l-wrap {
    min-width: 260px;
    padding: 0 15px;
  }

  .b-textpage__head {
    margin-bottom: 30px;
    padding: 80px 0 30px 0;
  }

  .b-textpage__title {
    font-size: 36px;
    line-height: 48px;
  }

  .b-textpage__title2 {
    font-size: 22px;
    line-height: 28px;
  }

  .b-textpage__socials {
    padding-top: 20px;
  }

  .b-textpage__comments {
    padding-top: 20px;
  }

  .b-anchor {
    display: block;
    margin: 0;
  }

  #popupFeedback {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: auto !important;
    padding: 15px 30px;
    overflow-y: auto;
  }

  .b-popup_small .b-popup__title {
    font-size: 28px;
    line-height: 20px;
  }

  #popupFeedback .b-popup__form__field {
    margin-bottom: 30px;
    text-align: center;
  }

  #popupFeedback .b-popup__form__field input,
  #popupFeedback .b-popup__form__field textarea {
    padding: 8px 12px;
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 700px) and (orientation: landscape) {
  .b-theytrust__one img {
    width: 105px;
    height: 65px;
    margin: auto;
  }
}

@media screen and (max-width: 550px) {
  /* Вертикальное расположение Awards на главной */
  .b-awards__header, .b-awards__image,
  .b-awards-item.no-title .b-awards__header, .b-awards-item.no-title .b-awards__image {
    height: auto;
  }

  .b-head__bg {
    display: none;
  }

  .b-awards-group + .b-awards-group,
  .b-awards-item + .b-awards-item {
    margin-top: 20px;
  }
}

@media screen and (max-width: 360px) {
  #popupFeedback .b-popup__title {
    font-size: 20px;
    line-height: 20px;
  }

  .b-popup__form__field input, .b-popup__form__field textarea {
    font-size: 14px;
  }
  
  .b-popup__close {
    width: 16px;
  }

  .b-popup__close img.d-n {
    width: 100%;
  }

  #popupFeedback .b-popup__form__field {
    margin-bottom: 20px;
  }

  .b-popup__form__submit input {
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }

  .b-popup__form__field textarea {
    height: 52px;
  }

  #popupFeedback .b-popup__form__field input,
  #popupFeedback .b-popup__form__field textarea {
    padding: 4px 12px;
  }

  .b-popup__form__submit {
    padding-top: 0;
  }

  .b-field__hint {
    font-size: 12px;
  }
  

  .b-projects_alternate .b-projects__one {

    &.b-projects--action .b-professionals__image {
      background-image: url('../img/projects/action/thumb_360.jpg');
    }

    &.b-projects--dostavista .b-professionals__image {
      background-image: url('../img/projects/dostavista/thumb_360.jpg');
    }

    &.b-projects--nissan .b-professionals__image {
      background-image: url('../img/projects/nissan/thumb_360.jpg');
    }

    &.b-projects--graviwar .b-professionals__image {
      background-image: url('../img/projects/graviwar/thumb_360.jpg');
    }

    &.b-projects--vedomosti .b-professionals__image {
      background-image: url('../img/projects/vedomosti/thumb_360.jpg');
    }

    .b-professionals__image {
      padding-bottom: 72.2222% !important;
    }
  }

}

/* animations */
@-webkit-keyframes buzz-out {
  5% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  10% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  15% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  25% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  30% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  35% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  40% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  45% {
    -webkit-transform: translateX(1px) rotate(0deg);
    transform: translateX(1px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateX(-1px) rotate(0deg);
    transform: translateX(-1px) rotate(0deg);
  }
  55% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes buzz-out {
  5% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  10% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  15% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  25% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  30% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  35% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  40% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  45% {
    -webkit-transform: translateX(1px) rotate(0deg);
    transform: translateX(1px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateX(-1px) rotate(0deg);
    transform: translateX(-1px) rotate(0deg);
  }
  55% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
  }
}

.buzz-out {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation-name: buzz-out;
  animation-name: buzz-out;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes bob-float {
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bob-float {
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.bob-float {
  -webkit-animation-name: bob-float;
  animation-name: icon-bob-float;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}


.js-fade {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
}

.unselectable {
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.b-spacer {
  height: 84px;
}

.sebbia-event img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fixed-scroll {
	height: 100%;
	overflow: hidden;
  width: 100%;
}
