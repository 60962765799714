$colorCaseActionGlavbuhBackground: #1d1d1b;
$colorCaseActionGendirBackground: #ae9a63;
$colorCaseActionComDirBackground: #3299bc;
$colorCaseActionFinDirBackground: #f98b33;
$colorCaseActionUprBackground: #94bd4e;
$colorCaseActionLawyerBackground: #08344d;
$colorCaseActionUbuBackground: #177a60;

$colorCaseActionBlockBright: #ffffff;
$colorCaseActionBlockDark: #393b3f;
$colorCaseActionTextBright: #fff6f1; // Светлый текст на тёмном фоне
$colorCaseActionTextDark: #999999; // Тёмный текст на тёмном фоне

@mixin sliderGradient($leftColorHex, $leftColorAlpha, $rightColorHex, $rightColorAlpha) {
  $leftColor: rgba($leftColorHex, $leftColorAlpha);
  $ieLeftColor: ie_hex_str($leftColor);
  $rightColor: rgba($rightColorHex, $rightColorAlpha);
  $ieRightColor: ie_hex_str($rightColor);
  background: -moz-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, $leftColor), color-stop(100%, rgba(255, 255, 255, 0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $leftColor 0%, $rightColor 100%); /* IE10+ */
  background: linear-gradient(to right, $leftColor 0%, $rightColor 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$ieLeftColor, endColorstr=$rightColor, GradientType=1); /* IE6-9 */
}

.b-casehead-action {
  height: 900px;
  position: relative;
  background: url('../img/projects/action/cover.jpg') 100% 84px;
  color: $color_case_vedomosti_block_dark;

  .l-wrap {
    height: 100%;
    margin: auto;

    .b-casehead__logo {
      position: absolute;
      top: -150px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 350px;
      height: 112px;

      img {
        width: 350px;
      }
    }

    .b-casehead__descr {
      position: absolute;
      top: 150px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 70%;
      min-width: 800px;
      height: 150px;
      margin: auto;
      text-align: center;
      color: white;
    }

  }

}

.b-caseblock.b-caseblock-action {
  padding: 5em 0 7em;

  &.b-caseblock--dark {
    background: $colorCaseActionBlockDark;
    color: $colorCaseActionTextBright;

    .b-caseblock__title {
      color: white;
    }

    .b-caseblock__subtitle {
      color: $colorCaseActionTextDark;
    }
  }

  &.b-caseblock--bright {
    background: $colorCaseActionBlockBright;
    color: $colorCaseActionTextDark;

    .b-caseblock__title {
      color: black;
    }
  }

  .b-caseblock__text {
    max-width: 1100px;
    margin-bottom: 1em;
  }

  .b-caseblock__title {
    max-width: 1100px;
    line-height: 1.2em;
    margin-bottom: 0.5em;
  }

  .b-caseblock__subtitle {
    max-width: 1000px;
    margin-bottom: 2em;
    line-height: 1.5em;
    font-size: 20px;
  }

  &.b-caseblock-action--magazine {
    padding: 10em 0 12em;
    overflow: hidden;

    .magazine {
      position: relative;
      padding: 0;
      color: white;
      box-sizing: border-box;

      .magazine-description {
        position: relative;
        margin: auto;
        padding: 0 2em;

        .magazine-description__logo {
          text-align: left;
        }

        .magazine-description__text {
          margin-top: 1em;
          line-height: 1.5em;
          font-size: 14pt;
        }

        .magazine-description-stores {
          text-align: left;
          margin-top: 1.5em;

          a {
            display: inline-block;

            img {
              vertical-align: middle;
            }
          }
        }
      }

      .magazine__end {
        clear: both;
      }
    }

    &.b-caseblock-action--ekiosk {
      background: #eeeeee;

      .magazine {
        max-width: 1200px;

        .magazine-description {
          color: black;
          float: left;
          width: 500px;

          .magazine-description__logo {
            text-align: left;

            img {
              width: 331px;
              height: 64px;
            }
          }
        }

        .magazine-screen {
          position: absolute;
          left: 550px;
          top: -50px;
          width: 629px;
          height: 499px;
          background: url('../img/projects/action/ekiosk/screen.png') top left no-repeat;
        }
      }
    }

    &.b-caseblock-action-glavbuh {
      background: $colorCaseActionGlavbuhBackground;

      .magazine {
        background-size: auto 100%;
        height: 461px;

        .magazine-screen {
          position: absolute;
          right: 540px;
          top: -50px;
          width: 870px;
          height: 461px;
          background: url('../img/projects/action/glavbuh/screen.png') top left no-repeat;
        }

        .magazine-description {
          float: right;
          text-align: left;
          width: 460px;
          height: 380px;

          .magazine-description__logo {
            img {
              width: 200px;
            }
          }
        }
      }
    }

    &.b-caseblock-action-gendir {
      background: $colorCaseActionGendirBackground;
      padding: 14em 0 16em;

      .magazine {
        max-width: 1000px;

        .magazine-description {
          float: left;
          width: 480px;
          height: 320px;

          .magazine-description__logo {
            img {
              width: 227px;
            }
          }
        }

        .magazine-screen {
          position: absolute;
          left: 500px;
          top: -100px;
          width: 491px;
          height: 514px;
          background: url('../img/projects/action/gendir/screen.png') top right no-repeat;
        }
      }
    }

    &.b-caseblock-action-comdir {
      background: $colorCaseActionComDirBackground;
      padding: 14em 0 14em;

      .magazine {
        max-width: 1250px;

        .magazine-screen {
          position: absolute;
          right: 550px;
          top: -70px;
          width: 660px;
          height: 469px;
          background: url('../img/projects/action/comdir/screen.png') top right no-repeat;
        }

        .magazine-description {
          float: right;
          width: 480px;

          .magazine-description__logo {
            img {
              width: 261px;
            }
          }
        }
      }
    }

    &.b-caseblock-action-findir {
      background: $colorCaseActionFinDirBackground;
      padding: 12em 0 12em;

      .magazine {

        .magazine-description {
          float: left;
          width: 480px;

          .magazine-description__logo {
            img {
              width: 260px;
            }
          }
        }

        .magazine-screen {
          position: absolute;
          left: 520px;
          top: -50px;
          width: 860px;
          height: 448px;
          background: url('../img/projects/action/findir/screen.png') top right no-repeat;
        }
      }
    }

    &.b-caseblock-action-upr {
      background: $colorCaseActionUprBackground;
      padding: 14em 0 16em;

      .magazine {
        max-width: 1000px;

        .magazine-screen {
          position: absolute;
          right: 520px;
          top: -120px;
          width: 501px;
          height: 524px;
          background: url('../img/projects/action/upr/screen.png') top left no-repeat;
        }

        .magazine-description {
          float: right;
          width: 450px;

          .magazine-description__logo {
            img {
              width: 281px;
            }
          }
        }
      }
    }

    &.b-caseblock-action-lawyer {
      background: $colorCaseActionLawyerBackground;
      padding: 14em 0 16em;

      .magazine {
        max-width: 1200px;

        .magazine-description {
          float: left;
          width: 480px;

          .magazine-description__logo {
            img {
              width: 240px;
            }
          }
        }

        .magazine-screen {
          position: absolute;
          left: 500px;
          top: -100px;
          width: 726px;
          height: 550px;
          background: url('../img/projects/action/lawyer/screen.png') top right no-repeat;
        }
      }
    }

  }

  &.b-caseblock-action-ubu {
    background: $colorCaseActionUbuBackground;

    .magazine {
      max-width: 1000px;

      .magazine-screen {
        position: absolute;
        right: 550px;
        top: -50px;
        width: 530px;
        height: 510px;
        background: url('../img/projects/action/ubu/screen.png') top left no-repeat;
      }

      .magazine-description {
        float: right;
        width: 480px;

        .magazine-description__logo {
          img {
            width: 208px;
          }
        }
      }
    }
  }

  &.b-caseblock-action-services {

    .b-caseblock__subtitle {
      color: $colorCaseActionTextBright;
    }

    .b-caseblock__screen_flex {
      .b-caseblock__screen__image img {
        margin: 0 auto;
        max-width: 847px;
      }
    }

  }


  $formats-stage-item-margin: 100px;
  $formats-stage-image-width: 942px;
  $formats-stage-image-height: 502px;

  &.b-caseblock-action-easy {

    .b-easy-stage {
      margin: 50px auto 0;
      background: none;
      width: 100%;
      height: $formats-stage-image-height;

      .b-caseblock-carousel__wrap {
        padding: 0;
        background: none;
        overflow: hidden;
        width: 100%;
        height: $formats-stage-image-height;

        .b-caseblock-carousel__prev, .b-caseblock-carousel__next {
          color: white;
        }

        &:before, &:after {
          display: block;
          content: "";
          width: 200px;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1;
        }

        &:before {
          left: 0;
          @include sliderGradient($colorCaseActionBlockBright, 1.0, $colorCaseActionBlockBright, 0.0);
        }

        &:after {
          right: 0;
          @include sliderGradient($colorCaseActionBlockBright, 0.0, $colorCaseActionBlockBright, 1.0);
        }
      }

      .b-caseblock-carousel__viewport {
        overflow: visible;
        width: $formats-stage-image-width + $formats-stage-item-margin;
        height: $formats-stage-image-height;
        margin: 0 auto;

        .b-caseblock-carousel_easy-item {
          height: $formats-stage-image-height;
          width: $formats-stage-image-width + $formats-stage-item-margin;
          cursor: pointer;
          text-align: center;

          .b-caseblock-carousel_easy-item__image {
            margin: 0 auto;
            width: $formats-stage-image-width;
            height: $formats-stage-image-height;
            background-position: 0 0;
            background-size: cover;

            img {
              width: $formats-stage-image-width;
              height: $formats-stage-image-height;
            }
          }
        }
      }
    }
  }

  &.b-caseblock-action-catalog {

    .b-caseblock__screen_flex {
      .b-caseblock__screen__image img {
        margin: 0 auto;
        max-width: 1100px;
      }
    }

  }


  $formats-stage-item-margin: 100px;
  $formats-stage-image-width: 942px;
  $formats-stage-image-height: 502px;

  &.b-caseblock-action-issues {

    .b-issues-stage {
      margin: 50px auto 0;
      background: none;
      width: 100%;
      height: $formats-stage-image-height;

      .b-caseblock-carousel__wrap {
        padding: 0;
        background: none;
        overflow: hidden;
        width: 100%;
        height: $formats-stage-image-height;

        .b-caseblock-carousel__prev, .b-caseblock-carousel__next {
          color: white;
        }

        &:before, &:after {
          display: block;
          content: "";
          width: 200px;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 1;
        }

        &:before {
          left: 0;
          @include sliderGradient($colorCaseActionBlockBright, 1.0, $colorCaseActionBlockBright, 0.0);
        }

        &:after {
          right: 0;
          @include sliderGradient($colorCaseActionBlockBright, 0.0, $colorCaseActionBlockBright, 1.0);
        }
      }

      .b-caseblock-carousel__viewport {
        overflow: visible;
        width: $formats-stage-image-width + $formats-stage-item-margin;
        height: $formats-stage-image-height;
        margin: 0 auto;

        .b-caseblock-carousel_issues-item {
          height: $formats-stage-image-height;
          width: $formats-stage-image-width + $formats-stage-item-margin;
          cursor: pointer;
          text-align: center;

          .b-caseblock-carousel_issues-item__image {
            margin: 0 auto;
            width: $formats-stage-image-width;
            height: $formats-stage-image-height;
            background-position: 0 0;
            background-size: cover;

            img {
              width: $formats-stage-image-width;
              height: $formats-stage-image-height;
            }
          }
        }
      }
    }
  }

  &.b-caseblock-action-fullscreen {

    .b-caseblock__screen_flex {
      .b-caseblock__screen__image img {
        margin: 0 auto;
        max-width: 1122px;
      }
    }

  }

  &.b-caseblock-action-purchase {

    .b-caseblock__title {
      margin-bottom: 1.5em;
    }

    .b-caseblock__screen_flex {
      .b-caseblock__screen__image img {
        margin: 0 auto;
        max-width: 1000px;
      }
    }

  }

  &.b-caseblock-action-control {
    padding-bottom: 0;

    .b-caseblock__screen_flex {
      .b-caseblock__screen__image img {
        margin: 0 auto;
        max-width: 998px;
      }
    }

  }

}

.b-case-team {
  padding: 50px 0;

  .b-case-team__one {
    padding: 0;
  }

  .b-case-team__one+.b-case-team__one {
    margin-top: 50px;
  }
}

.b-case-team--vedomosti {
  padding: 50px 0;
  background-color: $color_case_vedomosti_block_bright;

  .b-case-team__one {
    padding-bottom: 0;
  }
}