$title-font-size: 24px;
$title-line-height: 30px;
$feedback-height: 260px;
$mob-size: 480px;

/* popups */

#fader {
  display: none;
  background: rgba(12, 12, 12, 0.94);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 405;
}

.b-popup {
  display: none;
  width: 530px;
  height: $feedback-height;
  margin: -190px 0 0 -320px;
  padding: 60px 55px 0px;
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 406;
}

.b-popup_small {
  width: 370px !important;
  height: auto;
  margin: 0 0 0 -245px !important;
  padding: 30px 60px !important;
  top: 100px;
  left: 50% !important;
}

.b-popup.fixed {
  position: fixed;
  margin-top: 0;
}

.b-popup__close {
  width: 24px;
  height: 26px;
  position: absolute;
  top: -13px;
  right: -64px;
  cursor: pointer;
}

.b-popup__title {
  margin: 0 10px;
  font-size: $title-font-size;
  line-height: $title-line-height;
  text-align: center;
  font-family: "DaxLinePro", sans-serif;
  color: #303030;
}

.b-popup-error .b-popup__title {
  color: #F74343
}

.b-popup_small .b-popup__title {
  font-size: 24px;
  line-height: 61px;
}

.b-popup__descr {
  margin-bottom: 30px;
  padding-left: 0 30px;
}

.b-popup__thanks {
  margin: 8px 25px 23px;
  font-size: 18px;
}

.b-popup__knowmore {
  color: #c5c5c5;
}

.b-popup__link--fb {
  display: block;
  width: 198px;
  height: 50px;
  margin: 18px auto 0;
  background: #3b5998;
  font-size: 18px;
  line-height: 50px;
  color: #ffffff;
  font-family: "DaxLinePro", sans-serif;
}

.b-feedback-popup {
  padding-left: 30px;
  padding-right: 30px;
}

.b-popup-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

.b-feedback-popup div {
  text-align: center;
}

.b-popup__failed {
  width: 100px;
  margin: auto;
  text-align: center;
}

.b-popup-error-message {
  margin: 8px 16px;
  text-align: center;
}

.b-popup_review {
  width: 640px;
  height: auto;
  padding: 45px 70px;
  margin: 0 0 0 -390px;
  top: 100px;
}

.b-popup__image {
  margin-bottom: 10px;
  text-align: center;
}

.b-popup__image img {
  display: block;
  margin: auto;
}

.b-popup__name {
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  font-family: "DaxLinePro", sans-serif;
}

.b-popup__job {
  margin-top: -6px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.b-popup__quote {
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
  font-style: italic;
  text-align: center;
}

.b-popup__text {
  margin-top: 25px;
}

.b-popup_review {
  .b-popup__text {
    max-height: 500px;
    overflow-y: auto;
  }
}

.b-popup__form__field {
  margin-bottom: 35px;
}

#feedbackForm1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.b-popup__form__field input,
.b-popup__form__field textarea {
  display: block;
  width: 328px;
  height: 24px;
  padding: 18px 20px;
  border: 1px solid #ababab;
  background: #ffffff;
  color: #1c1c1c;
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  transition: background-color 300ms linear, border-color 300ms linear;
}

.b-popup__form__field textarea {
  height: 122px;
  resize: vertical;
}

.b-popup__form__field .error {
  background-color: #FFF3F3;
  border-color: #FFB1B1;
  color: #F74343;
}

.b-popup__form__submit {
  padding-top: 10px;
}

.b-popup__form__submit input,
.b-popup__form__submit button,
.b-popup__form__submit a {
  display: block;
  width: 220px;
  height: 50px;
  margin: auto;
  padding: 0;
  border: none;
  background: #0032E9;
  color: #ffffff;
  font-size: 18px;
  line-height: 50px;
  font-family: "DaxLinePro", sans-serif;
  cursor: pointer;
}

.b-popup__form__submit input:disabled {
  background: #888;
}

@media screen and (max-width: 700px) {
  .b-popup_small {
    padding: 0 !important;
  }
}

@media screen and (max-width: $mob-size) {
  .b-feedback-popup {
    width: 100%;
    height: 100%;
    // background: #fff1f1;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
}
