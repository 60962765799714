.b-titleblock {
  height: 100%;
  background: #083088 url("../img/base/cover/cover1.jpg") no-repeat 50% 100%;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;

  .b-titleblock__logo {
    width: 256px;
    height: 256px;
    margin: auto;
    position: absolute;
    top: -100px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0032E9;
  }

  .b-titleblock__descr {
    height: 30px;
    margin: auto;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif;
    position: absolute;
    top: -50px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .b-titleblock__showreal {
    width: 220px;
    height: 50px;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 150px;
    left: 0;
  }

  .b-titleblock__showreal a {
    display: block;
    padding: 10px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif;
  }


  .b-titleblock__showreal a:hover {
    color: #fff;
    background: #0032E9;
  }
  .b-titleblock__showreal a:hover .a {
    display: none;
  }

  .b-titleblock__showreal a .b {
    display: none;
  }
  .b-titleblock__showreal a:hover .b {
    display: inline;
  }


  .b-titleblock__btn {
    width: 220px;
    height: 50px;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 80px;
    left: 0;
  }

  .b-titleblock__btn a {
    display: block;
    padding: 10px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-family: "DaxLinePro", sans-serif;
  }

  .b-titleblock__btn a:hover {
    color: #083088;
    background: #ffffff;
  }
}

#popupShowReal {
  width: 720px;
  height: 405px;
  padding: 0;
  background: transparent;

  .showreal-video {
    width: 720px;
    height: 405px;
  }
}


@media screen and (max-width: 990px) {

  #popupShowReal {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: auto !important;
    padding: 0;
    z-index: 560;

    overflow-y: auto;
    background: transparent;
    text-align: center;

    .b-popup__close {
      img {
        display: block;

        &.d-n {
          display: none;
        }
      }
    }

    .showreal-video {
      margin-top: 60px;
      width: 100%;
      height: 70%;
    }
  }

}

@media screen and (max-width: 700px) {

  .b-titleblock {
    min-height: 320px;

    .b-titleblock__logo, .b-titleblock__logo img {
      width: 200px;
      height: 200px;
    }

    .b-titleblock__showreal {
      width: 176px;
      bottom: 120px;
    }

    .b-titleblock__descr {
      height: 15px;
      font-size: 15px;
      line-height: 23px;
      font-weight: 300;
      top: -110px;
    }

    .b-titleblock-showreal {
      height: 70px;
      width: 70px;
      top: 20px;
    }

    .b-titleblock__btn {
      width: 176px;
      height: 50px;
      bottom: 40px;
    }

    .b-titleblock__btn a {
      padding: 14px 0;
      font-size: 15px;
      line-height: 23px;
    }
    .b-titleblock__showreal a {
      font-size: 15px;
    }
  }

  #popupShowReal {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    width: auto !important;
    padding: 0;
    z-index: 560;

    overflow-y: auto;
    background: transparent;
    text-align: center;

    .b-popup__close {
      img {
        display: block;

        &.d-n {
          display: none;
        }
      }
    }

    .showreal-video {
      margin-top: 60px;
      width: 100%;
      height: 70%;
    }
  }

}