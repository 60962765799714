.g-strong {
  font-weight: bold;
}


@import "global_mixins";

@import "reset";
@import "prestyles";
@import "ui/ui";
@import "ui/popup";
@import "contacts";
@import "footer";
@import "index";

@import "main";

// Platforms
@import "./platforms/main";

// Projects
@import "./projects/main";